import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../../css/listingView.scss'
import ListingCard from './ListingCard'
 
const S3_BASE = "https://mapmax-backend.s3.amazonaws.com/"

export default class Slideshow extends React.Component {

    constructor(props) {
        super(props)
    }

 
render() {
    return (
        <Carousel style={{ width: this.props.width}} interval={10000}>
            {this.props.data.map((e, i) => { 
                return (
                    <Carousel.Item>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                                {e.map((listing, i) => {
                                    return (
                                        <ListingCard listing={listing} height={this.props.height}/> 
                                    )
                                } 
                             )}
                            </div>
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                )
            })}
         
        </Carousel>
    )
    }
}
