import React from "react";
import { Container, Typography, Box, Link, Paper } from "@mui/material";

function DisclaimerPage() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Disclaimer for Mapmax LLC
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          PLEASE READ THIS DISCLAIMER CAREFULLY BEFORE USING THE SERVICES
          OFFERED BY MAPMAX, LLC. HEREAFTER REFERRED TO AS “MAPMAX”.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Mapmax is a Real Estate Analytics Platform.
        </Typography>

        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>1. General Information:</strong>Mapmax is a real estate
            geographical analytics platform for both commercial and residential
            real estate. Mapmax covers territories within New York City, Long
            Island, New York, and New Jersey. The content and services are
            provided by Mapmax, headquartered in New Jersey.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>2. No Warranties:</strong> All content and services provided
            by Mapmax are on an &quot;as is&quot; and &quot;as available&quot;
            basis. Mapmax relies on third party data and, therefore, makes no
            representations or warranties of any kind, express or implied, as to
            the accuracy, reliability, completeness, or timeliness of the
            content, graphics, links, or other items contained on the website.
            It is the user’s responsibility to research and verify all
            information provided. Any reliance you place on any such information
            provided by Mapmax is strictly at your own risk.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>3. Not Professional Advice</strong>The information provided
            on this site, including any geographical analytics, is for general
            informational purposes only. It should not be considered as
            professional real estate, legal, financial, or tax advice. Users are
            encouraged to consult with professional advisors for advice
            concerning specific matters before making any decision.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>4.Limitation of Liability:</strong> In no event shall
            Mapmax, its officers, directors, employees, or agents, be liable for
            any direct, indirect, incidental, special, punitive, or
            consequential damages whatsoever arising out of or related to the
            use, inability to use, performance or non-performance of the
            services provided by Mapmax, even if Mapmax has been advised
            previously of the possibility of such damages and whether such
            damages arise in contract, negligence, tort, under statute, in
            equity, at law, or otherwise.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            This website may contain links to other websites which are not under
            the control of Mapmax. We have no control over the nature, content,
            and availability of those sites. The use of any third party data or
            the inclusion of any links does not necessarily imply a
            recommendation or endorse the views expressed within them.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>6. Jurisdiction:</strong> This disclaimer, and any disputes
            arising from or related to the services of Mapmax, shall be governed
            by and construed in accordance with the laws of the State of New
            Jersey without regard to its conflict of law provisions.
          </Typography>
          {/* Repeat for other points */}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          {/* Disclaimer points here */}
          <Typography variant="body1" paragraph>
            <strong>7.Changes to This Disclaimer:</strong>Mapmax reserves the
            right to change this disclaimer at any time. Users are encouraged to
            review this disclaimer periodically to stay informed of any changes.
          </Typography>
          {/* Repeat for other points */}
        </Box>

        <Typography variant="body1" paragraph>
          By using Mapmax, you signify your acceptance of this disclaimer. If
          you do not agree to all of the terms of this disclaimer, please do not
          use our services. Your continued use of the services following the
          posting of changes to this disclaimer will be deemed your acceptance
          of those changes.
        </Typography>
        <Typography variant="body1" paragraph>
          will be deemed your acceptance of those changes. If you have any
          questions or concerns regarding this disclaimer, please contact
          customer service at
        </Typography>
        <Typography variant="body1">
          <Link href="mailto:ithelp.mapmax@gmail.com">
            {" "}
            ithelp.mapmax@gmail.com
          </Link>
          ,<Link href="mailto:mapmax360@gmail.com"> mapmax360@gmail.com</Link>.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            display: "flex",
            fontSize: "1.5rem",
            justifyContent: "center",
            alignItems: "center", // Use this if you also want vertical centering
            // Adjust the height as needed for vertical centering
          }}
        >
          Thank you for using Mapmax!
        </Typography>
      </Paper>
    </Container>
  );
}

export default DisclaimerPage;
