import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { updateBusinessType } from "../../../Actions/business-actions";
import { BUSINESS_TYPES } from "../../../Constants";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { updateDataRange } from "../../../Actions/dataRange-actions";
import {
  updateStatsZip,
  updateStatsTradezone,
} from "../../../Actions/stats-actions";
import {
  isCitySelector,
  tractSelector,
  addressSelector,
  dataRangeSelector,
  tradeZoneBoundsSelector,
  statsSelector,
  geoUnitSelector,
  businessTypeSelector,
} from "../../../Reducers/selectors";
import Button from "react-bootstrap/Button";
import { createTradeZoneStats } from "../../../Requests/locations-requests";
import { updateTradeZoneBounds } from "../../../Actions/tradeZoneBoundaries-actions";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  getZipData,
  getTradezoneData,
} from "../../../Requests/tradezones-requests";
import Modal from "react-bootstrap/Modal";
import { determineRadius } from "../../utils/distance";

export const ZIP = "zip code tabulation area";
export const TRADE_ZONE = "tradeZone";
const darkBg = "rgb(31 117 210 / 64%)";
const lightBg = "#000";
const dropdownDefault = "#006455";
const dropdownSelected = "#178171";
const dropdownNestedDefault = "#0f3630";
const dropdownNestedSelected = "#32796e";
const textPrimary = "whitesmoke";

class DemographicsPanel extends React.Component {
  constructor(props) {
    super(props);

    // reconcile state display and backend business type encoding
    let business_type = this.props.business_type.type;
    if (business_type == "lodging") business_type = "lodging";

    this.state = {
      statsLoaded: false,
      zipVariant: "light",
      tzVariant: "dark",
      zipDisabled: false,
      zipHeader: "ZIP Code - " + this.props.address.zip, // change Zipcode Header here
      accordianKeys: {
        zip: ["0", "0", "0", "0"],
        tradeZone: ["0", "0", "0", "0"],
      },
      activeKey: "0",
      activeNestedKey: "-1",
      showModal: false,
      business_type: business_type.replace(/_/g, " "),
      hasValidTradeZone: false,
    };
  }

  async componentDidMount() {
    this.getZip();
    this.loadNewTradeZoneStats();
  }

  onUpdateDataRange = (event) => {
    let val = event.target.value;
    if (val === "zip") {
      this.props.onUpdateDataRange(ZIP);
      this.setState({ zipVariant: "light", tzVariant: "dark" });
    } else if (val == "tradeZone") {
      this.props.onUpdateDataRange(TRADE_ZONE);
      this.setState({ zipVariant: "dark", tzVariant: "light" });
    }
  };

  onUpdateStatsZip = (stats) => {
    updateStatsZip(stats);
  };

  onUpdateStatsTZ = (stats) => {
    updateStatsTradezone(stats);
  };

  getZip = async () => {
    const { address } = this.props;
    const { state, zip } = address;

    const res = await getZipData(state, zip);
    if (res.length >= 1) {
      this.setState({
        zipStats: res[0].stats,
        accordianKeys: {
          ...this.state.accordianKeys,
          zip: ["0", "1", "2", "3"],
        },
      });
      this.props.onUpdateStatsZip(res[0]);
    } else {
      this.setState({ zipHeader: "Zip Unavailable", zipDisabled: true });
      this.props.onUpdateDataRange(TRADE_ZONE);
      this.setState({ zipVariant: "dark", tzVariant: "light" });
    }
  };

  loadNewTradeZoneStats = async () => {
    const { address, stats } = this.props;
    const params = determineRadius(address.zip);
    const { radius, isnyc } = params;

    this.setState({ isnyc });

    const res = await getTradezoneData(
      address.state,
      address.zip,
      address.coords.lat,
      address.coords.lng,
      radius,
      isnyc
    );

    if (res.stats) {
      // set internal state
      this.setState({
        tradeZoneStats: res.stats,
        statsLoaded: true,
        hasValidTradeZone: false, // wtf is this named that???
        accordianKeys: {
          ...this.state.accordianKeys,
          tradeZone: ["0", "1", "2", "3"],
        },
      });
      this.props.onUpdateStatsTZ(res);
    }
  };

  checkForTradeZone = () => {
    return new Promise((resolve) => {
      if (this.state.tradeZoneStats == null) {
        let loop = setInterval(() => {
          if (this.state.tradeZoneStats != null) {
            clearInterval(loop);
            resolve();
          }
        }, 100);
      } else resolve();
    });
  };

  onUpdateTradeZoneBounds = (tradeZone_bounds) => {
    this.props.onUpdateTradeZoneBounds(tradeZone_bounds);
  };

  setModalShow = (flag) => {
    this.setState({ showModal: flag });
  };

  onBusinessFormChange = (event) => {
    let type = event.target.value;
    this.setState({ business_type: type });

    // for props
    if (type == "hotels /lodging") type = "lodging";
    let business = {
      type: type.replace(/ /g, "_"),
      priceLevel: 0,
    };

    this.onUpdateBusinessType(business);
  };

  onUpdateBusinessType(business_type) {
    this.props.onUpdateBusinessType(business_type);
  }

  hasValidTradeZone = () => {
    const { address } = this.props;
    console.log("AddressState", address.state);
    const isValid = address.state !== "NJ" && address.state !== "NY";
    this.setState({ hasValidTradeZone: isValid });
    return isValid;
  };

  handleAccordionClick = (newKey) => {
    this.setState({ activeKey: newKey });
  };

  handleNestedAccordionClick = (newKey) => {
    this.setState({ activeNestedKey: newKey });
  };

  render() {
    const { hasValidTradeZone } = this.state;
    const { activeKey } = this.state;
    const { activeNestedKey } = this.state;

    var social_data = {
      summary: {
        gender: {},
      },

      race: {},
      nativity: {},
      education: {},
      marital_status: {},
      employment: {},
      transportation: {},
    };
    var income_data = {};
    var age_data = {};

    if (this.props.data_range == ZIP) {
      if (this.state.zipStats != null) {
        social_data = this.state.zipStats.social;
        income_data = this.state.zipStats.income;
        age_data = this.state.zipStats.age;
      }
    } else if (this.props.data_range == TRADE_ZONE) {
      if (this.state.tradeZoneStats != null) {
        social_data = this.state.tradeZoneStats.social;
        income_data = this.state.tradeZoneStats.income;
        age_data = this.state.tradeZoneStats.age;
      }
    }

    const NestedCard = ({ name, data, key_i, activeNestedKey }) => {
      let total = 0;
      Object.entries(data).forEach(([k, v]) => {
        if (
          k.toLowerCase().search("total") <= -1 &&
          k.toLowerCase().search("median") <= -1
        )
          total += v;
      });

      return (
        <Card style={{ backgroundColor: lightBg }}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={key_i}
            style={{
              color: "whitesmoke",
              backgroundColor:
                activeNestedKey === key_i
                  ? dropdownNestedSelected
                  : dropdownNestedDefault,
              cursor: "pointer",
              margin: "3px",
              fontWeight: "bold",
            }}
          >
            {name}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={key_i}>
            <Card.Body>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table striped bordered hover variant="dark">
                  <tbody>
                    {Object.entries(data).map((data, i) => {
                      let entry = data[0].replace(/_/g, " ");

                      let output = getPercent(data[1], total);
                      if (
                        entry.toLowerCase().search("total") > -1 ||
                        entry.toLowerCase().search("median") > -1
                      )
                        output = formatCommas(data[1]);
                      return (
                        <tr key={i}>
                          <td>{capitalize(entry)}</td>
                          <td>{output}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    };
    let business_type = this.props.business_type.type.replace(/_/g, " ");
    if (business_type == "lodging") business_type = "hotel /lodging";

    return (
      <div className="demographics_container">
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              margin: "3px 0 12px",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p></p>
            <strong
              style={{
                color: "whitesmoke",
                textAlign: "center",
                margin: "14px auto 0",
              }}
            >
              Demographics &nbsp;
              <InfoButton clickFunction={() => this.setModalShow(true)} />
            </strong>

            <MyVerticallyCenteredModal
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "15px",
              padding: "0 6px",
              height: "35px",
              width: "100%",
            }}
          >
            <LoadingButton
              click={this.onUpdateDataRange}
              buffer={this.checkForTradeZone}
              buttonVariant={this.state.tzVariant}
              hasValidTradeZone={this.state.hasValidTradeZone}
            />
            <Button
              disabled={this.state.zipDisabled}
              variant={this.state.zipVariant}
              className="map-control_button"
              onClick={this.onUpdateDataRange}
              value={"zip"}
              style={{
                width: "50%",
                height: 35,
                padding: "0",
                marginRight: "6px",
                fontWeight: "bold",
              }}
            >
              {this.state.zipHeader}
            </Button>
          </div>
        </div>

        <div style={{ flex: 1, overflowY: "auto", backgroundColor: lightBg }}>
          <Accordion
            defaultActiveKey="0"
            activeKey={this.state.activeKey}
            onSelect={this.handleAccordionClick}
          >
            <Card style={{ backgroundColor: lightBg }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                ref={(ref) => (this.accordion = ref)}
                style={{
                  color: "whitesmoke",
                  backgroundColor:
                    activeKey === "0" ? dropdownSelected : dropdownDefault,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                General
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Table striped bordered hover variant="dark">
                    <tbody style={{ overflowX: "auto" }}>
                      {/* <tr>
                        <td>Your Proposed Business Type</td>
                        <td>
                          <Form.Group>
                            <Form.Label
                              style={{ color: textPrimary }}
                              style={{ color: textPrimary }}
                            ></Form.Label>
                            <Form.Control
                              as="select"
                              name="business_type"
                              value={this.state.business_type}
                              onChange={this.onBusinessFormChange}
                            >
                              {BUSINESS_TYPES.map((e, i) => {
                                return (
                                  <option key={i}>
                                    {e.replace(/_/g, " ")}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </td>
                      </tr> */}
                      <tr>
                        <td>Address</td>
                        <td>{this.props.address.formatted}</td>
                      </tr>
                      <tr>
                        <td>Population</td>
                        <td>
                          {social_data.summary.population != undefined &&
                            formatCommas(social_data.summary.population)}
                          {!hasValidTradeZone &&
                            social_data.summary.population == undefined &&
                            "loading..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Median Age</td>
                        <td>
                          {social_data.summary.median_age != undefined &&
                            social_data.summary.median_age + " yrs"}
                          {!hasValidTradeZone &&
                            social_data.summary.median_age == undefined &&
                            "loading..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Median Household Income</td>
                        <td>
                          {income_data.median != undefined &&
                            "$" + formatCommas(income_data.median)}
                          {!hasValidTradeZone &&
                            income_data.median == undefined &&
                            "loading..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Males</td>
                        <td>
                          {social_data.summary.gender.males != undefined &&
                            formatCommas(social_data.summary.gender.males)}
                          {!hasValidTradeZone &&
                            social_data.summary.gender.males == undefined &&
                            "loading..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Females</td>
                        <td>
                          {social_data.summary.gender.females != undefined &&
                            formatCommas(social_data.summary.gender.females)}
                          {!hasValidTradeZone &&
                            social_data.summary.gender.females == undefined &&
                            "loading..."}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card style={{ backgroundColor: lightBg }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={
                  this.state.accordianKeys[
                    this.props.data_range.split(" ")[0]
                  ][1]
                }
                style={{
                  color: "whitesmoke",
                  backgroundColor:
                    activeKey === "1" ? dropdownSelected : dropdownDefault,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Social
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Accordion onSelect={this.handleNestedAccordionClick}>
                    {!(
                      this.props.data_range == TRADE_ZONE && this.state.isnyc
                    ) && (
                      <NestedCard
                        name={"Ethnicity"}
                        data={social_data.race}
                        key_i={"0"}
                        activeNestedKey={activeNestedKey}
                      ></NestedCard>
                    )}
                    {!(
                      this.props.data_range == TRADE_ZONE && this.state.isnyc
                    ) && (
                      <NestedCard
                        name={"Nativity"}
                        data={social_data.nativity}
                        key_i={"1"}
                        activeNestedKey={activeNestedKey}
                      ></NestedCard>
                    )}
                    {!(
                      this.props.data_range == TRADE_ZONE && this.state.isnyc
                    ) && (
                      <NestedCard
                        name={"Education (Ages 25+)"}
                        data={social_data.education}
                        key_i={"2"}
                        activeNestedKey={activeNestedKey}
                      ></NestedCard>
                    )}
                    <NestedCard
                      name={"Marital Status"}
                      data={social_data.marital_status}
                      key_i={"3"}
                      activeNestedKey={activeNestedKey}
                    ></NestedCard>
                    {!(this.props.data_range == TRADE_ZONE) && (
                      <NestedCard
                        name={"Transportation (for work)"}
                        data={social_data.transportation}
                        key_i={"4"}
                        activeNestedKey={activeNestedKey}
                      ></NestedCard>
                    )}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ backgroundColor: lightBg }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={
                  this.state.accordianKeys[
                    this.props.data_range.split(" ")[0]
                  ][2]
                }
                style={{
                  color: "whitesmoke",
                  backgroundColor:
                    activeKey === "2" ? dropdownSelected : dropdownDefault,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Household Income
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Table striped bordered hover variant="dark">
                    <tbody>
                      <tr>
                        <td>Sample total</td>
                        <td>{formatCommas(income_data.sample_total)}</td>
                      </tr>
                      <tr>
                        <td>Median Household Income</td>
                        <td>{"$" + formatCommas(income_data.median)}</td>
                      </tr>
                      <tr>
                        <td>0-35k</td>
                        <td>
                          {getPercent(
                            income_data._0_9999 +
                              income_data._10000_14999 +
                              income_data._15000_19999 +
                              income_data._20000_24999 +
                              income_data._25000_29999 +
                              income_data._30000_34999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>35-50k</td>
                        <td>
                          {getPercent(
                            income_data._35000_39999 +
                              income_data._40000_44999 +
                              income_data._45000_49999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>50-75k</td>
                        <td>
                          {getPercent(
                            income_data._50000_59999 + income_data._60000_74999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>75-100k</td>
                        <td>
                          {getPercent(
                            income_data._75000_99999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>100-150k</td>
                        <td>
                          {getPercent(
                            income_data._100000_124999 +
                              income_data._125000_149999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>150-200k</td>
                        <td>
                          {getPercent(
                            income_data._150000_199999,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>200k+</td>
                        <td>
                          {getPercent(
                            income_data._200000_MORE,
                            income_data.sample_total
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ backgroundColor: lightBg }}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={
                  this.state.accordianKeys[
                    this.props.data_range.split(" ")[0]
                  ][3]
                }
                style={{
                  color: "whitesmoke",
                  backgroundColor:
                    activeKey === "3" ? dropdownSelected : dropdownDefault,
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                Age
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <Table striped bordered hover variant="dark">
                    <tbody>
                      <tr>
                        <td>Median</td>
                        <td>{age_data.median_age + " yrs"}</td>
                      </tr>
                      <tr>
                        <td>Sample Total</td>
                        <td>{formatCommas(age_data.sample_total)}</td>
                      </tr>
                      <tr>
                        <td>0-9yrs</td>
                        <td>
                          {getPercent(
                            age_data.ZERO_FIVE + age_data.FIVE_NINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>10-19yrs</td>
                        <td>
                          {getPercent(
                            age_data.TEN_FOURTEEN +
                              age_data.FIFTEEN_SEVENTEEN +
                              age_data.EIGHTEEN_NINETEEN,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>20-29yrs</td>
                        <td>
                          {getPercent(
                            age_data.TWENTY +
                              age_data.TWENTYONE +
                              age_data.TWENTYTWO_TWENTYFOUR +
                              age_data.TWENTYFIVE_TWENTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>30-39yrs</td>
                        <td>
                          {getPercent(
                            age_data.THIRTY_THIRTYFOUR +
                              age_data.THIRTYFIVE_THIRTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>40-49yrs</td>
                        <td>
                          {getPercent(
                            age_data.FORTY_FORTYFOUR +
                              age_data.FORTYFIVE_FORTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>50-59yrs</td>
                        <td>
                          {getPercent(
                            age_data.FIFTY_FIFTYFOUR +
                              age_data.FIFTYFIVE_FIFTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>60-69yrs</td>
                        <td>
                          {getPercent(
                            age_data.SIXTY_SIXTYONE +
                              age_data.SIXTYTWO_SIXTYFOUR +
                              age_data.SIXTYFIVE_SIXTYSIX +
                              age_data.SIXTYSEVEN_SIXTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>70-79yrs</td>
                        <td>
                          {getPercent(
                            age_data.SEVENTY_SEVENTYFOUR +
                              age_data.SEVENTYFIVE_SEVENTYNINE,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>80+yrs</td>
                        <td>
                          {getPercent(
                            age_data.EIGHTY_EIGHTYFOUR + age_data.EIGHTYFIVE_UP,
                            age_data.sample_total
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    );
  }
}

const LoadingButton = (props) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      props.buffer().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = (e) => {
    props.click(e);
    setLoading(true);
  };

  let label = isLoading ? "Loading..." : "RETONE Community";
  if (props.hasValidTradeZone) label += " Unavailable";

  return (
    <Button
      style={{
        margin: "auto",
        width: "60%",
        height: 35,
        padding: "0",
        fontWeight: "bold",
      }}
      variant={props.buttonVariant}
      disabled={props.hasValidTradeZone}
      value={"tradeZone"}
      onClick={!isLoading ? handleClick : null}
    >
      {label}
    </Button>
  );
};

const formatCommas = (value) => {
  if (value == undefined) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPercent = (value, total) => {
  return ((value / total) * 100).toFixed(1) + "%";
};

const capitalize = (word) => {
  let split = word.split(" ");
  let capitalize = [];
  split.forEach((e) => {
    if (e == "or" || e == "or") capitalize.push(e);
    else
      capitalize.push(
        e.substring(0, 1).toUpperCase() + e.substring(1, e.length)
      );
  });
  return capitalize.join(" ");
};

const InfoButton = (props) => {
  const [clicked, setClicked] = useState(false);
  let color = "white";

  if (clicked) {
    //   color = 'rgba(255,255,255,0.5'
  } else color = "white";

  let style = { color: color, marginRight: "0.4em" };

  return (
    <AiOutlineInfoCircle
      size={20}
      onClick={(e) => [setClicked(!clicked), props.clickFunction()]}
      style={style}
    />
  );
};

const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Demographics
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h3 style={{ fontSize: "23px", float: "left", textAlign: "left" }}>
            <strong>RETONE</strong>
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            Click on “RETONE” to see gray overlay on the map page and associated
            data. RETONE is created by a proprietary algorithm that reveals
            insight into the community surrounding a location. It analyzes
            routes traveled in every direction and the average time spent by
            people pursuing everyday activities around their community. RETONE
            computes this into a geographical area to reveal how people live,
            shop and commute. The radius rings on the map page are static to
            illustrate distance for viewer perspective.
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            For commercial real estate, RETONE reveals demographics that are
            most likely to provide a primary and continuous customer base.
            Analyze the RETONE around a proposed location to decide whether or
            not the demographic overlay will support your product or service.
          </h3>

          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            For example, if you are selling young children’s products, you will
            want the RETONE Demographic “Age” breakdown around your location to
            reveal a large percentage of the sample total to show 0-9% in the
            age category.
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            For businesses that cater to everyone, the most important features
            in a location’s RETONE are sheer demographic density, lack of
            competition, and income levels indicating affordability of your
            product or service.
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            For residential users, the RETONE is key for understanding what
            goods and services are available to you and your family conveniently
            in your community. Most importantly, RETONE demographics will
            present the strength and diversity of the people who live within
            your neighborhood.
          </h3>
          <h3 style={{ fontSize: "23px", float: "left", textAlign: "left" }}>
            {" "}
            <strong>Zip Codes</strong>
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            Click on “Zip Code” to see the demographic density and detail within
            your location’s zip code.
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            Zip codes may contain visible gaps, especially in urban areas. This
            will occur in the special case of a designated zip code for a
            particular building or complex such as Penn Station or the Empire
            State Building.
          </h3>
          <h3 style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
            <strong>Statistics</strong>
          </h3>
          <h3 style={{ fontSize: "20px", float: "left", textAlign: "left" }}>
            Demographics statistics are calculated using the U.S.
            Census&#39;s 2019{" "}
            <a href="https://www.census.gov/programs-surveys/acs">
              2019 American Community Survey.
            </a>
          </h3>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = createSelector(
  tractSelector,
  addressSelector,
  dataRangeSelector,
  tradeZoneBoundsSelector,
  statsSelector,
  isCitySelector,
  geoUnitSelector,
  businessTypeSelector,

  (
    tract,
    address,
    data_range,
    tradeZone_bounds,
    stats,
    isCity,
    geo_unit,
    business_type
  ) => ({
    tract,
    address,
    data_range,
    tradeZone_bounds,
    stats,
    isCity,
    geo_unit,
    business_type,
  })
);

const mapActionsToProps = {
  onUpdateDataRange: updateDataRange,
  onUpdateTradeZoneBounds: updateTradeZoneBounds,
  onUpdateBusinessType: updateBusinessType,
  onUpdateStatsZip: updateStatsZip,
  onUpdateStatsTZ: updateStatsTradezone,
};

export default connect(mapStateToProps, mapActionsToProps)(DemographicsPanel);
