//user-actions.js

export const UPDATE_USER = "users:updateUser";
export const SHOW_ERROR = "users:showError";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const loginUser = (userData, token) => {
  return {
    type: LOGIN_USER,
    payload: {
      user: userData,
      token: token,
    },
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export function updateUser(newUser) {
  return {
    type: UPDATE_USER,
    payload: {
      user: newUser,
    },
  };
}

export function showError() {
  return {
    type: SHOW_ERROR,
    payload: {
      user: "ERROR!!",
    },
  };
}
