import React, { Component } from "react";
import NavigationBar from "./Navigation/NavigationBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container, AppBar, Toolbar, Typography } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute";
import History from "./History";
import { isMobile } from "react-device-detect";
import Mission from "./Mission";
import BusinessUsers from "./Users/BusinessUsers";
import ResidentialUsers from "./Users/ResidentialUsers";
import LookUpForm from "./Home/LookUpForm";
import DemographicsPanel from "./Map/Panels/DemographicsPanel";
import { connect } from "react-redux";
import Map from "./Map";
import PlacesList from "./Map/Panels/PlacesList";
import * as selectors from "../Reducers/selectors";
import { createSelector } from "reselect";
import ChartsPanel from "./Map/Panels/ChartsPanel";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Login from "./Login";
import CommentsPanel from "./Map/Panels/CommentsPanel";
import { updateAddress } from "../Actions/address-actions";
import TransportationPanel from "./Map/Panels/TransportationPanel";
// import Footer from "./Navigation/Footer";
import white_smaple from "../images/logo/landmark_logo_spiral_white.png";
import logo_full from "../images/logo/landmark_logo_full_white.png";
import Image from "react-bootstrap/Image";
import Joyride, { STATUS } from "react-joyride";
import { updateUser } from "../Actions/user-actions";
// import { setRecentSearches, getUserInfo } from "../Requests/users-requests";
import Profile from "./Profile";
import AddListing from "./Listings/AddListing";
import ListingView from "./Listings/ListingView";
import ListingsPreviews from "./Listings/ListingPreviews";
import ListingsBrowse from "./Listings/ListingsBrowse";
import Cookies from "universal-cookie";
import About from "./About";
import MediaQuery from "react-responsive";
import { steps } from "./Map/JoyRideSteps";
import { hasSubways } from "../Helpers/Subways";
import AddViewListing from "./Listings/AddListing/AddViewListing";
import ForgotPassword from "./Login/ForgotPassword";
import ResetPassword from "./Login/ResetPassword";
import Sample from "./Feedback";
import "../css/App.scss";
import GoogleMapsOverlay from "./Map/TaxMaps/TaxMap";
import IntroSection from "./Home/Intro";
import PictureGrid from "./Home/Containers";
import Footer from "./Footer/Footer";
import AddListings from "./Listings/AddSaleListings/LandingPage";
import ChatModal from "./Feedback/index";
import FeedbackIcon from "./Feedback/FeedbackIcon";
import CommercialDropdownPage from "./Home/CommercialDropdown";
import ResidentialDropdownPage from "./Home/ResidentialDropdown";
import HowToUsePage from "./HowToUse/index";
import AboutUsPage from "./Home/AboutUs";
import InTouch from "./Home/GetInTouch";
import MapmaxContainer from "./Home/WhyMapmax";
import ContactForm from "./Home/ContactUs";
import DisclaimerPage from "./Home/Disclaimer";
import TermsOfUsePage from "./Home/TermsOfUse";
import PrivacyPolicy from "./Home/UserPrivacy";

import AddSaleResidential from "./Listings/AddSaleListings/Home";
import SaleListingDetailPage from "./Listings/AddSaleListings/SaleListingDetails";

import RetonePage from "./Home/Retone";
import PropertyReviewPage from "./Home/PropertyReview";
import TopographyPage from "./Home/Topography";

import ResidentialRetonePage from "./Home/ResidentialRetone";
import OtherFactors from "./Home/OtherFactors";
import PropertySpecsPage from "./Home/PropertySpecs";
import Data311 from "./CityData/index";

import AllSaleListingsPage from "./Listings/AddSaleListings/BrowseAllSaleListings";
import { useAuth } from "./Contexts/AuthContext";
import validateToken from "./Login/validateToken";

import { loginUser, logoutUser } from "../Actions/user-actions";
import HomePagePopupVideo from "../Components/Home/HomePagePopupVideo";

import Modal from "react-modal"; // Ensure you have this installed
import ReactPlayer from "react-player"; // Ensure you have this installed

import VideoPage from "../Components/Home/HomePageVideo";

import ResidentialReviewPage from "./HowToUse/ResidentialReviewPage";
import CommercialReviewPage from "./HowToUse/CommercialReviewPage";
import UseMapPage from "./HowToUse/MapPage";
import NavigateTextPage from "./HowToUse/NavigateText";

// Update with actual path
//import ShareModal from './ShareModal'

const darkBg = "black";
const cookies = new Cookies();
const theme = createTheme({
  typography: {
    fontFamily: ["Clarkson", "Helvetica", "sans-serif"].join(","),
  },
  // ... other theme customizations
});

class App extends React.Component {
  openChat = () => {
    this.setState({ isChatOpen: true });
  };

  closeChat = () => {
    this.setState({ isChatOpen: false });
  };

  constructor(props) {
    super(props);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);

    this.state = {
      isChatOpen: false,
      run: false,
      searches: [],
      isFirstTimeUser: false,
      run: false,
      type: "",
      showModal: false,
    };
  }
  handleLogout = () => {
    this.props.dispatch(logoutUser()); // Dispatch logout action
    // Optionally perform additional logout logic, such as redirecting
  };

  async componentDidMount() {
    const isFirstVisit = !localStorage.getItem("hasVisited");
    if (isFirstVisit) {
      this.setState({ showModal: true });
      localStorage.setItem("hasVisited", "true");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      try {
        const user = await validateToken(token);
        if (user) {
          this.props.loginUser(user, token); // loginUser should appropriately set isAuthenticated
        }
      } catch (error) {
        console.error("Token validation failed:", error);
      }
    }
    // Removed the user info and recent searches logic as the corresponding backend functions are not available

    if (cookies.get("hasLoggedIn") == undefined && this.props.user._id == -1) {
      this.setState({ isFirstTimeUser: true });
      cookies.set("hasLoggedIn", 1, {
        path: "/",
        expires: new Date("2200-12-1T03:24:00"),
      });
    } else {
      this.setState({ isFirstTimeUser: false });
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onUpdateUser = (user) => {
    this.props.onUpdateUser(user);
  };

  onUpdateAddress = (address) => {
    this.props.onUpdateAddress(address);
  };
  runJoyRideTutorial = () => {
    this.setState({ run: true, isFirstTimeUser: false });
  };

  handleJoyrideCallback = (data) => {
    const { status, action, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (action === "update" && index === 4) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (finishedStatuses.includes(status)) {
      this.setState({ run: false });
    }
  };

  getHelpers = (helpers) => {
    this.helpers = helpers;
  };

  updateIsFirstTime = (flag) => {
    this.setState({ isFirstTimeUser: flag });
  };

  updateBusinessTypeOption = (data) => {
    this.setState({ type: data });
  };

  render() {
    const { address } = this.props;
    const { user } = this.props;
    const { showModal } = this.state;

    const joyrideProps = {
      steps: steps,
      continuous: true,
      run: this.state.run,
      callback: this.handleJoyrideCallback,
      scrollToFirstStep: true,
      showProgress: true,
      showSkipButton: true,
      styles: {
        options: {
          zIndex: isMobile ? 99999999 : 10000, // Conditional zIndex based on mobile detection
        },
      },
    };

    let availHeight = window.screen.availHeight - 170 - 40;

    if (this.props.ready) {
      var map = (
        <div>
          <div className="results-container">
            <div style={{ flex: 1 }}>
              <Map
                runJoyRideTutorial={this.runJoyRideTutorial}
                height={"95.5vh"}
                address={this.props.address}
                center={this.props.address.coords}
                zoom={15}
                business_type={this.props.business_type}
                isFirstTimeUser={this.state.isFirstTimeUser}
                updateIsFirstTime={this.updateIsFirstTime}
                updateBusinessTypeOption={this.updateBusinessTypeOption}
              />
            </div>
          </div>
          <PlacesList type={this.state.type} />
          <div className="transportationWrapper">
            {hasSubways(address.zip) && <TransportationPanel />}
          </div>
        </div>
      );

      {
        /* <div style={{ flex: 1 }}>
              <GoogleMapsOverlay />
            </div> */
      }

      {
        <div
          class="parentDiv"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
          }}
        >
          <div className="demographicsWrappper">
            <DemographicsPanel
              address={this.props.address}
              business_type={this.props.business_type}
              getHelpers={this.getHelpers}
              orientation={"demographics-list-vertical "}
            ></DemographicsPanel>
          </div>
          <div className="placesListWrapper">
            <PlacesList type={this.state.type} />
          </div>
        </div>;
      }
      var mobile_map = (
        <div className="mob_map_dashboard">
          <Map
            runJoyRideTutorial={this.runJoyRideTutorial}
            height={availHeight}
            address={this.props.address}
            center={this.props.address.coords}
            zoom={15}
            business_type={this.props.business_type}
            isFirstTimeUser={this.state.isFirstTimeUser}
            updateIsFirstTime={this.updateIsFirstTime}
            updateBusinessTypeOption={this.updateBusinessTypeOption}
          />
          <PlacesList type={this.state.type} />
          <div className="transportationWrapper">
            {hasSubways(address.zip) && <TransportationPanel />}
          </div>
        </div>
      );
    }

    const { isChatOpen } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Switch>
            <ProtectedRoute
              exact
              path="/addlistings"
              component={AddListings}
              isAuthenticated={user.isAuthenticated}
            />
            <Route
              exact
              path={"/whoweare"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <AboutUsPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/contactus"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <ContactForm />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/disclaimer"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <DisclaimerPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/termsofuse"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <TermsOfUsePage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/userprivacy"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <PrivacyPolicy />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path="/forgot-password"
              render={() => <ForgotPassword />}
            />

            <Route
              exact
              path="/reset-password/:token"
              render={() => <ResetPassword />}
            />
            <Route
              exact
              path="/allsale-listings"
              render={() => <AllSaleListingsPage />}
            />

            <Route
              exact
              path="/getsalelistingdetails/:id"
              render={(props) => <SaleListingDetailPage {...props} />}
            />

            <Route
              path={"/login"}
              render={({ match }) => {
                return (
                  <div className="mainPage">
                    <Login />
                  </div>
                );
              }}
            ></Route>
            <Route
              path={"/register"}
              render={({ match }) => {
                return (
                  <div>
                    <NavigationBar urlParams={match.params} />
                    <div className="App">
                      <header className="App-header">
                        <Register />
                      </header>
                    </div>
                  </div>
                );
              }}
            ></Route>
            <Route
              path={"/forgotPassword"}
              render={({ match }) => {
                return (
                  <div>
                    <NavigationBar urlParams={match.params} />

                    <header className="App-header">
                      <ForgotPassword />
                    </header>
                  </div>
                );
              }}
            ></Route>
            <Route
              path={"/profile"}
              render={({ match }) => {
                return (
                  <div>
                    <NavigationBar urlParams={match.params} />
                    <div className="App">
                      <header className="App-header">
                        <Profile />
                      </header>
                    </div>
                  </div>
                );
              }}
            ></Route>
            <Route
              exact
              path={"/listings/browse"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>
                    <Container>
                      <ListingsBrowse />
                    </Container>
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/listing/:listingId"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>
                    <Container>
                      <ListingView urlParams={match.params} />
                    </Container>
                  </div>
                );
              }}
            ></Route>

            <Route
              path={"/addlisting/:listingId?"}
              render={({ match }) => {
                return (
                  <div>
                    <NavigationBar urlParams={match.params} />
                    <div className="App">
                      <AddListing urlParams={match.params} />
                    </div>
                  </div>
                );
              }}
            ></Route>
            <Route
              path={"/:address/:business_type"}
              render={({ match }) => {
                return (
                  <div className="App" style={{ backgroundColor: darkBg }}>
                    <div style={{ marginBottom: "70px" }}>
                      <NavigationBar
                        displayTutorialLink={true}
                        displayTutorialButton={false}
                        displayHistoryButton={true}
                        displayMissionButton={true}
                        displaySearchBar={true}
                        displayResidentialUsers={true}
                        displayBusinessUsers={true}
                        urlParams={match.params}
                        runJoyRideTutorial={this.runJoyRideTutorial}
                      />
                    </div>
                    {/* <ShareModal /> */}
                    <MediaQuery minDeviceWidth={551}>
                      <>
                        <Joyride
                          steps={steps}
                          continuous={true}
                          run={this.state.run}
                          callback={this.handleJoyrideCallback}
                          scrollToFirstStep={true}
                          showProgress={true}
                          showSkipButton={true}
                          styles={{
                            options: {
                              zIndex: 10000,
                            },
                          }}
                        />
                        {map}
                      </>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={550}>
                      <>
                        <Joyride
                          steps={steps}
                          continuous={true}
                          run={this.state.run}
                          callback={this.handleJoyrideCallback}
                          scrollToFirstStep={true}
                          showProgress={true}
                          showSkipButton={true}
                          styles={{
                            options: {
                              zIndex: 99999999,
                            },
                          }}
                        />
                        {map}
                      </>
                    </MediaQuery>

                    {/* {hasSubways(address.zip) && <Data311 />} */}
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/history"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <History />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/mission"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>

                    <Mission />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path="/residentialusers"
              render={() => <ResidentialUsers />}
            ></Route>
            <Route
              exact
              path="/businessusers"
              render={() => <BusinessUsers />}
            ></Route>
            <Route
              exact
              path="/addviewlistings"
              render={() => <AddViewListing />}
            ></Route>
            <Route
              exact
              path="/add-listing-for-sale"
              render={() => <AddSaleResidential />}
            ></Route>

            <Route
              exact
              path={"/commercial"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <CommercialDropdownPage />
                  </div>
                );
              }}
            ></Route>
            <Route
              exact
              path={"/howtouse"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <HowToUsePage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/usemappage"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <UseMapPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/residentialreviewpage"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <ResidentialReviewPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/commercialreviewpage"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <CommercialReviewPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/navigatetextpage"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <NavigateTextPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/retone"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <RetonePage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/residentialretone"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <ResidentialRetonePage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/propertyreview"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <PropertyReviewPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/propertyspecs"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <PropertySpecsPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/topography"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <TopographyPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/otherfactors"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <OtherFactors />
                  </div>
                );
              }}
            ></Route>

            <Route exact path="/aboutus" render={() => <AboutUsPage />}></Route>
            <Route
              exact
              path={"/residential"}
              render={({ match }) => {
                return (
                  <div>
                    <AppBar position="static">
                      <Toolbar style={{ backgroundColor: "white" }}>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>{" "}
                    <ResidentialDropdownPage />
                  </div>
                );
              }}
            ></Route>

            <Route
              exact
              path={"/"}
              render={({ match }) => {
                return (
                  <div className="App-main">
                    {/* AppBar from MUI as NavigationBar */}
                    <AppBar position="static">
                      <Toolbar>
                        <NavigationBar urlParams={match.params} />
                      </Toolbar>
                    </AppBar>
                    {/* The rest of the components follow */}
                    <div className="lookupWrapper">
                      {/* LookUpForm comes right after NavigationBar */}
                      <LookUpForm />
                      <VideoPage />
                      {/* <MapmaxContainer />
                      <InTouch /> */}
                      {/* <IntroSection /> */}

                      {/* <PictureGrid /> */}
                    </div>
                    <HomePagePopupVideo />
                  </div>
                );
              }}
            ></Route>

            <Route exact path="/homepage">
              <Redirect to="/" />
            </Route>
          </Switch>
          {/* <Footer /> */}
          {/* <ChatModal /> */}
        </div>
        <Footer />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = createSelector(
  selectors.addressSelector,
  selectors.businessTypeSelector,
  selectors.readySelector,
  selectors.isCitySelector,
  selectors.userSelector,
  (address, business_type, ready, isCity, user) => ({
    address,
    business_type,
    ready,
    isCity,
    user,
  })
);

const mapActionsToProps = {
  onUpdateAddress: updateAddress,
  onUpdateUser: updateUser,
  loginUser,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(App));
