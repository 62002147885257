// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Typography, Grid, Box, Paper, Divider } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import MapComponent from "./MapComponent"; // Ensure this component is correctly implemented

// function SaleListingDetailPage({ match }) {
//   const [listingData, setListingData] = useState(null);
//   const [error, setError] = useState(""); // Error state
//   const listingId = match.params.id;

//   useEffect(() => {
//     const fetchListing = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:8081/get-listing/${listingId}`
//         );
//         setListingData(response.data);
//       } catch (error) {
//         setError("Failed to fetch listing data"); // Set the error state
//       }
//     };

//     fetchListing();
//   }, [listingId]);

//   if (!listingData) {
//     return <Typography>Loading...</Typography>;
//   }

//   if (error) {
//     return <Typography color="error">{error}</Typography>;
//   }

//   // Define columns for MUI Data Grid for structured data presentation
//   const columns = [
//     { field: "label", headerName: "Label", width: 150 },
//     { field: "value", headerName: "Value", flex: 1 },
//   ];

//   // Mapping listingData to Data Grid format
//   const pageDataToRows = (pageData) => {
//     return Object.keys(pageData || {}).map((key) => ({
//       id: key,
//       label:
//         key.charAt(0).toUpperCase() +
//         key
//           .slice(1)
//           .replace(/([A-Z])/g, " $1")
//           .trim(),
//       value: pageData[key] || "Not available",
//     }));
//   };

//   return (
//     <Box sx={{ flexGrow: 1, p: 4 }}>
//       <Typography variant="h3" gutterBottom>
//         Listing Details
//       </Typography>
//       <Divider sx={{ mb: 4 }} />
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={6}>
//           <Typography variant="h5" gutterBottom>
//             Basic Information
//           </Typography>
//           <Paper elevation={2} sx={{ height: 400 }}>
//             <DataGrid
//               rows={pageDataToRows(listingData.page1Data)}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//               disableSelectionOnClick
//             />
//           </Paper>
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <Typography variant="h5" gutterBottom>
//             Property Details
//           </Typography>
//           <Paper elevation={2} sx={{ height: 400 }}>
//             <DataGrid
//               rows={pageDataToRows(listingData.page2Data)}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//               disableSelectionOnClick
//             />
//           </Paper>
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <Typography variant="h5" gutterBottom>
//             More Information
//           </Typography>
//           <Paper elevation={2} sx={{ height: 400 }}>
//             <DataGrid
//               rows={pageDataToRows(listingData.page3Data)}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//               disableSelectionOnClick
//             />
//           </Paper>
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <Typography variant="h5" gutterBottom>
//             Images
//           </Typography>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               gap: 2,
//               maxHeight: 400,
//               overflowY: "auto",
//             }}
//           >
//             {listingData.images &&
//               listingData.images.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt={`Property ${index}`}
//                   style={{ width: "100%", height: "auto", borderRadius: "4px" }}
//                 />
//               ))}
//           </Box>
//         </Grid>

//         <Grid item xs={12}>
//           <Typography variant="h5" gutterBottom>
//             Location
//           </Typography>
//           {listingData.page1Data?.address && (
//             <Paper elevation={2} sx={{ height: 400 }}>
//               <MapComponent address={listingData.page1Data.address} />
//             </Paper>
//           )}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }

// export default SaleListingDetailPage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Grid,
  Box,
  Paper,
  Divider,
  Modal,
  IconButton,
  Container,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import MapComponent from "./MapComponent";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function SaleListingDetailPage({ match }) {
  const [listingData, setListingData] = useState(null);
  const [error, setError] = useState(""); // Error state
  const listingId = match.params.id;
  const [openModal, setOpenModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Open modal with image
  const handleOpenModal = (index) => {
    setCurrentImageIndex(index);
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Navigate to next image
  const handleNext = () => {
    setCurrentImageIndex((prev) => (prev + 1) % listingData.images.length);
  };

  // Navigate to previous image
  const handlePrev = () => {
    setCurrentImageIndex(
      (prev) =>
        (prev - 1 + listingData.images.length) % listingData.images.length
    );
  };

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await axios.get(
          `http://localhost:8081/get-listing/${listingId}`
        );
        setListingData(response.data);
      } catch (error) {
        setError("Failed to fetch listing data"); // Set the error state
      }
    };

    fetchListing();
  }, [listingId]);

  if (!listingData) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  // Define columns for MUI Data Grid for structured data presentation
  const columns = [
    { field: "label", headerName: "Label", width: 150 },
    { field: "value", headerName: "Value", flex: 1 },
  ];

  // Mapping listingData to Data Grid format
  const pageDataToRows = (pageData) => {
    return Object.keys(pageData || {}).map((key) => ({
      id: key,
      label:
        key.charAt(0).toUpperCase() +
        key
          .slice(1)
          .replace(/([A-Z])/g, " $1")
          .trim(),
      value: pageData[key] || "Not available",
    }));
  };

  return (
    <Container maxWidth="lg" style={{ width: "75%" }}>
      <Box
        sx={{
          flexGrow: 1,
          padding: 4,
          margin: "auto",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        <Typography variant="h3" gutterBottom>
          Listing Details
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Basic Information
            </Typography>
            <Paper elevation={2} sx={{ height: 400 }}>
              <DataGrid
                rows={pageDataToRows(listingData.page1Data)}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Location Map
            </Typography>
            <Paper elevation={2} sx={{ height: 300, overflow: "hidden" }}>
              {listingData.page1Data?.address && (
                <MapComponent address={listingData.page1Data.address} />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Property Details
            </Typography>
            <Paper elevation={2} sx={{ height: 400 }}>
              <DataGrid
                rows={pageDataToRows(listingData.page2Data)}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              More Information
            </Typography>
            <Paper elevation={2} sx={{ height: 400 }}>
              <DataGrid
                rows={pageDataToRows(listingData.page3Data)}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Images
            </Typography>
            <Paper elevation={2} sx={{ p: 2 }}>
              <ImageList variant="quilted" cols={4} rowHeight={121}>
                {listingData.images.map((item, index) => {
                  // Assigning cols and rows based on index for example emphasis
                  let cols, rows;
                  if (index % 5 === 0) {
                    cols = 2;
                    rows = 2;
                  } else if (index % 6 === 0) {
                    cols = 2;
                    rows = 1;
                  } else {
                    cols = 1;
                    rows = 1;
                  }

                  return (
                    <ImageListItem
                      key={index}
                      cols={cols}
                      rows={rows}
                      onClick={() => handleOpenModal(index)}
                    >
                      <img
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={`Property ${index}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </Paper>
          </Grid>
          {/* Modal for Image View */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ position: "relative", outline: "none" }}>
              <IconButton
                sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                sx={{
                  position: "absolute",
                  left: 8,
                  top: "50%",
                  color: "white",
                  zIndex: 1000,
                }}
                onClick={handlePrev}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <img
                src={`${listingData.images[currentImageIndex]}?w=600&h=600&fit=crop&auto=format`}
                alt="Current"
                style={{ maxHeight: "80vh", maxWidth: "80vw" }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  color: "white",
                  zIndex: 1000,
                }}
                onClick={handleNext}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Modal>
          {/* Map Section */}
        </Grid>
      </Box>
    </Container>
  );
}

export default SaleListingDetailPage;
