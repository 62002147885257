import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  lineHeight: "60px",
}));
const VideoBox = (props) => {
  const theme = useTheme(); // Use the theme for consistent styling

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%", // Ensure it takes full width
        paddingTop: "56.25%", // Maintain a 16:9 aspect ratio
        overflow: "hidden",
        backgroundColor: "#000", // Background color for the video area
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const IntroSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4), // Padding for the entire section
        overflow: "hidden",
        backgroundColor: "#e8e4e4",
      }}
    >
      <Grid container spacing={theme.spacing(4)}>
        {/* Video Side */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VideoBox>
            <iframe
              title="Embedded YouTube Video"
              src="https://www.youtube.com/watch?v=ez64TLaL4n4" // Use embed URL here
              style={{
                width: "100%",
                height: "100%",
                border: 0,
              }}
              frameBorder="0"
              allowFullScreen
            />
          </VideoBox>
        </Grid>

        {/* Text Side */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center the content vertically
            alignItems: "start", // Align items to the start of the box
            padding: theme.spacing(4), // Padding around text content
          }}
        >
          <Box>
            <Typography variant="h6" gutterBottom component="div">
              Create a website
            </Typography>
            <Typography variant="body1" paragraph>
              Choose from any of our industry-leading website templates,
              designer fonts, and color palettes.
            </Typography>

            <Typography variant="h6" gutterBottom component="div">
              Sell your products and services
            </Typography>
            <Typography variant="body1" paragraph>
              Set up an ecommerce store, book appointments, or sell your
              skills—all on a single platform built just for you.
            </Typography>

            <Typography variant="h6" gutterBottom component="div">
              Market your business
            </Typography>
            <Typography variant="body1" paragraph>
              On-brand email campaigns and social tools make it easy to retain
              customers and grow your base.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntroSection;
