// import React, { useState, useRef } from "react";
// import { withRouter, Link } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
//   Button,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Collapse,
//   Box,
//   useTheme,
//   useMediaQuery,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import logoImage from "../../images/newmapmaxlogo.png"; // Your logo image path

// function NavigationBar(props) {
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [aboutUsOpen, setAboutUsOpen] = useState(false);
//   const [aboutUsButtonPosition, setAboutUsButtonPosition] = useState(null);
//   const aboutUsButtonRef = useRef(null);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setMobileOpen(open);
//   };

//   const toggleAboutUs = (event) => {
//     // Check if the event object is provided
//     if (event) {
//       // Prevent event from propagating to the drawer's onClick handler
//       event.stopPropagation();
//     }

//     if (!isMobile && aboutUsButtonRef.current) {
//       setAboutUsButtonPosition(
//         aboutUsButtonRef.current.getBoundingClientRect()
//       );
//     }
//     setAboutUsOpen(!aboutUsOpen);
//   };

//   const aboutUsSubMenuItems = (
//     <List component="div" disablePadding>
//       <ListItem button onClick={() => props.history.push("/whoweare")}>
//         <ListItemText primary="Who We Are" />
//       </ListItem>
//       <ListItem button onClick={() => props.history.push("/history")}>
//         <ListItemText primary="History & Intent" />
//       </ListItem>
//       <ListItem button onClick={() => props.history.push("/mission")}>
//         <ListItemText primary="Mission" />
//       </ListItem>
//     </List>
//   );

//   const aboutUsSubMenu = (
//     <Collapse in={aboutUsOpen} timeout="auto" unmountOnExit>
//       {aboutUsSubMenuItems}
//     </Collapse>
//   );

//   const drawer = (
//     <div
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//       style={{ backgroundColor: "white", color: "black" }}
//     >
//       <IconButton
//         color="inherit"
//         aria-label="close drawer"
//         edge="start"
//         onClick={toggleDrawer(false)}
//         sx={{ marginLeft: "auto", marginRight: 1 }}
//       >
//         <CloseIcon />
//       </IconButton>
//       <List>
//         {[
//           "Home Page",
//           "Commercial",
//           "Residential",
//           "How to Use",
//           "Contact Us",
//           // "Blog",
//           // "Manage Listings",
//           // "Resources",
//           // "Add Listings",
//         ].map((text) => (
//           <ListItem
//             button
//             key={text}
//             onClick={() =>
//               props.history.push(`/${text.toLowerCase().replace(/\s+/g, "")}`)
//             }
//           >
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}

//         <ListItem button onClick={(event) => toggleAboutUs(event)}>
//           <ListItemText primary="About Us" />
//           {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//         </ListItem>
//         {aboutUsSubMenu}
//       </List>
//     </div>
//   );

//   return (
//     <AppBar
//       position="fixed"
//       style={{
//         backgroundColor: "white",
//         color: "black",
//         minHeight: "85px",
//       }}
//     >
//       <Toolbar>
//         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//           <Link to="/" style={{ color: "black", textDecoration: "none" }}>
//             <img src={logoImage} alt="Logo" style={{ height: "60px" }} />
//           </Link>
//         </Typography>
//         {isMobile ? (
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="end"
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//         ) : (
//           <div>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Home Page
//             </Button>

//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/commercial")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Commercial
//             </Button>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/residential")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Residential
//             </Button>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/howtouse")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               How to Use
//             </Button>
//             <Button
//               color="inherit"
//               ref={aboutUsButtonRef}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//               onClick={() => {
//                 if (aboutUsButtonRef.current) {
//                   setAboutUsButtonPosition(
//                     aboutUsButtonRef.current.getBoundingClientRect()
//                   );
//                 }
//                 toggleAboutUs();
//               }}
//             >
//               About Us {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//             </Button>
//             {aboutUsOpen && (
//               <Box
//                 position="absolute"
//                 left={aboutUsButtonPosition?.left ?? 0}
//                 top={aboutUsButtonPosition?.bottom ?? 0}
//                 zIndex="modal"
//                 bgcolor="white" // White background for submenu
//               >
//                 {aboutUsSubMenuItems}
//               </Box>
//             )}
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/contactus")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f", // Replace with your desired hover color
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Contact Us
//             </Button>
//           </div>
//         )}
//       </Toolbar>
//       {isMobile && (
//         <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer(false)}>
//           {drawer}
//         </Drawer>
//       )}
//     </AppBar>
//   );
// }

// export default withRouter(NavigationBar);

// import React, { useState, useRef, useEffect } from "react";
// import { withRouter, Link } from "react-router-dom";
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
//   Button,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Collapse,
//   Box,
//   useTheme,
//   useMediaQuery,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import CloseIcon from "@mui/icons-material/Close";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import logoImage from "../../images/newmapmaxlogo.png"; // Your logo image path

// function NavigationBar(props) {
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [aboutUsOpen, setAboutUsOpen] = useState(false);
//   const [aboutUsButtonPosition, setAboutUsButtonPosition] = useState(null);
//   const aboutUsButtonRef = useRef(null);
//   const aboutUsMenuRef = useRef(null);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setMobileOpen(open);
//   };

//   const toggleAboutUs = (event) => {
//     if (event) {
//       event.stopPropagation();
//     }

//     if (!isMobile && aboutUsButtonRef.current) {
//       setAboutUsButtonPosition(
//         aboutUsButtonRef.current.getBoundingClientRect()
//       );
//     }
//     setAboutUsOpen(!aboutUsOpen);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         aboutUsButtonRef.current &&
//         !aboutUsButtonRef.current.contains(event.target) &&
//         aboutUsMenuRef.current &&
//         !aboutUsMenuRef.current.contains(event.target)
//       ) {
//         setAboutUsOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const aboutUsSubMenuItems = (
//     <List component="div" disablePadding ref={aboutUsMenuRef}>
//       <ListItem button onClick={() => props.history.push("/whoweare")}>
//         <ListItemText primary="Who We Are" />
//       </ListItem>
//       <ListItem button onClick={() => props.history.push("/history")}>
//         <ListItemText primary="History & Intent" />
//       </ListItem>
//       <ListItem button onClick={() => props.history.push("/mission")}>
//         <ListItemText primary="Mission" />
//       </ListItem>
//     </List>
//   );

//   const aboutUsSubMenu = (
//     <Collapse in={aboutUsOpen} timeout="auto" unmountOnExit>
//       {aboutUsSubMenuItems}
//     </Collapse>
//   );

//   const drawer = (
//     <div
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//       style={{ backgroundColor: "white", color: "black" }}
//     >
//       <IconButton
//         color="inherit"
//         aria-label="close drawer"
//         edge="start"
//         onClick={toggleDrawer(false)}
//         sx={{ marginLeft: "auto", marginRight: 1 }}
//       >
//         <CloseIcon />
//       </IconButton>
//       <List>
//         {[
//           "Home Page",
//           "Commercial",
//           "Residential",
//           "How to Use",
//           "Contact Us",
//         ].map((text) => (
//           <ListItem
//             button
//             key={text}
//             onClick={() =>
//               props.history.push(`/${text.toLowerCase().replace(/\s+/g, "")}`)
//             }
//           >
//             <ListItemText primary={text} />
//           </ListItem>
//         ))}

//         <ListItem button onClick={(event) => toggleAboutUs(event)}>
//           <ListItemText primary="About Us" />
//           {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//         </ListItem>
//         {aboutUsSubMenu}
//       </List>
//     </div>
//   );

//   return (
//     <AppBar
//       position="fixed"
//       style={{
//         backgroundColor: "white",
//         color: "black",
//         minHeight: "85px",
//       }}
//     >
//       <Toolbar>
//         <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//           <Link to="/" style={{ color: "black", textDecoration: "none" }}>
//             <img src={logoImage} alt="Logo" style={{ height: "60px" }} />
//           </Link>
//         </Typography>
//         {isMobile ? (
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="end"
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//         ) : (
//           <div>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Home Page
//             </Button>

//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/commercial")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Commercial
//             </Button>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/residential")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Residential
//             </Button>
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/howtouse")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               How to Use
//             </Button>
//             <Button
//               color="inherit"
//               ref={aboutUsButtonRef}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//               onClick={(event) => toggleAboutUs(event)}
//             >
//               About Us {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//             </Button>
//             {aboutUsOpen && (
//               <Box
//                 position="absolute"
//                 left={aboutUsButtonPosition?.left ?? 0}
//                 top={aboutUsButtonPosition?.bottom ?? 0}
//                 zIndex="modal"
//                 bgcolor="white"
//                 ref={aboutUsMenuRef}
//               >
//                 {aboutUsSubMenuItems}
//               </Box>
//             )}
//             <Button
//               color="inherit"
//               onClick={() => props.history.push("/contactus")}
//               sx={{
//                 "&:hover": {
//                   backgroundColor: "#33ab9f",
//                 },
//                 "&:focus": {
//                   outline: "none",
//                 },
//               }}
//             >
//               Contact Us
//             </Button>
//           </div>
//         )}
//       </Toolbar>
//       {isMobile && (
//         <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer(false)}>
//           {drawer}
//         </Drawer>
//       )}
//     </AppBar>
//   );
// }

// export default withRouter(NavigationBar);

import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import logoImage from "../../images/newmapmaxlogo.png"; // Your logo image path

function NavigationBar(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [aboutUsButtonPosition, setAboutUsButtonPosition] = useState(null);
  const aboutUsButtonRef = useRef(null);
  const aboutUsMenuRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMobileOpen(open);
  };

  const toggleAboutUs = (event) => {
    if (event) {
      event.stopPropagation();
    }

    if (!isMobile && aboutUsButtonRef.current) {
      setAboutUsButtonPosition(
        aboutUsButtonRef.current.getBoundingClientRect()
      );
    }
    setAboutUsOpen(!aboutUsOpen);
  };

  const handleMenuItemClick = (path) => {
    props.history.push(path);
    setAboutUsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        aboutUsButtonRef.current &&
        !aboutUsButtonRef.current.contains(event.target) &&
        aboutUsMenuRef.current &&
        !aboutUsMenuRef.current.contains(event.target)
      ) {
        setAboutUsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const aboutUsSubMenuItems = (
    <List component="div" disablePadding ref={aboutUsMenuRef}>
      <ListItem button onClick={() => handleMenuItemClick("/whoweare")}>
        <ListItemText primary="Who We Are" />
      </ListItem>
      <ListItem button onClick={() => handleMenuItemClick("/history")}>
        <ListItemText primary="History & Intent" />
      </ListItem>
      <ListItem button onClick={() => handleMenuItemClick("/mission")}>
        <ListItemText primary="Mission" />
      </ListItem>
    </List>
  );

  const aboutUsSubMenu = (
    <Collapse in={aboutUsOpen} timeout="auto" unmountOnExit>
      {aboutUsSubMenuItems}
    </Collapse>
  );

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ backgroundColor: "white", color: "black" }}
    >
      <IconButton
        color="inherit"
        aria-label="close drawer"
        edge="start"
        onClick={toggleDrawer(false)}
        sx={{ marginLeft: "auto", marginRight: 1 }}
      >
        <CloseIcon />
      </IconButton>
      <List>
        {[
          "Home Page",
          "Commercial",
          "Residential",
          "How to Use",
          "Contact Us",
        ].map((text) => (
          <ListItem
            button
            key={text}
            onClick={() =>
              handleMenuItemClick(`/${text.toLowerCase().replace(/\s+/g, "")}`)
            }
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}

        <ListItem button onClick={(event) => toggleAboutUs(event)}>
          <ListItemText primary="About Us" />
          {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        {aboutUsSubMenu}
      </List>
    </div>
  );

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "white",
        color: "black",
        minHeight: "85px",
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            <img src={logoImage} alt="Logo" style={{ height: "60px" }} />
          </Link>
        </Typography>
        {isMobile ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div>
            <Button
              color="inherit"
              onClick={() => handleMenuItemClick("/")}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              Home Page
            </Button>

            <Button
              color="inherit"
              onClick={() => handleMenuItemClick("/commercial")}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              Commercial
            </Button>
            <Button
              color="inherit"
              onClick={() => handleMenuItemClick("/residential")}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              Residential
            </Button>
            <Button
              color="inherit"
              onClick={() => handleMenuItemClick("/howtouse")}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              How to Use
            </Button>
            <Button
              color="inherit"
              ref={aboutUsButtonRef}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={(event) => toggleAboutUs(event)}
            >
              About Us {aboutUsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            {aboutUsOpen && (
              <Box
                position="absolute"
                left={aboutUsButtonPosition?.left ?? 0}
                top={aboutUsButtonPosition?.bottom ?? 0}
                zIndex="modal"
                bgcolor="white"
                ref={aboutUsMenuRef}
              >
                {aboutUsSubMenuItems}
              </Box>
            )}
            <Button
              color="inherit"
              onClick={() => handleMenuItemClick("/contactus")}
              sx={{
                "&:hover": {
                  backgroundColor: "#33ab9f",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
            >
              Contact Us
            </Button>
          </div>
        )}
      </Toolbar>
      {isMobile && (
        <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
      )}
    </AppBar>
  );
}

export default withRouter(NavigationBar);
