import { buildMap, Boroughs } from "./zipcodes";
const halfMile = 0.804672; // 1.207008
const oneMile = 1.60934; // 1.6
const twoMiles = 3.21869;
const threeMiles = 4.828032;
const fiveMiles = 8.04672;

const zipMap = buildMap();
const { Brooklyn, Bronx, Queens, Staten, Manhattan, Nassau } = Boroughs; // Staten,

export const determineRadius = (zip) => {
  switch (zipMap.get(zip)) {
    case Brooklyn:
      return { radius: twoMiles };
    case Bronx:
      return { radius: oneMile };
    case Queens:
      return { radius: twoMiles };
    case Staten:
      return { radius: fiveMiles };
    case Manhattan:
      return { radius: oneMile, isnyc: true };
    case Nassau:
      return { radius: fiveMiles };
    default:
      return { radius: fiveMiles };
  }
};

export const determinePlacesRadius = (zip) => {
  switch (zipMap.get(zip)) {
    case Brooklyn:
      return { radius: twoMiles };
    case Bronx:
      return { radius: halfMile };
    case Queens:
      return { radius: twoMiles };
    case Staten:
      return { radius: fiveMiles };
    case Manhattan:
      return { radius: halfMile, isnyc: true };
    case Nassau:
      return { radius: fiveMiles };
    default:
      return { radius: fiveMiles };
  }
};

export const getCounty = (zip) => zipMap.get(zip);
