import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Grid,
  Box,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { styled } from "@mui/material/styles";

// Customizing theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#2c3e50", // Dark shade
    },
    secondary: {
      main: "#3498db", // Light blue
    },
    error: {
      main: "#e74c3c",
    },
    background: {
      default: "#ecf0f1", // Soft Gray
    },
  },
  typography: {
    fontFamily: "Quicksand, Arial",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  transition: "0.3s",
  "&:hover": {
    boxShadow: theme.shadows[20],
  },
}));

function AddlListings() {
  let history = useHistory();

  const handleAddListingClick = (path) => {
    history.push(path);
  };

  const CardItem = ({ title, description, path }) => (
    <Grid item xs={12} sm={6} lg={4}>
      <StyledCard>
        <CardMedia
          component="img"
          height="140"
          image="https://source.unsplash.com/featured/?building" // Replace with relevant image
          alt="Property"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => handleAddListingClick(path)}
          >
            Add your listing
          </Button>
        </CardActions>
      </StyledCard>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundImage:
            "url('https://source.unsplash.com/featured/?cityscape')", // Professional background
          backgroundSize: "cover",
          minHeight: "100vh",
          py: 8,
          px: 3,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ background: "#fff", borderRadius: 2, p: 2 }}
        >
          {/* Section for Commercial Listings */}
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            color="primary"
            fontWeight="fontWeightBold"
            marginBottom={4}
          >
            Residential Listings
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <CardItem
              title="For Sale"
              description="Explore residential properties available for sale."
              path="/add-listing-for-sale"
            />
            <CardItem
              title="For Lease"
              description="Find the perfect residential property for lease."
              path="/add-listing-for-lease"
            />
            {/* Add more cards as needed */}
          </Grid>

          {/* Section for Residential Listings */}
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            color="primary"
            fontWeight="fontWeightBold"
            marginTop={8}
            marginBottom={4}
          >
            Commercial Listings
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <CardItem
              title="For Commericial"
              description="Find the perfect commercial property."
              path="/add-commercial"
            />
            {/* Add more cards as needed */}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default AddlListings;
