async function validateToken(token) {
  try {
    const response = await fetch("http://localhost:8081/validate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    if (!response.ok) {
      throw new Error("Token validation failed");
    }

    const { user } = await response.json();
    return user;
  } catch (error) {
    console.error("Error validating token:", error);
    throw error;
  }
}

export default validateToken;
