// import React, { useState } from "react";
// import { Box, Button } from "@mui/material";

// function Page4({ goToNextPage }) {
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);

//     const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
//     let allFilesSupported = true;

//     const filesArray = selectedFiles.map((file) => {
//       if (!supportedFormats.includes(file.type)) {
//         allFilesSupported = false; // Flag if any file is not supported
//       }
//       return URL.createObjectURL(file);
//     });

//     if (!allFilesSupported) {
//       alert("Only JPG, JPEG, and PNG files are allowed.");
//       e.target.value = null; // Clear the selected files
//       return; // Exit the function if any file is not supported
//     }

//     setImages((prevImages) => prevImages.concat(filesArray));
//     localStorage.setItem("page4Images", JSON.stringify(filesArray)); // Optionally saving the images array for review
//   };

//   // Render the image previews
//   const renderImages = () => {
//     return images.map((image, index) => (
//       <Box
//         key={index}
//         component="img"
//         sx={{
//           width: "100%",
//           height: 200,
//           objectFit: "cover",
//           borderRadius: "8px",
//           margin: "8px",
//         }}
//         src={image}
//         alt={`img-${index}`}
//       />
//     ));
//   };

//   return (
//     <div>
//       <input
//         accept="image/*"
//         style={{ display: "none" }}
//         id="raised-button-file"
//         multiple
//         type="file"
//         onChange={handleImageChange}
//       />
//       <label htmlFor="raised-button-file">
//         <Button variant="contained" component="span">
//           Upload Images
//         </Button>
//       </label>
//       <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
//         {renderImages()}
//       </Box>

//       <Button
//         variant="contained"
//         onClick={() => {
//           // Assuming you'll handle image data or references appropriately
//           goToNextPage(); // Navigate to the review page
//         }}
//         style={{ marginTop: "20px" }}
//       >
//         Go to Review Page
//       </Button>
//     </div>
//   );
// }

// export default Page4;

import React, { useState } from "react";
import { Box, Button } from "@mui/material";

function Page4({ setImageFiles, goToNextPage }) {
  // Added goToNextPage as a prop
  const [images, setImages] = useState([]); // To store image URLs for preview

  const handleImageChange = (e) => {
    const fileObjects = Array.from(e.target.files);
    const filesArray = fileObjects.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => prevImages.concat(filesArray));
    setImageFiles(fileObjects); // Update lifted state with actual file objects
    e.target.value = null; // Clear the selected files
  };

  // Render the image previews
  const renderImages = () => {
    return images.map((image, index) => (
      <Box
        key={index}
        component="img"
        sx={{
          width: "100%",
          height: 200,
          objectFit: "cover",
          borderRadius: "8px",
          margin: "8px",
        }}
        src={image}
        alt={`img-${index}`}
      />
    ));
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Upload Images
        </Button>
      </label>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {renderImages()}
      </Box>

      <Button
        variant="contained"
        onClick={goToNextPage} // Use the goToNextPage function passed as a prop
        style={{ marginTop: "20px" }}
      >
        Go to Review Page
      </Button>
    </div>
  );
}

export default Page4;
