// import React from "react";
// import PlacesAutocomplete from "react-places-autocomplete";
// import { updateAddress } from "../Actions/address-actions";
// import { updateBusinessType } from "../Actions/business-actions";
// import { updateZip } from "../Actions/zip-actions";
// import { updateState } from "../Actions/state-actions";
// import { connect } from "react-redux";
// import { createSelector } from "reselect";
// import * as selectors from "../Reducers/selectors";

// class AutoCompleteBar extends React.Component {
//   constructor(props) {
//     super(props);
//     let address = "";

//     if (this.props.urlParams != undefined) {
//       address = JSON.parse(this.props.urlParams.address);
//     }
//     this.state = { address: address };
//     this.handleChange = this.handleChange.bind(this);
//     this.onUpdateAddress = this.onUpdateAddress.bind(this);
//   }

//   handleChange = (address) => {
//     this.setState({ address });
//   };

//   handleSelect = async (address) => {
//     this.handleChange(address);
//     //  this.onUpdateAddress(addressState)
//     this.props.addressFunction(address);
//   };

//   onUpdateAddress(address) {
//     this.props.onUpdateAddress(address);
//   }

//   render() {
//     const customStyle = {
//       position: this.props.position,
//       width: "100%",
//       height: "100%",
//       marginTop: this.props.marginTop,
//       color: "black",
//       zIndex: 999999,
//     };

//     return (
//       <PlacesAutocomplete
//         value={this.state.address}
//         onChange={this.handleChange}
//         onSelect={this.handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div className="autoCompleteContainer">
//             <input
//               className="autoCompleteInput"
//               style={{
//                 width: this.props.width ? this.props.width : "100%",
//                 height: "55px",

//                 fontSize:
//                   this.props.fontSize == undefined ? 22 : this.props.fontSize,
//               }}
//               {...getInputProps({
//                 placeholder: "Please type your address search here ...",
//                 className: "location-search-input",
//                 id: "location-search-input",
//               })}
//             />
//             {suggestions.length > 0 && (
//               <div id="autoCompleteSuggestions" style={customStyle}>
//                 {loading && <div>Loading...</div>}
//                 {suggestions.map((suggestion) => {
//                   const className = suggestion.active
//                     ? "suggestion-item--active"
//                     : "suggestion-item";

//                   // inline style for demonstration purpose
//                   const defaultStyle = {
//                     cursor: "pointer",
//                     width: "100%",
//                     marginLeft: ".5em",
//                     color: "black",
//                   };
//                   const style = suggestion.active
//                     ? { ...defaultStyle, backgroundColor: "whitesmoke" }
//                     : { ...defaultStyle, backgroundColor: "#fafafa" };

//                   return (
//                     <div
//                       {...getSuggestionItemProps(suggestion, {
//                         className,
//                         style,
//                       })}
//                     >
//                       <span style={{ color: "black" }}>
//                         {suggestion.description}
//                       </span>
//                     </div>
//                   );
//                 })}
//               </div>
//             )}
//           </div>
//         )}
//       </PlacesAutocomplete>
//     );
//   }
// }

// const mapStateToProps = createSelector(
//   selectors.addressSelector,
//   selectors.businessTypeSelector,
//   selectors.zipSelector,
//   selectors.stateSelector,
//   (address, business_type, zip, state) => ({
//     address,
//     business_type,
//     zip,
//     state,
//   })
// );

// const mapActionsToProps = {
//   onUpdateAddress: updateAddress,
//   onUpdateBusinessType: updateBusinessType,
//   onUpdateZip: updateZip,
//   onUpdateState: updateState,
// };

// export default connect(mapStateToProps, mapActionsToProps)(AutoCompleteBar);

// import React from "react";
// import PlacesAutocomplete from "react-places-autocomplete";
// import { updateAddress } from "../Actions/address-actions";
// import { updateBusinessType } from "../Actions/business-actions";
// import { updateZip } from "../Actions/zip-actions";
// import { updateState } from "../Actions/state-actions";
// import { connect } from "react-redux";
// import { createSelector } from "reselect";
// import * as selectors from "../Reducers/selectors";

// class AutoCompleteBar extends React.Component {
//   constructor(props) {
//     super(props);
//     let address = "";

//     if (this.props.urlParams != undefined) {
//       address = JSON.parse(this.props.urlParams.address);
//     }
//     this.state = { address: address };
//     this.handleChange = this.handleChange.bind(this);
//     this.onUpdateAddress = this.onUpdateAddress.bind(this);
//   }

//   handleChange = (address) => {
//     this.setState({ address });
//   };

//   handleSelect = async (address) => {
//     this.handleChange(address);
//     this.props.addressFunction(address);
//   };

//   onUpdateAddress(address) {
//     this.props.onUpdateAddress(address);
//   }

//   render() {
//     const customStyle = {
//       width: "100%",
//       position: "absolute", // Ensure the dropdown is positioned absolutely to the container
//       top: "100%", // Position right below the input box
//       left: 0, // Align with the input box
//       backgroundColor: "#ffffff",
//       boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
//       borderRadius: "0 0 4px 4px",
//       zIndex: 999999,
//     };

//     return (
//       <div
//         style={{
//           position: "relative",
//           width: this.props.width ? this.props.width : "100%",
//         }}
//         className="autoCompleteContainer"
//       >
//         <PlacesAutocomplete
//           value={this.state.address}
//           onChange={this.handleChange}
//           onSelect={this.handleSelect}
//         >
//           {({
//             getInputProps,
//             suggestions,
//             getSuggestionItemProps,
//             loading,
//           }) => (
//             <>
//               <input
//                 {...getInputProps({
//                   placeholder: "Please type your address search here ...",
//                   className: "location-search-input autoCompleteInput",
//                   style: {
//                     width: "100%",
//                     height: "55px",
//                     fontSize:
//                       this.props.fontSize === undefined
//                         ? 22
//                         : this.props.fontSize,
//                   },
//                 })}
//               />
//               {suggestions.length > 0 && (
//                 <div id="autoCompleteSuggestions" style={customStyle}>
//                   {loading && <div>Loading...</div>}
//                   {suggestions.map((suggestion) => {
//                     const className = suggestion.active
//                       ? "suggestion-item--active"
//                       : "suggestion-item";

//                     // inline style for demonstration purpose
//                     const style = {
//                       cursor: "pointer",
//                       width: "100%",
//                       padding: "10px",
//                       backgroundColor: suggestion.active
//                         ? "whitesmoke"
//                         : "#fafafa",
//                       color: "black",
//                     };

//                     return (
//                       <div
//                         {...getSuggestionItemProps(suggestion, {
//                           className,
//                           style,
//                         })}
//                       >
//                         <span>{suggestion.description}</span>
//                       </div>
//                     );
//                   })}
//                 </div>
//               )}
//             </>
//           )}
//         </PlacesAutocomplete>
//       </div>
//     );
//   }
// }

// const mapStateToProps = createSelector(
//   selectors.addressSelector,
//   selectors.businessTypeSelector,
//   selectors.zipSelector,
//   selectors.stateSelector,
//   (address, business_type, zip, state) => ({
//     address,
//     business_type,
//     zip,
//     state,
//   })
// );

// const mapActionsToProps = {
//   onUpdateAddress: updateAddress,
//   onUpdateBusinessType: updateBusinessType,
//   onUpdateZip: updateZip,
//   onUpdateState: updateState,
// };

// export default connect(mapStateToProps, mapActionsToProps)(AutoCompleteBar);

// import React from "react";
// import PlacesAutocomplete from "react-places-autocomplete";
// import { updateAddress } from "../Actions/address-actions";
// import { updateBusinessType } from "../Actions/business-actions";
// import { updateZip } from "../Actions/zip-actions";
// import { updateState } from "../Actions/state-actions";
// import { connect } from "react-redux";
// import { createSelector } from "reselect";
// import * as selectors from "../Reducers/selectors";

// class AutoCompleteBar extends React.Component {
//   constructor(props) {
//     super(props);
//     let address = "";

//     if (this.props.urlParams != undefined) {
//       address = JSON.parse(this.props.urlParams.address);
//     }
//     this.state = { address: address };
//     this.handleChange = this.handleChange.bind(this);
//     this.onUpdateAddress = this.onUpdateAddress.bind(this);
//   }

//   handleChange = (address) => {
//     this.setState({ address });
//   };

//   handleSelect = async (address) => {
//     this.handleChange(address);
//     this.props.addressFunction(address);
//   };

//   onUpdateAddress(address) {
//     this.props.onUpdateAddress(address);
//   }

//   render() {
//     const customStyle = {
//       width: "100%",
//       position: "absolute",
//       top: "100%",
//       left: 0,
//       backgroundColor: "#ffffff",
//       boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
//       borderRadius: "0 0 8px 8px",
//       overflow: "hidden",
//       zIndex: 999999,
//     };

//     const inputStyle = {
//       width: "100%",
//       height: "55px",
//       padding: "10px 20px",
//       fontSize:
//         this.props.fontSize === undefined ? "16px" : `${this.props.fontSize}px`,
//       border: "1px solid #cccccc",
//       borderRadius: "8px",
//       boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
//     };

//     return (
//       <div
//         style={{
//           position: "relative",
//           width: this.props.width ? this.props.width : "100%",
//         }}
//         className="autoCompleteContainer"
//       >
//         <PlacesAutocomplete
//           value={this.state.address}
//           onChange={this.handleChange}
//           onSelect={this.handleSelect}
//         >
//           {({
//             getInputProps,
//             suggestions,
//             getSuggestionItemProps,
//             loading,
//           }) => (
//             <>
//               <input
//                 {...getInputProps({
//                   placeholder: "Type your address...",
//                   className: "location-search-input autoCompleteInput",
//                   style: inputStyle,
//                 })}
//               />
//               {suggestions.length > 0 && (
//                 <div id="autoCompleteSuggestions" style={customStyle}>
//                   {loading && <div style={{ padding: "10px" }}>Loading...</div>}
//                   {suggestions.map((suggestion) => {
//                     const style = {
//                       cursor: "pointer",
//                       padding: "10px 20px",
//                       backgroundColor: suggestion.active
//                         ? "#f0f0f0"
//                         : "#ffffff",
//                       color: "#333333",
//                     };

//                     return (
//                       <div
//                         {...getSuggestionItemProps(suggestion, {
//                           style,
//                         })}
//                       >
//                         <span>{suggestion.description}</span>
//                       </div>
//                     );
//                   })}
//                 </div>
//               )}
//             </>
//           )}
//         </PlacesAutocomplete>
//       </div>
//     );
//   }
// }

// const mapStateToProps = createSelector(
//   selectors.addressSelector,
//   selectors.businessTypeSelector,
//   selectors.zipSelector,
//   selectors.stateSelector,
//   (address, business_type, zip, state) => ({
//     address,
//     business_type,
//     zip,
//     state,
//   })
// );

// const mapActionsToProps = {
//   onUpdateAddress: updateAddress,
//   onUpdateBusinessType: updateBusinessType,
//   onUpdateZip: updateZip,
//   onUpdateState: updateState,
// };

// export default connect(mapStateToProps, mapActionsToProps)(AutoCompleteBar);

import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { updateAddress } from "../Actions/address-actions";
import { updateBusinessType } from "../Actions/business-actions";
import { updateZip } from "../Actions/zip-actions";
import { updateState } from "../Actions/state-actions";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as selectors from "../Reducers/selectors";
import { FaSearch } from "react-icons/fa"; // Ensure you have `react-icons` library installed

class AutoCompleteBar extends React.Component {
  constructor(props) {
    super(props);
    let address = "";

    if (this.props.urlParams != undefined) {
      address = JSON.parse(this.props.urlParams.address);
    }
    this.state = { address: address };
    this.handleChange = this.handleChange.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = async (address) => {
    this.handleChange(address);
    this.props.addressFunction(address);
  };

  onUpdateAddress(address) {
    this.props.onUpdateAddress(address);
  }

  render() {
    const customStyle = {
      width: "100%", // Adjust width to prevent overlap with search icon
      position: "absolute",
      top: "calc(100% + 8px)", // Adjust top position to add gap between search bar and dropdown
      left: 0,
      backgroundColor: "#ffffff",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      borderRadius: "8px",
      overflow: "hidden",
      zIndex: 999999,
    };

    const inputStyle = {
      width: "100%",
      height: "55px",
      padding: "10px 20px",
      paddingRight: "40px", // Make space for the search icon inside the input
      fontSize:
        this.props.fontSize === undefined ? "16px" : `${this.props.fontSize}px`,
      border: "1px solid #cccccc",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      display: "flex",
      alignItems: "center",
    };

    return (
      <div
        style={{
          position: "relative",
          width: this.props.width ? this.props.width : "100%",
        }}
        className="autoCompleteContainer"
      >
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <>
              <div style={{ position: "relative" }}>
                <input
                  {...getInputProps({
                    placeholder: "Type your address...",
                    className: "location-search-input autoCompleteInput",
                    style: inputStyle,
                  })}
                />
                <FaSearch
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ccc",
                    pointerEvents: "none", // Prevents the icon from blocking input interactions
                  }}
                />
              </div>
              {suggestions.length > 0 && (
                <div id="autoCompleteSuggestions" style={customStyle}>
                  {loading && <div style={{ padding: "10px" }}>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      cursor: "pointer",
                      padding: "10px 20px",
                      backgroundColor: suggestion.active
                        ? "#f0f0f0"
                        : "#ffffff",
                      color: "#333333",
                    };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectors.addressSelector,
  selectors.businessTypeSelector,
  selectors.zipSelector,
  selectors.stateSelector,
  (address, business_type, zip, state) => ({
    address,
    business_type,
    zip,
    state,
  })
);

const mapActionsToProps = {
  onUpdateAddress: updateAddress,
  onUpdateBusinessType: updateBusinessType,
  onUpdateZip: updateZip,
  onUpdateState: updateState,
};

export default connect(mapStateToProps, mapActionsToProps)(AutoCompleteBar);
