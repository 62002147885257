const zipMap = new Map();

export const buildMap = () => {
    Object.entries(zips).forEach(([ location, zipArray ]) => {
        zipArray.forEach(zip => zipMap.set(String(zip), location))
    });

    return zipMap;
};

export const Boroughs = {
    Brooklyn: 'Brooklyn', Bronx: 'Bronx', Queens: 'Queens', Staten: 'Staten', Manhattan: 'Manhattan', Nassau: 'Nassau'
};


const Nassau = [

    11550,
    11758,
    11590,
    11003,
    11520,
    11756,
    11580,
    11040,
    11801,
    11554,
    11710,
    11735,
    11566,
    11510,
    11793,
    11530,
    11572,
    11561,
    11803,
    11001,
    11050,
    11570,
    11553,
    11542,
    11791,
    11010,
    11552,
    11762,
    11714,
    11563,
    11581,
    11783,
    11501,
    11021,
    11030,
    11575,
    11702,
    11753,
    11576,
    11545,
    11598,
    11577,
    11596,
    11518,
    11771,
    11023,
    11797,
    11557,
    11559,
    11096,
    11565,
    11516,
    11507,
    11558,
    11560,
    11024,
    11514,
    11568,
    11020,
    11579,
    11709,
    11804,
    11732,
    11548,
    11042,
    11547,
    11569,
    11509,
    11765,
    11556,
    11549,
    11815,
    11022,
    11535,
    11594,
    11597,
    11736,
    11774,
    11819,
    11854,
    11855,
    11571,
    11582,
    11592,
    11595,
    11599,
    11737,
    11773,
    11802,
    11853,
    11002,
    11025,
    11026,
    11027,
    11041,
    11043,
    11044,
    11051,
    11052,
    11053,
    11054,
    11055,
    11099,
    11531,
    11536,
    11551,
    11555
    ];
const Brooklyn = [

    11201,
    11203,
    11204,
    11205,
    11206,
    11207,
    11208,
    11209,
    11210,
    11211,
    11212,
    11213,
    11214,
    11215,
    11216,
    11217,
    11218,
    11219,
    11220,
    11221,
    11222,
    11223,
    11224,
    11225,
    11226,
    11228,
    11229,
    11230,
    11231,
    11232,
    11233,
    11234,
    11235,
    11236,
    11237,
    11238,
    11239,
    11241,
    11242,
    11243,
    11249,
    11252,
    11256,
];

const Bronx = [
  
    10451,
    10452,
    10453,
    10454,
    10455,
    10456,
    10457,
    10458,
    10459,
    10460,
    10461,
    10462,
    10463,
    10464,
    10465,
    10466,
    10467,
    10468,
    10469,
    10470,
    10471,
    10472,
    10473,
    10474,
    10475,
    10451,
    10452,
    10453,
    10454,
    10455,
    10456,
    10457,
    10458,
    10459,
    10460,
    10461,
    10462,
    10463,
    10464,
    10465,
    10466,
    10467,
    10468,
    10469,
    10470,
    10471,
    10472,
    10473,
    10474,
    10475
];

const Queens = [
    11004,
    11101,
    11102,
    11103,
    11104,
    11105,
    11106,
    11109,
    11351,
    11354,
    11355,
    11356,
    11357,
    11358,
    11359,
    11360,
    11361,
    11362,
    11363,
    11364,
    11365,
    11366,
    11367,
    11368,
    11369,
    11370,
    11371,
    11372,
    11373,
    11374,
    11375,
    11377,
    11378,
    11379,
    11385,
    11411,
    11412,
    11413,
    11414,
    11415,
    11416,
    11417,
    11418,
    11419,
    11420,
    11421,
    11422,
    11423,
    11426,
    11427,
    11428,
    11429,
    11430,
    11432,
    11433,
    11434,
    11435,
    11436,
    11691,
    11692,
    11693,
    11694,
    11697,
    11366,
    11367,
    11368,
    11369,
    11370,
    11371,
    11372,
    11373,
    11374,
    11375,
    11377,
    11378,
    11379,
    11385,
    11411,
    11412,
    11413,
    11414,
    11415,
    11416,
    11417,
    11418,
    11419,
    11420,
    11421,
    11422,
    11423,
    11426,
    11427,
    11428,
    11429,
    11430,
    11432,
    11433,
    11434,
    11435,
    11436,
    11691,
    11692,
    11693,
    11694,
    11697,
];



const Staten = [
    10301,
    10302,
    10303,
    10304,
    10305,
    10306,
    10307,
    10308,
    10309,
    10310,
    10311,
    10312,
    10314,
    10301,
    10302,
    10303,
    10304,
    10305,
    10306,
    10307,
    10308,
    10309,
    10310,
    10311,
    10312,
    10314,
];

const Manhattan = [
   
    10001,
    10002,
    10003,
    10004,
    10005,
    10006,
    10007,
    10008,
    10009,
    10010,
    10011,
    10012,
    10013,
    10014,
    10015,
    10016,
    10017,
    10018,
    10019,
    10020,
    10021,
    10022,
    10023,
    10024,
    10025,
    10026,
    10027,
    10028,
    10029,
    10030,
    10031,
    10032,
    10033,
    10034,
    10035,
    10036,
    10037,
    10038,
    10039,
    10040,
    10041,
    10042,
    10043,
    10044,
    10045,
    10046,
    10047,
    10048,
    10049,
    10050,
    10051,
    10052,
    10053,
    10054,
    10055,
    10056,
    10057,
    10058,
    10059,
    10060,
    10061,
    10062,
    10063,
    10064,
    10065,
    10066,
    10067,
    10068,
    10069,
    10070,
    10071,
    10072,
    10073,
    10074,
    10075,
    10076,
    10077,
    10078,
    10079,
    10080,
    10081,
    10082,
    10083,
    10084,
    10085,
    10086,
    10087,
    10088,
    10089,
    10090,
    10091,
    10092,
    10093,
    10094,
    10095,
    10096,
    10097,
    10098,
    10099,
    10100,
    10101,
    10102,
    10103,
    10104,
    10105,
    10106,
    10107,
    10108,
    10109,
    10110,
    10111,
    10112,
    10113,
    10114,
    10115,
    10116,
    10117,
    10118,
    10119,
    10120,
    10121,
    10122,
    10123,
    10124,
    10125,
    10126,
    10127,
    10128,
    10129,
    10130,
    10131,
    10132,
    10133,
    10134,
    10135,
    10136,
    10137,
    10138,
    10139,
    10140,
    10141,
    10142,
    10143,
    10144,
    10145,
    10146,
    10147,
    10148,
    10149,
    10150,
    10151,
    10152,
    10153,
    10154,
    10155,
    10156,
    10157,
    10158,
    10159,
    10160,
    10161,
    10162,
    10163,
    10164,
    10165,
    10166,
    10167,
    10168,
    10169,
    10170,
    10171,
    10172,
    10173,
    10174,
    10175,
    10176,
    10177,
    10178,
    10179,
    10180,
    10181,
    10182,
    10183,
    10184,
    10185,
    10186,
    10187,
    10188,
    10189,
    10190,
    10191,
    10192,
    10193,
    10194,
    10195,
    10196,
    10197,
    10198,
    10199,
    10200,
    10201,
    10202,
    10203,
    10204,
    10205,
    10206,
    10207,
    10208,
    10209,
    10210,
    10211,
    10212,
    10213,
    10214,
    10215,
    10216,
    10217,
    10218,
    10219,
    10220,
    10221,
    10222,
    10223,
    10224,
    10225,
    10226,
    10227,
    10228,
    10229,
    10230,
    10231,
    10232,
    10233,
    10234,
    10235,
    10236,
    10237,
    10238,
    10239,
    10240,
    10241,
    10242,
    10243,
    10244,
    10245,
    10246,
    10247,
    10248,
    10249,
    10250,
    10251,
    10252,
    10253,
    10254,
    10255,
    10256,
    10257,
    10258,
    10259,
    10260,
    10261,
    10262,
    10263,
    10264,
    10265,
    10266,
    10267,
    10268,
    10269,
    10270,
    10271,
    10272,
    10273,
    10274,
    10275,
    10276,
    10277,
    10278,
    10279,
    10280,
    10281
]

export const zips = {
    Brooklyn, Bronx, Queens, Staten, Manhattan, Nassau
};