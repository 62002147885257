import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Custom theme settings
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    background: {
      default: "#f4f5f7",
    },
  },
  typography: {
    h2: {
      fontWeight: 500,
      fontSize: "2.2rem",
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
    header: {
      fontWeight: 600,
      fontSize: "2.5rem",
      margin: "20px 0",
      color: "#333",
    },
    cardHeader: {
      fontSize: "1.7rem", // Increased font size
      fontWeight: 400,
      marginBottom: "10px",
      color: "#4682b4", // New color
      display: "flex",
      justifyContent: "center",
      // textAlign: "center",
      // alignItems: "center",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
  },
});

function ResidentialDropdownPage() {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginBottom: "30px" }}>
        <Box py={3} textAlign="center">
          <Typography variant="header" gutterBottom>
            Residential Review
          </Typography>
        </Box>
        <Box py={2} textAlign="center">
          <Typography variant="subtitle" gutterBottom>
            When it comes to selecting a home for comfort and investment the old
            adage is true:
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            "The three most important factors are location, location, location."
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Feature Cards */}
          {/* Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/residentialretone")}
                >
                  RETONE
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="aerial.jpg"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/residentialretone")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Your Home, Your Community
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Get to know your new neighborhood with a click of a mouse, or
                  a touch of a screen.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/residentialretone")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Discover
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Card 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/propertyspecs")}
                >
                  Property Specifications
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  objectFit="contain"
                  image="hamptons.webp"
                  alt="Data Analysis"
                  onClick={() => navigateTo("/propertyspecs")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Your Perfect Space
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your residence can provide every comfort and safety you
                  desire.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/propertyspecs")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Consider
                </Button>
                {/* <Chip label="New" color="primary" /> */}
              </CardActions>
            </Card>
          </Grid>
          {/* Card 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/otherfactors")}
                >
                  Other Considerations
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="dakota.webp"
                  alt="Nearby Business"
                  onClick={() => navigateTo("/otherfactors")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Enviromental Factors
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  External considerations can make your house or apartment feel
                  like the perfect home.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/otherfactors")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Explore
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default ResidentialDropdownPage;
