import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // for navigation
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function Page2({ goToNextPage }) {
  const history = useHistory(); // for navigation
  const [formData, setFormData] = useState({
    address: "",
    squareFootage: "",
    askingPrice: "",
    dateListed: "",
    daysOnMarket: "",
    taxHistory: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div style={{ margin: "20px" }}>
      <TextField
        label="Address of property"
        variant="outlined"
        name="address"
        fullWidth
        margin="normal"
        value={formData.address}
        onChange={handleChange}
      />
      <TextField
        label="Total square footage"
        variant="outlined"
        name="squareFootage"
        fullWidth
        margin="normal"
        value={formData.squareFootage}
        onChange={handleChange}
      />
      <TextField
        label="Asking price"
        variant="outlined"
        name="askingPrice"
        fullWidth
        margin="normal"
        value={formData.askingPrice}
        onChange={handleChange}
      />
      <TextField
        label="Date listed"
        variant="outlined"
        name="dateListed"
        fullWidth
        margin="normal"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={formData.dateListed}
        onChange={handleChange}
      />
      <TextField
        label="Days on the market"
        variant="outlined"
        name="daysOnMarket"
        fullWidth
        margin="normal"
        value={formData.daysOnMarket}
        onChange={handleChange}
      />
      <TextField
        label="Tax history"
        variant="outlined"
        name="taxHistory"
        fullWidth
        margin="normal"
        value={formData.taxHistory}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        onClick={() => {
          localStorage.setItem("page2Data", JSON.stringify(formData));
          goToNextPage(); // Using goToNextPage from props
        }}
        style={{ marginTop: "20px" }}
      >
        Go to Page 3
      </Button>
    </div>
  );
}

export default Page2;
