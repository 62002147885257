// ForgotPassword.js
import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Box, Alert } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");
    try {
      const response = await axios.post(
        `http://localhost:8081/auth/forgot-password`,
        { email }
      );
      setMessage(response.data.message);
    } catch (error) {
      setError(error.response?.data.message || "An unexpected error occurred");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="Email Address"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Send Reset Link
      </Button>
    </Box>
  );
};

export default ForgotPassword;
