import React from "react";
import {
  Container,
  Typography,
  ListItem,
  ListItemText,
  Box,
  Paper,
  styled,
} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 5px 25px rgba(0,0,0,0.12)",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ListContainer = styled(Box)(({ theme }) => ({
  maxWidth: "60%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "45%",
  height: "100%",
  boxShadow: "0px 5px 25px rgba(0,0,0,0.12)",

  [theme.breakpoints.down("md")]: {
    maxWidth: "80%",
    marginTop: theme.spacing(4),
  },
}));

const OtherFactors = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box py={3} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mb: 2, fontWeight: "bold", color: "#1565c0" }}
        >
          Other Considerations
        </Typography>
      </Box>
      <StyledPaper>
        <ListContainer>
          <ListItem>
            <ListItemText primary="How much space do you need?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="What can you afford to pay for rent or a monthly mortgage and down payment?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Are you more comfortable in a quiet town or an action-packed city?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="How close to family and friends do you want to be?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Would you like to live by the water and/or green spaces?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="What kind of view would you like?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="What near-by activities, sports, and cultural events are avaiable?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Do you have a pet and need to be near a veterinarian, dog park, groomer, and pet sitting facility?" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Is it important to you to live in an area where most people share your political ideologies?" />
          </ListItem>
        </ListContainer>
        <Image src="/other1.jpeg" alt="Consideration Visual" />
      </StyledPaper>
    </Container>
  );
};

export default OtherFactors;
