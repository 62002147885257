import React from "react";
import {
  Container,
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const SectionContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ItemContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start", // Adjusted for closer alignment
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 5px 25px rgba(0,0,0,0.12)",
  gap: theme.spacing(2), // Reduced gap for closer proximity
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  maxWidth: "65%",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  fontSize: "1.5rem",
  marginBottom: theme.spacing(2),
}));

const DetailText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Image = styled("img")({
  width: "55%", // Adjusted width for consistent sizing
  height: "auto",
  boxShadow: "0px 5px 25px rgba(0,0,0,0.12)",
  borderRadius: "4px", // Optional: Adds rounded corners for a softer look
});

const PropertySpecsPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333", mb: 6, textAlign: "center" }}
      >
        Property Information and Considerations When Choosing Your Home
      </Typography>

      {/* Property Information Section */}
      <SectionContainer>
        <ItemContainer>
          <TextContainer>
            <Title>Property Information</Title>
            <Divider sx={{ my: 4, width: "100%" }} />
            <DetailText>Address of Property</DetailText>
            <DetailText>Asking Price for Sale or Rental</DetailText>
            <DetailText>Type of Residential Property</DetailText>
            <DetailText>Year Built/Age of Property</DetailText>
            <DetailText>New Construction</DetailText>
            <DetailText>Tax History</DetailText>
            <DetailText>Common Area Charges/Maintenance Fees</DetailText>
            <DetailText>Days on the Market</DetailText>
            <DetailText>Date Available for Procession/Move In</DetailText>
          </TextContainer>
          <Image src="./property1.jpg" alt="Property Information" />
        </ItemContainer>
      </SectionContainer>

      {/* Property Specifications Section */}
      <SectionContainer>
        <ItemContainer>
          <TextContainer>
            <Title>Property Specifications</Title>
            <Divider sx={{ my: 4, width: "100%" }} />
            <DetailText>Total Square Footage</DetailText>
            <DetailText>Date of Renovation</DetailText>
            <DetailText>Specifications of Interior Space</DetailText>
            <DetailText>Kitchen Features</DetailText>
            <DetailText>Basement Details</DetailText>
            <DetailText>Garage</DetailText>
            <DetailText>Exterior and Additional Features</DetailText>
            <DetailText>Pool</DetailText>
            <DetailText>Fenced in Area</DetailText>
            <DetailText>Deck, Patio, or Porch</DetailText>
            <DetailText>Seller or Broker Contact Information</DetailText>
          </TextContainer>
          <Image src="./property2.jpg" alt="Property Specifications" />
        </ItemContainer>
      </SectionContainer>
    </Container>
  );
};

export default PropertySpecsPage;
