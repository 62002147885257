import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";
import { Typography } from "@mui/material";

// Fix icon issue with leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function MapComponent({ address }) {
  const [latLng, setLatLng] = useState(null); // Initially no coordinates

  useEffect(() => {
    if (address) {
      // Use Nominatim Geocoding API to convert address to latLng
      const fetchGeocode = async () => {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              address
            )}`
          );
          if (response.data && response.data.length > 0) {
            setLatLng([response.data[0].lat, response.data[0].lon]);
          } else {
            console.error("No results found for address:", address);
            // Here, you may handle what happens if no geocoding result is found, such as setting a default.
          }
        } catch (error) {
          console.error("Error fetching geocoding data:", error);
          // Handle the error, maybe setLatLng to a default or show an error
        }
      };

      fetchGeocode();
    }
  }, [address]);

  if (!latLng) {
    // Handle the case when latLng is not available yet
    return <Typography>Loading map...</Typography>;
  }

  return (
    <MapContainer
      center={latLng}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: "400px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={latLng}>
        <Popup>
          {address || "Address not available"}{" "}
          {/* Show the address or other information here */}
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default MapComponent;
