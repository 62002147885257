import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import Button from "react-bootstrap/Button";
import { updateActivePlace } from "../../../Actions/active-actions";
import "../../../css/placesList.scss";

class PlacesList extends React.Component {
  state = {
    showModal: false,
    currentPage: 1,
    itemsPerPage: 8, // Default for larger screens
    isMobile: window.innerWidth < 768,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 768 });
    if (window.innerWidth < 768) {
      this.setState({ itemsPerPage: 4 }); // For mobile, show only 4 items initially
    } else {
      this.setState({ itemsPerPage: 8 }); // For larger screens, show 8 items
    }
  };

  onUpdateActivePlace = (data) => {
    this.props.onUpdateActivePlace(data);
    console.log("onUpdateActivePlace: ", data);
  };

  loadMore = () => {
    this.setState((prevState) => ({
      itemsPerPage: prevState.itemsPerPage + 8, // Changed from 10 to 8
    }));
  };

  renderPlaces() {
    const { currentPage, itemsPerPage } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage; // Adjust this to control the number of items displayed
    const placesToShow = this.props.places.slice(startIndex, endIndex);

    if (placesToShow.length === 0) {
      return <p>There are no places to show.</p>;
    }

    return placesToShow.map((place, i) => {
      const photoUrl =
        place.photos && place.photos.length > 0
          ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${place.photos[0].photo_reference}&key=AIzaSyDCkeKGG8hYP59S-ocZ-aTOfotl0a-jfjQ`
          : "defaultImageURL";

      return (
        <div
          className="place-item"
          key={i}
          onClick={() => this.onUpdateActivePlace(place)}
        >
          <img src={photoUrl} alt={place.name} className="place-image" />
          <div className="place-details">
            <p>{place.name}</p>
            <p>Distance: {(place.distance / 1.609).toPrecision(2)} mi</p>
            <p>Rating: {place.rating ? place.rating : "Not rated"} / 5</p>
            <p>
              Total Reviews:{" "}
              {place.user_ratings_total ? place.user_ratings_total : "No data"}
            </p>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="places-list">
        <header className="places-list-header">
          <h2 className="header-title">Competitors and/or Generators</h2>
        </header>
        <div className="places-container">{this.renderPlaces()}</div>

        <Button onClick={this.loadMore} className="load-more-btn">
          Load More
        </Button>
      </div>
    );
  }
}

const MarkerLegend = () => (
  <div className="marker-legend">
    <img src={BLUE_MARKER} alt="Blue Marker" />
    <p>Site</p>
    <img src={RED_MARKER} alt="Red Marker" />
    <p>Businesses / Points of Interest</p>
  </div>
);

const activePlaceSelector = createSelector(
  (state) => state.active_place,
  (active_place) => active_place
);

const placesSelector = createSelector(
  (state) => state.places,
  (places) => places
);

const addressSelector = createSelector(
  (state) => state.address,
  (address) => address
);

const mapStateToProps = createSelector(
  activePlaceSelector,
  placesSelector,
  addressSelector,
  (active_place, places, address) => ({
    active_place,
    places,
    address,
  })
);

const mapActionsToProps = {
  onUpdateActivePlace: updateActivePlace,
};

export default connect(mapStateToProps, mapActionsToProps)(PlacesList);
