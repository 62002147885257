import React, { createContext, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, logoutUser } from "../../Actions/user-actions";
import axios from "axios"; // Ensure axios is imported

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user); // Ensure this selector correctly points to user state

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      validateToken(token)
        .then((userData) => {
          if (userData) login(userData, token);
          else console.log("Invalid or expired token");
        })
        .catch((error) => {
          console.error("Token validation error", error);
        });
    }
  }, []); // If your linter recommends including dispatch, you can safely do so
  const login = (userData, token) => {
    localStorage.setItem("token", token); // Save token for session persistence
    // Update user state in Redux
    dispatch(loginUser({ ...userData, isAuthenticated: true }, token));
  };

  const logout = () => {
    localStorage.removeItem("token"); // Clean up token
    dispatch(logoutUser()); // Update user state in Redux to reflect logout
  };

  const validateToken = async (token) => {
    try {
      const response = await axios.post(
        `http://localhost:8081/validate-token`,
        {
          token,
        }
      );
      return response.data.user; // Ensure backend sends user data
    } catch (error) {
      console.error("Token validation error", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
