import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as selectors from "../../Reducers/selectors";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { updateUser } from "../../Actions/user-actions";
import { S3_BASE } from "../../Constants";

class ProfilePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first: this.props.user.first,
      last: this.props.user.last,
      username: this.props.user.username,
      email: this.props.user.email,
      editMode: false,
    };
  }

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleEdit = () => {
    this.setState({ editMode: true });
  };

  handleCancel = () => {
    this.setState({
      editMode: false,
      first: this.props.user.first,
      last: this.props.user.last,
      username: this.props.user.username,
      email: this.props.user.email,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Update user profile logic here
    // As the editUser function is not available, you'll need to implement it according to your backend

    this.setState({ editMode: false });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          minWidth: "320px",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            marginTop: "2em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={S3_BASE + "users/" + this.props.user._id + "/profile.jpg"}
            width={100}
            height={100}
            style={{ borderRadius: 45 }}
          />
        </div>
        <Form
          style={{ width: "80%", margin: "auto", marginTop: "2em" }}
          onSubmit={this.handleSubmit}
        >
          <Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.first}
                  onChange={(e) => this.handleChange(e, "first")}
                  readOnly={!this.state.editMode}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.last}
                  onChange={(e) => this.handleChange(e, "last")}
                  readOnly={!this.state.editMode}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={this.state.username}
              onChange={(e) => this.handleChange(e, "username")}
              readOnly={!this.state.editMode}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={this.state.email}
              onChange={(e) => this.handleChange(e, "email")}
              readOnly={!this.state.editMode}
            />
          </Form.Group>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            {!this.state.editMode && (
              <Button variant="primary" onClick={this.handleEdit}>
                Edit
              </Button>
            )}
            {this.state.editMode && (
              <Button variant="danger" onClick={this.handleCancel}>
                Cancel
              </Button>
            )}
            {this.state.editMode && (
              <Button
                variant="primary"
                type="submit"
                style={{ marginLeft: "2em" }}
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createSelector(selectors.userSelector, (user) => ({
  user,
}));

const mapActionsToProps = {
  onUpdateUser: updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(ProfilePanel);
