import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function Page3({ goToNextPage }) {
  const [formData, setFormData] = useState({
    totalSquareFootage: "",
    totalNumberOfRooms: "",
    numberOfBedrooms: "",
    numberOfBathrooms: "",
    familyRoom: "", // Assuming this is a text field; adjust as necessary if it's a different type of input
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div style={{ margin: "20px" }}>
      <TextField
        label="Total square footage"
        variant="outlined"
        name="totalSquareFootage"
        fullWidth
        margin="normal"
        value={formData.totalSquareFootage}
        onChange={handleChange}
      />
      <TextField
        label="Total number of rooms"
        variant="outlined"
        name="totalNumberOfRooms"
        fullWidth
        margin="normal"
        value={formData.totalNumberOfRooms}
        onChange={handleChange}
      />
      <TextField
        label="Number of bedrooms"
        variant="outlined"
        name="numberOfBedrooms"
        fullWidth
        margin="normal"
        value={formData.numberOfBedrooms}
        onChange={handleChange}
      />
      <TextField
        label="Number of bathrooms"
        variant="outlined"
        name="numberOfBathrooms"
        fullWidth
        margin="normal"
        value={formData.numberOfBathrooms}
        onChange={handleChange}
      />
      <TextField
        label="Family room"
        variant="outlined"
        name="familyRoom"
        fullWidth
        margin="normal"
        value={formData.familyRoom}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        onClick={() => {
          localStorage.setItem("page3Data", JSON.stringify(formData));
          goToNextPage(); // Handle what happens next when this button is clicked
        }}
        style={{ marginTop: "20px" }}
      >
        Go to Page 4{/* Update this text as needed */}
      </Button>
    </div>
  );
}

export default Page3;
