import React, { useState } from "react";
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import SelectResidentialLocation from "./SelectResidentialLocation";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Review from "./Review";

function Home() {
  const theme = useTheme(); // using the theme for consistent styling
  const [currentPage, setCurrentPage] = useState("page1");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [imageFiles, setImageFiles] = useState([]); // Lifted state for image files

  const handleAddressSelect = (address) => {
    setSelectedAddress(address); // Save the address
    setCurrentPage("page2"); // Automatically go to the 2nd page
  };

  const goToNextPage = () => {
    const currentPageNumber = parseInt(currentPage.replace("page", ""));
    const nextPageNumber = currentPageNumber + 1;
    setCurrentPage(`page${nextPageNumber}`);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentPage(newValue);
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "20px" }}>
      <img
        src="/home1.jpeg"
        alt="Top Image"
        style={{ width: "100%", height: "auto", borderRadius: "5px" }}
      />

      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          value={currentPage}
          onChange={handleTabChange}
          aria-label="navigation tabs"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Page 1" value="page1" />
          <Tab label="Page 2" value="page2" />
          <Tab label="Page 3" value="page3" />
          <Tab label="Page 4" value="page4" />
          <Tab label="Review" value="page5" />
        </Tabs>
      </AppBar>

      <Box sx={{ padding: 3 }}>
        {currentPage === "page1" && (
          <SelectResidentialLocation
            onAddressSelect={handleAddressSelect}
            goToNextPage={goToNextPage}
          />
        )}
        {currentPage === "page2" && <Page2 goToNextPage={goToNextPage} />}
        {currentPage === "page3" && <Page3 goToNextPage={goToNextPage} />}
        {currentPage === "page4" && (
          <Page4 goToNextPage={goToNextPage} setImageFiles={setImageFiles} />
        )}
        {currentPage === "page5" && (
          <Review selectedAddress={selectedAddress} imageFiles={imageFiles} />
        )}
      </Box>
    </Container>
  );
}

export default Home;
