// ResetPassword.js
import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Box, Alert } from "@mui/material";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { token } = useParams(); // Assuming you're using react-router-dom

  const handleSubmit = async (event) => {
    const { token } = useParams();
    event.preventDefault();
    setMessage("");
    setError("");
    try {
      const response = await axios.post(
        `http://localhost:8081/auth/reset-password/${token}`,
        { password }
      );
      setMessage(response.data.message);
      // Additional logic after successful password reset, like redirection
    } catch (error) {
      setError(error.response?.data.message || "An unexpected error occurred");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="New Password"
        type="password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Reset Password
      </Button>
    </Box>
  );
};

export default ResetPassword;
