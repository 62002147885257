import React from "react";
import NavigationBar from "../Navigation/NavigationBar";
import { Container, Typography, Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    backgroundColor: "#001A70",
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  headerTypography: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  contentBox: {
    margin: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  typography: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  serviceOverviewBox: {
    marginBottom: theme.spacing(4),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  serviceOverviewTypography: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const AboutUsPage = () => {
  const classes = useStyles();

  return (
    <>
      <NavigationBar />

      <Box className={classes.headerBox}>
        <CardMedia
          component="img"
          height="300px"
          image="blue_logo.png"
          alt="Interactive Map"
          style={{ objectFit: "contain" }} // This ensures the image fits without being cut off
        />
        <Typography variant="h3" className={classes.headerTypography}>
          About Mapmax
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Box className={classes.contentBox}>
          {/* Each Typography component represents a paragraph */}
          <Typography sx={{ mb: 4 }}>
            Mapmax is a comprehensive real estate platform helping businesses
            and individuals seeking to buy, sell, or rent commercial and
            residential properties. The website stands out with its
            user-friendly interface and numerous features that enhance the
            property hunting experience by offering analysis of the geography
            surrounding any inputted location address in New York City, Long
            Island, and New Jersey. Users can easily browse through the
            navigation bar and explore the map page to receive insights into
            each property's location details, especially from the proprietary
            algorithm called “RETONE”.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            RETONE can showcase a community’s nearby retail, recreational, and
            professional sites. These offer residential neighborhood services
            and provide business owners an understanding of surrounding
            establishments that may generate customers for them or compete for
            customers' revenue from them. With this knowledge, business owners
            can more accurately estimate their potential sales and decide if the
            location is right for them.
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Mapmax is a valuable tool for everyone in the real estate market and
            anyone wishing to better understand the community surrounding a
            prospective real estate acquisition. Moreover, the navigation bar
            and map page offer a friendly, informative, and seamless user
            experience.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default AboutUsPage;

// import React from "react";
// import NavigationBar from "../Navigation/NavigationBar";
// import { Container, Typography, Box, CardMedia, Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   headerBox: {
//     backgroundColor: "#001A70",
//     color: theme.palette.primary.contrastText,
//     padding: theme.spacing(4),
//     textAlign: "center",
//     display: "flex", // Added for flexbox layout
//     justifyContent: "center", // Center the items horizontally
//     alignItems: "center", // Center the items vertically
//     [theme.breakpoints.down("sm")]: {
//       flexDirection: "column", // Stack items vertically on small screens
//       padding: theme.spacing(2),
//     },
//   },
//   headerTypography: {
//     fontWeight: 600,
//     marginLeft: theme.spacing(2), // Add some space between the logo and the text
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "1.5rem",
//       marginLeft: 0, // Remove the margin on small screens
//     },
//   },
//   contentBox: {
//     margin: theme.spacing(4),
//     [theme.breakpoints.down("sm")]: {
//       margin: theme.spacing(2),
//     },
//   },
//   typography: {
//     marginBottom: theme.spacing(4),
//     [theme.breakpoints.down("sm")]: {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   serviceOverviewBox: {
//     marginBottom: theme.spacing(4),
//     display: "flex",
//     alignContent: "center",
//     alignItems: "center",
//     justifyContent: "center",
//     [theme.breakpoints.down("sm")]: {
//       flexDirection: "column",
//       textAlign: "center",
//     },
//   },
//   serviceOverviewTypography: {
//     fontWeight: 500,
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "1.25rem",
//     },
//   },
// }));

// const AboutUsPage = () => {
//   const classes = useStyles();

//   return (
//     <>
//       <NavigationBar />

//       <Box className={classes.headerBox}>
//         <CardMedia
//           component="img"
//           height="80%" // Adjust the height to be responsive within the flex container
//           image="blue_logo.png"
//           alt="Interactive Map"
//           style={{ maxWidth: "200px", objectFit: "contain" }} // Adjust size and fit of the logo
//         />
//         <Typography variant="h3" className={classes.headerTypography}>
//           About Mapmax
//         </Typography>
//       </Box>

//       <Container maxWidth="lg">
//         <Box className={classes.contentBox}>
//           {/* Each Typography component represents a paragraph */}
//           <Typography sx={{ mb: 4 }}>
//             Mapmax is a comprehensive real estate platform helping businesses
//             and individuals seeking to buy, sell, or rent commercial and
//             residential properties. The website stands out with its
//             user-friendly interface and numerous features that enhance the
//             property hunting experience by offering analysis of the geography
//             surrounding any inputted location address in New York City, Long
//             Island, and New Jersey. Users can easily browse through the
//             navigation bar and explore the map page to receive insights into
//             each property's location details, especially from the proprietary
//             algorithm called “RETONE”.
//           </Typography>

//           <Typography sx={{ mb: 4 }}>
//             RETONE can showcase a community’s nearby retail, recreational, and
//             professional sites. These offer residential neighborhood services
//             and provide business owners an understanding of surrounding
//             establishments that may generate customers for them or compete for
//             customers' revenue from them. With this knowledge, business owners
//             can more accurately estimate their potential sales and decide if the
//             location is right for them.
//           </Typography>
//           <Typography sx={{ mb: 4 }}>
//             Mapmax is a valuable tool for everyone in the real estate market and
//             anyone wishing to better understand the community surrounding a
//             prospective real estate acquisition. Moreover, the navigation bar
//             and map page offer a friendly, informative, and seamless user
//             experience.
//           </Typography>
//         </Box>
//       </Container>
//     </>
//   );
// };

// export default AboutUsPage;
