import React from "react";
import { Grid, Typography, Paper, Button } from "@mui/material"; // Import Button from @mui/material
import { makeStyles } from "@mui/styles";
import Image1 from "../../images/home1.jpeg";

const data = [
  {
    text: [
      "Business Owners- Choose Your Ultimate Location",
      "Discover “RETONE” and optimize your business potential",
      "Review operations that either generate or compete for revenue",
      "Consider your demographics so you can sell to your customer/clients wants and needs",
      <Button variant="contained" color="primary">
        Learn More
      </Button>, // Add a button within the text
    ],
    image: Image1,
  },
  {
    text: [
      "Business Owners- Choose Your Ultimate Location",
      "Discover “RETONE” and optimize your business potential",
      "Review operations that either generate or compete for revenue",
      "Consider your demographics so you can sell to your customer/clients wants and needs",
      <Button variant="contained" color="primary">
        Learn More
      </Button>, // Add a button within the text
    ],
    image: Image1,
  },
  {
    text: [
      "Business Owners- Choose Your Ultimate Location",
      "Discover “RETONE” and optimize your business potential",
      "Review operations that either generate or compete for revenue",
      "Consider your demographics so you can sell to your customer/clients wants and needs",
      <Button variant="contained" color="primary">
        Learn More
      </Button>, // Add a button within the text
    ],
    image: Image1,
  },
  {
    text: [
      "Business Owners- Choose Your Ultimate Location",
      "Discover “RETONE” and optimize your business potential",
      "Review operations that either generate or compete for revenue",
      "Consider your demographics so you can sell to your customer/clients wants and needs",
      <Button variant="contained" color="primary">
        Learn More
      </Button>, // Add a button within the text
    ],
    image: Image1,
  },
  // Add more data objects for other rows if needed
];

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(2),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    flex: 1,
    // padding: theme.spacing(2),
    textAlign: "center",
  },
  imageContainer: {
    flex: 1,
    // padding: theme.spacing(2),
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

const MyComponent = () => {
  const classes = useStyles();

  return (
    <Grid container>
      {data.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Paper className={classes.paper}>
            <div className={classes.textContainer}>
              {index % 2 === 0 ? (
                item.text.map((line, i) => (
                  <Typography key={i} variant="body1">
                    {line}
                  </Typography>
                ))
              ) : (
                <img src={item.image} alt="Image" className={classes.image} />
              )}
            </div>
            <div className={classes.imageContainer}>
              {index % 2 === 0 ? (
                <img src={item.image} alt="Image" className={classes.image} />
              ) : (
                item.text.map((line, i) => (
                  <Typography key={i} variant="body1">
                    {line}
                  </Typography>
                ))
              )}
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default MyComponent;
