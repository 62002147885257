import React from "react";
import {
  Container,
  Typography,
  Box,
  Link,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function TermsOfUsePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const responsivePaperStyle = {
    padding: isMobile ? 2 : 3, // less padding on mobile
    marginTop: isMobile ? 2 : 4, // less top margin on mobile
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={responsivePaperStyle}>
        <Typography variant="h4" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome to Mapmax, LLC (“Mapmax”, &quot;Website&quot;, &quot;we&quot;,
          &quot;us&quot;, or &quot;our&quot;). Mapmax is designed to provide
          geographical real estate analysis, demographic information,
          proprietary RETONE designations, and the location topology of
          establishments that may generate revenue for your small business or
          conversely compete for it. Mapmax is also designed to provide useful
          information to individuals and families searching to fulfill
          residential requisites.
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          {/* Terms content here */}
          <Typography variant="body1" paragraph>
            Please read these Terms of Use (&quot;Terms&quot;, &quot;Terms of
            Use&quot;) carefully before using this website. Your access to and
            use of Mapmax is conditioned upon your acceptance of and compliance
            with these Terms. These Terms apply to all visitors, users, and
            others who wish to access or use the Website.
          </Typography>
          {/* Repeat for other points */}

          <Typography variant="body1" paragraph>
            By accessing or using Mapmax services, you agree to be bound by
            these Terms. If you disagree with any part of the Terms, then you do
            not have permission to access the Website.
          </Typography>
          <ol>
            <li>
              <Typography variant="body1">
                <strong>Information Provided:</strong> The information provided
                on Mapmax is for general informational purposes only. While we
                endeavor to provide accurate and up-to-date information, that we
                hope will be helpful, we make no guarantees, warranties or
                representations about the completeness, accuracy, reliability,
                or availability of the information, products, services, or
                related graphics contained on the Website. Any reliance you
                place on such information is strictly at your own risk.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Third-Party Content: </strong>Our Website uses and
                incorporates aggregated data and information from third-party
                users and sources. We are not responsible for the accuracy,
                quality, or reliability of such content. Any use or reliance on
                third-party content is solely at your own risk.
              </Typography>
            </li>
            {/* Add more list items as needed */}

            <li>
              <Typography variant="body1">
                <strong>No Guarantees: </strong>Mapmax aims to help users select
                a suitable location for their needs, but we do not and cannot
                guarantee the choice of a successful business location or
                residential living environment. Many factors beyond the control
                of any website may influence these outcomes. We expressly
                disclaim any and all liability for any decisions, actions,
                transactions, or results taken or not taken based on the
                information provided on the Website.
              </Typography>
            </li>

            <li>
              <Typography variant="body1">
                <strong>Intellectual Property: </strong>All content on the
                Website, including but not limited to text, graphics, logos,
                icons, images, algorithms and software, is the property of
                Mapmax or its content suppliers and is protected by copyright
                laws.
              </Typography>
            </li>

            <li>
              <Typography variant="body1">
                <strong>Limitation of Liability: </strong>In no event shall
                Mapmax or its affiliates, partners, or licensors be liable for
                any direct, indirect, incidental, special, consequential, or
                punitive damages (including, without limitation, loss of
                profits, data, use, goodwill, or other tangible or intangible
                losses) resulting from your access to or use of, or inability to
                access or use, Mapmax or any content on Mapmax, whether based on
                warranty, contract, tort, including negligence, or any other
                legal theory, and whether or not we have been informed of the
                possibility of such damage.
              </Typography>
            </li>

            <li>
              <Typography variant="body1">
                <strong>Governing Law: </strong>These Terms shall be governed
                and construed in accordance with the laws of New Jersey,
                USA without regard to its conflict of law provisions.
              </Typography>
            </li>

            <li>
              <Typography variant="body1">
                <strong>Changes: </strong>We reserve the right, at our sole and
                absolute discretion, to modify or replace these Terms at any
                time. If a revision is material, we will make reasonable efforts
                to provide at least 30 days&#39; notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole and absolute discretion.
              </Typography>

              <Typography variant="body1">
                By continuing to access or use our website after any revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, you are no longer authorized
                to use Mapmax.
              </Typography>
            </li>

            <li>
              <Typography variant="body1">
                <strong>Contact Us:</strong> If you have any questions about
                these Terms, please contact us at:
                <Link href="mailto:ithelp.mapmax@gmail.com">
                  {" "}
                  ithelp.mapmax@gmail.com
                </Link>
                ,
                <Link href="mailto:mapmax360@gmail.com">
                  {" "}
                  mapmax360@gmail.com
                </Link>
                .
              </Typography>
            </li>
          </ol>
        </Box>

        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            display: "flex",
            fontSize: "1.5rem",
            justifyContent: "center",
            alignItems: "center", // Use this if you also want vertical centering
            // Adjust the height as needed for vertical centering
          }}
        >
          Thank you for using Mapmax!
        </Typography>
      </Paper>
    </Container>
  );
}

export default TermsOfUsePage;
