//user-reducer.js

import { LOGIN_USER, LOGOUT_USER } from "../Actions/user-actions";

const initialState = {
  user: null,
  token: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // user-reducer.js
    case LOGIN_USER:
      return {
        ...state,
        user: { ...action.payload.user, isAuthenticated: true },
        token: action.payload.token,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: { ...state.user, isAuthenticated: false },
        token: null,
      };
    default:
      return state;
  }
}
