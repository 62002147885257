import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Box,
} from "@mui/material";

const TopographyPage = () => {
  return (
    <Container sx={{ minHeight: "70vh" }}>
      <Box py={3} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Topography Analysis
        </Typography>
      </Box>
      <Typography paragraph>
        When accessing your location take note of the land’s topography and
        natural barriers like rivers, lakes and parks. Be aware that customers
        may be reluctant to cross over or go around barriers to shop. Look over
        businesses and points of interest that can generate customers to you and
        also those that may compete to take customers away. Analyze strengths
        and weaknesses within the RETONE, along with the location’s individual
        merits of store layout, visibility and accessibility and your educated
        analysis will help you choose the best location that you can afford with
        a much higher likeliness of success.
      </Typography>
      {/* Adding an image that matches the width of the text container */}
      <Box my={2} sx={{ display: "flex", justifyContent: "center" }}>
        <CardMedia
          component="img"
          image="/topography.jpeg" // Replace with your image path
          alt="Descriptive Alt Text"
          sx={{
            width: "100%", // Makes the image match the container width
            height: "100%", // Adjusts height automatically to keep aspect ratio
            maxWidth: "1200px", // Optional: Limits the maximum width of the image
          }}
        />
      </Box>
    </Container>
  );
};

export default TopographyPage;
