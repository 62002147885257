import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";

function Review({ selectedAddress, imageFiles }) {
  const [page1Data, setPage1Data] = useState({});
  const [page2Data, setPage2Data] = useState({});
  const [page3Data, setPage3Data] = useState({});
  const [page4Images, setPage4Images] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const history = useHistory();

  useEffect(() => {
    // Load stored data if available
    setPage1Data(JSON.parse(localStorage.getItem("page1Data")) || {});
    setPage2Data(JSON.parse(localStorage.getItem("page2Data")) || {});
    setPage3Data(JSON.parse(localStorage.getItem("page3Data")) || {});
    setPage4Images(JSON.parse(localStorage.getItem("page4Images")) || []);
  }, []);

  const renderPage2Data = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Review - Page 2 Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Address of Property: {page2Data.address || "Not provided"}
              <br />
              Total Square Footage: {page2Data.squareFootage || "Not provided"}
              <br />
              Asking Price: {page2Data.askingPrice || "Not provided"}
              <br />
              Date Listed: {page2Data.dateListed || "Not provided"}
              <br />
              Days on Market: {page2Data.daysOnMarket || "Not provided"}
              <br />
              Tax History: {page2Data.taxHistory || "Not provided"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );

  const renderPage3Data = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Review - Page 3 Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Total Square Footage:{" "}
              {page3Data.totalSquareFootage || "Not provided"}
              <br />
              Total Number of Rooms:{" "}
              {page3Data.totalNumberOfRooms || "Not provided"}
              <br />
              Number of Bedrooms: {page3Data.numberOfBedrooms || "Not provided"}
              <br />
              Number of Bathrooms:{" "}
              {page3Data.numberOfBathrooms || "Not provided"}
              <br />
              Family Room: {page3Data.familyRoom || "Not provided"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );

  const renderPage4Images = () => (
    <Grid container spacing={2}>
      {page4Images.map((image, index) => (
        <Grid item xs={6} md={4} key={index}>
          <img
            src={image}
            alt={`img-${index}`}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "contain",
              marginBottom: "10px",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  const handleSubmit = async () => {
    try {
      // Attempt to create the listing first
      const createListingResponse = await axios.post(
        "http://localhost:8081/create-listing",
        {
          page1Data: { address: selectedAddress }, // Using selectedAddress as page1Data
          page2Data,
          page3Data,
        }
      );

      if (createListingResponse.status === 200) {
        const listingId = createListingResponse.data._id;
        const formData = new FormData();
        formData.append("listingId", listingId);

        // Ensure imageFiles is an array before calling forEach
        if (Array.isArray(imageFiles)) {
          imageFiles.forEach((file, index) => {
            formData.append("images", file, `image-${index}.jpg`);
          });

          const uploadImagesResponse = await axios.post(
            "http://localhost:8081/upload-images",
            formData // Removed headers to let Axios handle it
          );

          if (uploadImagesResponse.status === 200 || imageFiles.length === 0) {
            setSnackbarMessage("Listing and images uploaded successfully!");
            setSnackbarSeverity("success");
            history.push(`/getsalelistingdetails/${listingId}`);
          } else {
            // Set error message if image upload fails
            setSnackbarMessage("Failed to upload images. Listing created.");
            setSnackbarSeverity("error");
          }
        } else {
          // Set success message if no images are there but listing is created
          setSnackbarMessage(
            "Listing created successfully! No images to upload."
          );
          setSnackbarSeverity("success");
        }
      } else {
        // Set error message if listing creation fails
        setSnackbarMessage("Failed to create listing.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      // Set error message for any other errors
      setSnackbarMessage(`Submission failed: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      {/* Accordion for Selected Address */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Review - Selected Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{selectedAddress || "No address selected"}</Typography>
        </AccordionDetails>
      </Accordion>

      {/* Accordion for Page 2 Data */}
      {renderPage2Data()}

      {/* Accordion for Page 3 Data */}
      {renderPage3Data()}

      {/* Accordion for Page 4 Images */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Review - Page 4 Images</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderPage4Images()}</AccordionDetails>
      </Accordion>
      {/* Submit Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: "20px" }}
      >
        Submit
      </Button>

      {/* Submission Status */}
      {submissionStatus && (
        <Typography color="error">{submissionStatus}</Typography>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Review;
