import React from "react";

const NavigateTextPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-6 sm:p-10 lg:p-12">
        <h1 className="text-3xl font-bold mb-6 text-center pt-4">
          Welcome to Mapmax
        </h1>
        <p className="mb-4">
          Your comprehensive tool for analyzing the real estate landscape in New
          York City, Long Island, and New Jersey. Follow this simple guide to
          navigate our user-friendly website and unlock in-depth insights into
          commercial and residential properties.
        </p>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
          <p className="mb-2">
            Begin on the Mapmax Homepage. Type the address of the property
            you're interested in—be it commercial or residential—into the search
            bar. Select the appropriate address from the drop-down menu to
            proceed.
          </p>
          <p>
            After selecting the address, you'll be directed to the Map Page.
            This is the core of our analytics platform and where you'll find
            various visuals and detailed information about the area. You can
            switch between a basic map, satellite imagery, and street views to
            get different perspectives of the location. To exit street view,
            scroll all the way up and click on the arrow in the upper left top
            of the street view image to return to the map view.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Using RETONE and Zip Code
          </h2>
          <p className="mb-2">
            You may select either “RETONE” for an understanding of the primary
            community or “Zip Code” for zip code information by clicking on
            these options to the right under the “Demographics” header.
          </p>
          <p className="mb-2">
            “RETONE” is our proprietary algorithm that offers business owners a
            detailed examination of consumers in the surrounding area, factoring
            in their travel habits, shopping preferences, and lifestyle within
            the community.
          </p>
          <p className="mb-2">
            For Residential users “RETONE” gives insightful details such as
            essential goods, services, amenities, and transportation options in
            your new neighborhood.
          </p>
          <p className="mb-2">
            Selection of either “RETONE” or “Zip Code” will activate a shaded
            overlay area on the map or satellite image. This shaded area
            coordinates to the demographic information to the right side of the
            page to illustrate population density, income, education, ethnicity,
            and age ratios in the area.
          </p>
          <p>
            Colored rings, representing radius circles, encircle each location.
            These rings remain fixed on maps and aerial images. They differ by
            area to offer a user-specific view of the average daily travel
            distance in each unique neighborhood.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Businesses/Destinations
          </h2>
          <p className="mb-2">
            You can select the “Businesses/Destinations” category from the
            options above the map or satellite image. This will display all
            establishments in these categories as pins around your surrounding
            area and within your designated RETONE area on the map.
          </p>
          <p className="mb-2">
            Begin typing in any category of retail store, professional service,
            or destination and then choose from the drop-down menu.
          </p>
          <p className="mb-2">
            With this feature, retail and professional service providers seeking
            an ideal location might choose to avoid nearby competitors that
            could adversely affect their revenue. They can also seek out
            locating by destinations that might attract customers to their
            location.
          </p>
          <p className="mb-2">
            Pins may be clicked individually to reveal additional information
            about nearby “Businesses/Destinations”. Scroll down for photographs,
            distance from your location, and establishment ratings.
          </p>
          <p>
            Residential users can use this feature to see the proximity of
            essential services, stores, and points of interest, helping to
            improve daily life at home.
          </p>
          <p>
            For the boroughs in New York City: Manhattan, The Bronx, Brooklyn,
            and Queens the four (4) closest subway stations are available as an
            option with turnstile counts. This information is critical for
            businesses who rely on pedestrian traffic for revenue. For
            residents, and commuters, these show proximity to a major form of
            transportation in the city.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Obtaining Information
          </h2>
          <p className="mb-2">
            After obtaining all desired information, you can select a new
            location, by clicking on the arrow on the upper left top of the Map
            Page to return to the Home Page and begin a different search.
          </p>
          <p>
            Beyond the visual illustrations and comprehensive analytics
            available on the Map Page, Mapmax offers “Commercial” and
            “Residential” reviews. To select, click on these from the upper
            right side of the toolbar. For each, you have three subcategories to
            choose from.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Commercial
          </h2>
          <ul className="list-disc list-inside">
            <li>“RETONE” to get analytics to understand your community.</li>
            <li>
              “Property Review” to see a checklist and rate your location in
              terms of its visibility, signage, and accessibility, and more.
            </li>
            <li>
              “Topography” to critique other considerations that have a direct
              impact on your business but are not immediately evident.
            </li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Residential
          </h2>
          <ul className="list-disc list-inside">
            <li>
              “RETONE” to evaluate how the community fits with your lifestyle.
            </li>
            <li>
              “Property Specifications” to access details on construction, year
              built, square footage, pricing, and terms.
            </li>
            <li>
              “Other Considerations” to learn about often-overlooked aspects
              crucial for residential decisions.
            </li>
          </ul>
        </section>
        <p className="mb-4">
          Mapmax is designed to empower your real estate choices by providing
          detailed analysis and insights. It can help business owners pinpoint
          locations that will offer the highest level of success; and it can
          assist homebuyers and renters with comprehensive insights to find an
          ideal residence.
        </p>
        <p className="font-bold text-center">And this is just the start.</p>
        <p className="font-bold text-center">
          Let insightful data empower your real estate decisions with Mapmax.
        </p>
      </div>
    </div>
  );
};

export default NavigateTextPage;
