// import React from "react";
// import {
//   Container,
//   Box,
//   Typography,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent,
//   CardActions,
//   Button,
//   Chip,
// } from "@mui/material";
// import { ThemeProvider, createTheme } from "@mui/material/styles";

// // Custom theme settings with a light background color
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#556cd6",
//     },
//     secondary: {
//       main: "#19857b",
//     },
//     background: {
//       default: "#f4f5f7", // Light background color
//     },
//   },
//   typography: {
//     h2: {
//       fontWeight: 500,
//       fontSize: "2.2rem",
//       letterSpacing: "0.0075em",
//     },
//     subtitle1: {
//       fontSize: "1.25rem",
//     },
//     body2: {
//       fontSize: "1rem",
//     },
//   },
//   components: {
//     MuiCard: {
//       styleOverrides: {
//         root: {
//           height: "100%", // Ensures all cards are of the same height
//         },
//       },
//     },
//   },
// });

// function CommercialDropdownPage() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Container
//         maxWidth="xl"
//         sx={{ Width: "90%", marginBottom: "30px", marginTop: "30px" }}
//       >
//         <Box
//           py={5}
//           textAlign="center"
//           sx={{ backgroundColor: theme.palette.background.default }}
//         >
//           <Typography variant="subtitle1" gutterBottom>
//             Advanced Commercial Real Estate Analysis Tools for Today's Market
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Harnessing the power of data-driven insights to empower real estate
//             professionals.
//           </Typography>
//         </Box>
//         <Grid container spacing={4}>
//           {/* Feature Cards */}
//           {/* Card 1 */}
//           <Grid item xs={12} sm={6} md={4}>
//             <Card>
//               <CardMedia
//                 component="img"
//                 height="200"
//                 //code to fit to container
//                 objectFit="contain"
//                 contain
//                 image="dataanalysis.jpg" // Unsplash image
//                 alt="Skyscraper"
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5">
//                   Cutting-edge Data Analytics
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   Leverage advanced algorithms for market prediction and
//                   investment analysis.
//                 </Typography>
//               </CardContent>
//               <CardActions>
//                 <Button size="small">Explore</Button>
//                 <Chip label="New" color="primary" />
//               </CardActions>
//             </Card>
//           </Grid>
//           {/* Card 2 */}

//           <Grid item xs={12} sm={6} md={4}>
//             <Card>
//               <CardMedia
//                 component="img"
//                 height="200"
//                 image="retone.png" // Unsplash image
//                 alt="Real Estate"
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5">
//                   Interactive Property Maps
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   Explore properties with our interactive mapping and
//                   visualization tools.
//                 </Typography>
//               </CardContent>
//               <CardActions>
//                 <Button size="small">View More</Button>
//               </CardActions>
//             </Card>
//           </Grid>

//           <Grid item xs={12} sm={6} md={4}>
//             <Card>
//               <CardMedia
//                 component="img"
//                 height="200"
//                 image="nearby.jpg" // Unsplash image
//                 alt="Office"
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5">
//                   Nearby Business Insights
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   Access detailed reports on market trends, pricing, and
//                   demographics.
//                 </Typography>
//               </CardContent>
//               <CardActions>
//                 <Button size="small">Discover</Button>
//               </CardActions>
//             </Card>
//           </Grid>
//           {/* Card 3 */}
//         </Grid>
//       </Container>
//     </ThemeProvider>
//   );
// }

// export default CommercialDropdownPage;

import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Custom theme settings
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    background: {
      default: "#f4f5f7",
    },
  },
  typography: {
    h2: {
      fontWeight: 500,
      fontSize: "2.2rem",
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
    header: {
      fontWeight: 600,
      fontSize: "2.5rem",
      margin: "20px 0",
      color: "#333",
    },
    cardHeader: {
      fontSize: "1.7rem", // Increased font size
      fontWeight: 400,
      marginBottom: "10px",
      color: "#4682b4", // New color
      display: "flex",
      justifyContent: "center",
      // textAlign: "center",
      // alignItems: "center",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
  },
});

function CommercialDropdownPage() {
  const history = useHistory();

  const navigateTo = (path) => {
    history.push(path);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Box py={3} textAlign="center">
          <Typography variant="header" gutterBottom>
            Commercial Review
          </Typography>
        </Box>
        <Box py={2} textAlign="center">
          <Typography variant="subtitle1" gutterBottom>
            Advanced Commercial Real Estate Analysis Tools for Today's Market
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Harnessing the power of data-driven insights to empower real estate
            professionals.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Feature Cards */}
          {/* Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/retone")}
                >
                  RETONE
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="retone.png"
                  alt="Interactive Map"
                  onClick={() => navigateTo("/retone")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Interactive Property Maps
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Explore locations with our interactive mapping, visualization
                  tools, and proprietary algorithm called "RETONE".
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/retone")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Discover
                </Button>
              </CardActions>
              {/* <CardActions>
                <Button
                  size="small"
                  onClick={() => navigateTo("/retone")}
                  sx={{
                    backgroundColor: "#33ab9f", // Set the background color directly here
                    color: "#F0F8FF", // Set the default text color to gray (or any color you prefer)
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keep the background color the same on hover
                      color: "#99CCFF", // Change the text color to white on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Discover
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
          {/* Card 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/propertyreview")}
                >
                  Property Review
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  objectFit="contain"
                  image="dataanalysis.jpg"
                  alt="Data Analysis"
                  onClick={() => navigateTo("/propertyreview")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Property Assessment Checklist
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Evaluate fundamental real estate features for the propensity
                  of a successful business site.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/propertyreview")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Consider
                </Button>
                {/* <Chip label="New" color="primary" /> */}
              </CardActions>
            </Card>
          </Grid>
          {/* Card 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="cardHeader"
                  onClick={() => navigateTo("/topography")}
                >
                  Topography
                </Typography>
                <CardMedia
                  component="img"
                  height="350"
                  image="nearby.png"
                  alt="Nearby Business"
                  onClick={() => navigateTo("/topography")}
                />
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ textAlign: "center" }}
                >
                  Geographical Business Insights
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Consider all demographics and topography in the area
                  surrounding your business.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Button
                  size="small"
                  onClick={() => navigateTo("/topography")}
                  sx={{
                    backgroundColor: "#33ab9f",
                    color: "#F0F8FF", // Initial color of the text
                    "&:hover": {
                      backgroundColor: "#33ab9f", // Keeps background color on hover
                      color: "#99CCFF", // Changes text color on hover
                    },
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  Explore
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {/* Summary Text */}
        <Box py={4} textAlign="left">
          <Typography variant="body1">
            A successful retail business depends on a good product, competent
            management, and a great location. Thus, selecting the right location
            is the foundation to building a successful retail enterprise or
            professional office site. With many products and services available
            online, and a variety of physical spaces available, the selection of
            a prime real estate location upon which to build a brick-and-mortar
            establishment becomes more vital than ever before. Mapmax analyses
            the visibility and accessibility of a location and takes into
            consideration the neighboring retail that will generate customers
            and/or compete for their business. It also provides business owners
            insight into community demographics that have the highest potential
            to provide and establish a loyal and continuous customer base. This
            proprietary software is called "RETONE".
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default CommercialDropdownPage;
