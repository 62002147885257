import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { connect } from "react-redux";
import * as selectors from "../../../Reducers/selectors";

const GoogleMapsOverlay = (props) => {
  const mapRef = useRef(null);
  const mapObjectRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const loaderRef = useRef(new GLTFLoader());
  const [currentLocation, setCurrentLocation] = useState(
    props.address?.coords || {
      lat: 40.7074,
      lng: -74.0113,
    }
  );
  let map;

  const apiOptions = {
    apiKey: "AIzaSyDCkeKGG8hYP59S-ocZ-aTOfotl0a-jfjQ",
    version: "weekly",
    libraries: ["places"],
  };

  const mapOptions = {
    center: currentLocation,
    tilt: 0,
    heading: 0,
    zoom: 18,
    mapId: "57a2d86ffda6dce3",
    gestureHandling: "greedy",
  };

  // Function to dynamically assign colors
  const getColorForZone = (zonedist) => {
    let hash = 0;
    for (let i = 0; i < zonedist.length; i++) {
      hash = zonedist.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }

    return color;
  };

  // Function to initialize the map
  const initMap = async () => {
    const apiLoader = new Loader(apiOptions);
    await apiLoader.load();
    map = new google.maps.Map(mapRef.current, mapOptions);
    initWebGLOverlayView(map);
  };

  // Function to initialize WebGL Overlay View
  const initWebGLOverlayView = (map) => {
    let renderer, camera;
    const webGLOverlayView = new google.maps.WebGLOverlayView();

    webGLOverlayView.onAdd = async () => {
      const scene = sceneRef.current;
      camera = new THREE.PerspectiveCamera();
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
      scene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.95);
      directionalLight.position.set(0.5, -1, 0.5);
      scene.add(directionalLight);

      const loader = loaderRef.current;
      const source = "./pin.gltf";

      loader.load(source, (gltf) => {
        gltf.scene.scale.set(5, 5, 5);
        gltf.scene.rotation.x = (180 * Math.PI) / 180;
        scene.add(gltf.scene);
      });

      const zoningDistricts = await loadZoningDistricts();
      addZoningDistrictsToScene(map, zoningDistricts);
    };
    webGLOverlayView.onContextRestored = ({ gl }) => {
      renderer = new THREE.WebGLRenderer({
        canvas: gl.canvas,
        context: gl,
        ...gl.getContextAttributes(),
      });
      renderer.autoClear = false;

      const loader = loaderRef.current;
      loader.manager.onLoad = () => {
        renderer.setAnimationLoop(() => {
          map.moveCamera({
            tilt: mapOptions.tilt,
            heading: mapOptions.heading,
            zoom: mapOptions.zoom,
          });

          if (mapOptions.tilt < 67.5) {
            mapOptions.tilt += 0.9;
          } else if (mapOptions.heading <= 10) {
            mapOptions.heading += 0.9;
          } else {
            renderer.setAnimationLoop(null);
          }
        });
      };
    };

    webGLOverlayView.onDraw = ({ gl, transformer }) => {
      const scene = sceneRef.current; // Access the scene from sceneRef

      const matrix = transformer.fromLatLngAltitude({
        lat: currentLocation.lat,
        lng: currentLocation.lng,
        altitude: 0, // Adjust this value as needed
      });

      camera.projectionMatrix = new THREE.Matrix4().fromArray(matrix);
      webGLOverlayView.requestRedraw();
      renderer.render(scene, camera);
      renderer.resetState();
    };

    webGLOverlayView.setMap(map);
  };

  // Function to load zoning districts
  const loadZoningDistricts = async () => {
    const response = await fetch("./data.geojson");
    const data = await response.json();
    return data.features;
  };

  // Function to add zoning districts to the scene
  const addZoningDistrictsToScene = (map, zoningDistricts) => {
    zoningDistricts.forEach((feature) => {
      const coordinates = feature.geometry.coordinates[0].map((coord) => {
        return { lat: coord[1], lng: coord[0] };
      });

      const zonedist = feature.properties.ZONEDIST;
      const fillColor = getColorForZone(zonedist);

      const zoningPolygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: fillColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 0.35,
      });

      zoningPolygon.addListener("click", () => {
        const infoWindow = new google.maps.InfoWindow({
          content: `<strong>Zone Name:</strong> ${zonedist}`,
          position: coordinates[0],
        });
        infoWindow.open(map);
      });

      zoningPolygon.setMap(map);
    });
  };

  const initMapAndAutocomplete = async () => {
    const apiLoader = new Loader(apiOptions);
    await apiLoader.load();

    const map = new google.maps.Map(mapRef.current, mapOptions);
    initWebGLOverlayView(map);

    // Initialize Autocomplete
    const input = document.getElementById("location-search-input");
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }
      const newLocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setCurrentLocation(newLocation);
      map.setCenter(newLocation);
      map.setZoom(18);
    });
  };

  useEffect(() => {
    initMapAndAutocomplete();
  }, []);

  useEffect(() => {
    if (
      mapRef.current &&
      props.address &&
      !isNaN(props.address.coords.lat) &&
      !isNaN(props.address.coords.lng)
    ) {
      // Check if coordinates are valid numbers
      const newLocation = {
        lat: props.address.coords.lat,
        lng: props.address.coords.lng,
      };
      setCurrentLocation(newLocation);
      const map = new google.maps.Map(mapRef.current, {
        ...mapOptions,
        center: newLocation,
      });
      initWebGLOverlayView(map);
    }
  }, [props.address]);

  return (
    <div>
      <button style={{ color: "green" }}>Tax Map</button>
      <button style={{ color: "green" }}>Zoning Map</button>
      <div id="map" ref={mapRef} style={{ width: "100%", height: "100vh" }} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    address: selectors.addressSelector(state),
  };
};

export default connect(mapStateToProps)(GoogleMapsOverlay);
