import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";

function HomePagePopupVideo() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const showModal = !localStorage.getItem("videoShown");
    if (showModal) {
      setModalIsOpen(true);
      localStorage.setItem("videoShown", "true");
    }
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: "90%", sm: "80%", md: "70%" }, // Responsive width
          height: { xs: "50%", sm: "60%", md: "70%" }, // Responsive height
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 1,
          overflow: "hidden",
          maxWidth: "960px", // Ensures the modal does not get too large on wide screens
        }}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "common.white",
            backgroundColor: "grey.800",
            "&:hover": {
              backgroundColor: "grey.900",
            },
            zIndex: 1500,
            padding: "6px",
            borderRadius: "50%",
          }}
        >
          <CloseIcon />
        </IconButton>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=ez64TLaL4n4"
          playing={modalIsOpen}
          controls={true}
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      </Box>
    </Modal>
  );
}

export default HomePagePopupVideo;
