import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Chip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Custom theme settings with a light background color
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    background: {
      default: "#f4f5f7", // Light background color
    },
  },
  typography: {
    h2: {
      fontWeight: 500,
      fontSize: "2.2rem",
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          height: "100%", // Ensures all cards are of the same height
        },
      },
    },
  },
});

function MapmaxContainer() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{ Width: "90%", marginBottom: "30px", marginTop: "30px" }}
      >
        <Box
          py={5}
          textAlign="center"
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Advanced Commercial Real Estate Analysis Tools for Today's Market
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Harnessing the power of data-driven insights to empower real estate
            professionals.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Feature Cards */}
          {/* Card 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                //code to fit to container
                objectFit="contain"
                contain
                image="dataanalysis.jpg" // Unsplash image
                alt="Skyscraper"
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Cutting-edge Data Analytics
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Leverage advanced algorithms for market prediction and
                  investment analysis.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Explore</Button>
                <Chip label="New" color="primary" />
              </CardActions>
            </Card>
          </Grid>
          {/* Card 2 */}

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image="retone.png" // Unsplash image
                alt="Real Estate"
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Interactive Property Maps
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Explore properties with our interactive mapping and
                  visualization tools.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">View More</Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image="nearby.jpg" // Unsplash image
                alt="Office"
              />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Nearby Business Insights
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Access detailed reports on market trends, pricing, and
                  demographics.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Discover</Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Card 3 */}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default MapmaxContainer;
