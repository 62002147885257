// FeedbackIcon.js
import React from "react";
import { IoMdChatbubbles } from "react-icons/io";
import styled from "styled-components";

const IconWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`;

function FeedbackIcon({ onClick }) {
  return (
    <IconWrapper onClick={onClick}>
      <IoMdChatbubbles size="40px" color="#007bff" />
    </IconWrapper>
  );
}

export default FeedbackIcon;
