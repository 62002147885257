import React from "react";
import NavigationBar from "../Navigation/NavigationBar";
import { Container, Typography, Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    backgroundColor: "#001A70",
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  headerTypography: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  contentBox: {
    margin: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  typography: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  serviceOverviewBox: {
    marginBottom: theme.spacing(4),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  serviceOverviewTypography: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const Mission = () => {
  const classes = useStyles();

  return (
    <>
      <NavigationBar />
      {/* <Box className={classes.headerBox}>
        <Typography variant="h3" className={classes.headerTypography}>
          Mission Statement
        </Typography>
      </Box> */}

      <Box className={classes.headerBox}>
        <CardMedia
          component="img"
          height="300px"
          image="blue_logo.png"
          alt="Interactive Map"
          style={{ objectFit: "contain" }} // This ensures the image fits without being cut off
        />
        <Typography variant="h3" className={classes.headerTypography}>
          Mission Statement
        </Typography>
      </Box>
      <Container maxWidth="lg">
        <Box className={classes.contentBox}>
          {/* Each Typography component represents a paragraph */}
          <Typography sx={{ mb: 4 }}>
            The mission of Mapmax is to provide real estate decision-making
            intelligence to potential buyers, sellers, and renters.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            Mapmax provides small business owners the analytical tools necessary
            to make the same informed real estate location decisions that major
            corporations have at their disposal. It also offers individuals and
            families information from which to better choose a new home.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            A successful retail business depends on a good product, competent
            management, and a great location. Thus, selecting the right location
            is the foundation for building a successful retail enterprise or
            professional office site. With many products and services available
            online, the selection of a prime location upon which to build a
            brick-and-mortar establishment has become more vital than ever
            before.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            Mapmax provides small business owners insight into trade zone
            demographics that have the highest potential to become a loyal
            customer base. This program analyses the visibility and
            accessibility of a location and considers the neighboring businesses
            that will either generate customers or conversely compete for their
            patronage.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            When the recent global pandemic created extensive movement among
            residential homeowners and renters, Mapmax also realized a need to
            provide geographical information about potential new locations to
            individuals and families. When searching for a new house or
            apartment, helpful information includes the location’s proximity to
            transportation, retail stores like supermarkets, pharmacies and
            restaurants, barbers and salons, and services such as doctors,
            dentists, and veterinarians. Also for consideration are the home’s
            proximity to schools, places of worship, and recreational facilities
            such as parks and gyms. The new community is key. For example, a
            family with young children may want to find a community where many
            other young children are in the neighborhood for their kids to
            befriend.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            Mapmax provides demographics, aerials, mapping images, existing
            neighborhood businesses, and site photographs. A unique and
            proprietary software called “RETONE” analyses demographic and
            geographical information specific to the community surrounding the
            location. “RETONE” provides a comprehensive look into the customer
            base and residential community to highlight the strength of
            demographic diversity and opportunity.
          </Typography>
        </Box>
      </Container>

      <Box className={classes.serviceOverviewBox}>
        <Typography
          variant="h5"
          className={classes.serviceOverviewTypography}
          gutterBottom
        >
          Mapmax provides the intelligence of visiting and exploring new
          locations in a few clicks!
        </Typography>
      </Box>
    </>
  );
};

export default Mission;
