import React from "react";
import NavigationBar from "../Navigation/NavigationBar";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  CardMedia,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    backgroundColor: "#001A70",
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  headerTypography: {
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  contentBox: {
    margin: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2),
    },
  },
  typography: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  serviceOverviewBox: {
    marginBottom: theme.spacing(4),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  serviceOverviewTypography: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const History = () => {
  const classes = useStyles();

  return (
    <>
      <NavigationBar />
      {/* <Box className={classes.headerBox}>
        <Typography variant="h3" className={classes.headerTypography}>
          History and Intent
        </Typography>
      </Box> */}

      <Box className={classes.headerBox}>
        <CardMedia
          component="img"
          height="300px"
          image="blue_logo.png"
          alt="Interactive Map"
          style={{ objectFit: "contain" }} // This ensures the image fits without being cut off
        />
        <Typography variant="h3" className={classes.headerTypography}>
          History and Intent
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Box className={classes.contentBox}>
          {/* Content goes here - use Typography components with className={classes.typography} */}
          <Typography sx={{ mb: 4 }}>
            Our founder began her commercial real estate career first in
            brokerage for small businesses and soon by advancing into site
            selection of retail locations for several US Fortune 500
            corporations. These large corporations demanded rapid expansion of
            company-owned and operated chain stores in real estate locations
            that guaranteed a high likelihood of success. This work entailed a
            high level of detailed analysis of location features, site
            geography, and interpretation of the surrounding community.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            As a corporate site selector and deal maker, first in New York City
            (with an emphasis in Manhattan), then throughout the domestic US,
            and ultimately into global markets, a methodology of location
            analysis developed. The procedure entailed compiling relevant
            information about each new location and its surrounding area to
            analyze and predict the likelihood of a specific concept succeeding,
            given the region's site specifics, demographics, and topography.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            After completing numerous assignments, our founder had an epiphany.
            The fundamental mythology of successful site selection is strikingly
            similar throughout the world. The difference between how large
            corporations and small business owners select real estate locations
            is staggering. While well-financed corporations collect a wealth of
            research to analyze the potential viability of a location, most
            independent businesses rely on guesswork and chance. The difficulty
            for many small business owners is that they are experts in making
            and selling their products, yet they are not well trained in site
            selection of commercial real estate. By contrast, major companies
            have teams of real estate professionals specifically educated to
            select ideal location sites.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            An idea for Mapmax was born. If one would provide a service of
            analysis that incorporated the same mythology for site selection
            that large corporations use and provide it to small business owners,
            they would have the same tools necessary for predicting a successful
            location as the major retail chains. Mapmax’s founder took a
            methodology of site selection based on her training, observation,
            and experience and, along with a team, created a digital platform of
            analysis for the selection of great real estate.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            This concept was actively created when the global pandemic Covid-19
            closed down much of the world. As remote work popularized a mass
            exodus from cities into suburbs and remote areas, our founder
            realized an additional need to provide individuals and families
            information with which to make informed decisions about where to
            move and live.
          </Typography>

          <Typography sx={{ mb: 4 }}>
            During lockdown, thousands of people searched for new places to live
            without being able to travel to these new areas to investigate them
            first thoroughly. This brought to light the benefits of researching
            location information about a potential new home without spending the
            time, money, and energy to physically travel there. However, once
            choices are narrowed to one or two, an on-site investigation is
            always wise before any final decision. Mapmax compiles important
            location information that a prospective new homeowner or renter can
            use to make an informed decision. The relevant information is
            similar to what retail businesses use when deciding where to put
            their stores. The difference is that business and residential users
            will interpret the data differently, focusing on the data elements
            that meet their specific needs, as described in the navigation bar
            options for “Commercial” or “Residential” review and analysis.
          </Typography>

          {/* Impact of Covid-19 Section */}

          <Typography sx={{ mb: 4 }}>
            Mapmax is an informative service designed to maximize a business’s
            potential to make good real estate decisions and to help individuals
            and families make informed decisions about where to live, work, and
            travel.
          </Typography>
        </Box>
      </Container>

      <Box className={classes.serviceOverviewBox}>
        <Typography
          variant="h5"
          className={classes.serviceOverviewTypography}
          gutterBottom
        >
          Everyone can benefit from Mapmax!
        </Typography>
      </Box>
    </>
  );
};

export default History;
