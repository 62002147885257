// import React from "react";
// import {
//   Container,
//   Box,
//   Typography,
//   TextField,
//   Button,
//   Grid,
//   CardMedia,
// } from "@mui/material";

// // Example images from Unsplash, replace with your desired images for each card
// const images = [
//   "https://source.unsplash.com/random/200x200?sig=1", // Replace with actual image URL for ProValue Estimate
//   "https://source.unsplash.com/random/200x200?sig=2", // Replace with actual image URL for Comparable Sales
//   "https://source.unsplash.com/random/200x200?sig=3", // Replace with actual image URL for Price History
//   "https://source.unsplash.com/random/200x200?sig=4", // Replace with actual image URL for Local Market Snapshots
// ];

// function InTouch() {
//   return (
//     <Box
//       sx={{
//         backgroundColor: "#eeeeee",
//         color: "text.primary",
//         py: 8,
//         px: 2,
//       }}
//     >
//       <Container maxWidth="lg">
//         <Box textAlign="center" my={4}>
//           <Typography
//             variant="h2"
//             component="h2"
//             gutterBottom
//             sx={{
//               fontFamily: ["Clarkson", "Helvetica", "sans-serif"],
//               fontWeight: 700,
//             }}
//           >
//             Select your business location Smarter.
//           </Typography>
//           <Typography
//             variant="h4"
//             component="h4"
//             my={4}
//             sx={{ fontFamily: ["Clarkson", "Helvetica", "sans-serif"] }}
//           >
//             Get Your Business Value Report by our RETONE Algorithm!
//           </Typography>
//         </Box>

//         <Box display="flex" justifyContent="center" mb={4}>
//           <TextField
//             id="address-input"
//             label="Enter your address"
//             variant="outlined"
//             sx={{
//               mr: 1,
//               width: "50%",
//               "& .MuiInputBase-root": { borderRadius: "20px" },
//             }}
//           />
//           <Button
//             variant="contained"
//             color="primary"
//             sx={{ borderRadius: "20px", padding: "10px 20px" }}
//           >
//             Get In Touch
//           </Button>
//         </Box>

//         <Grid container spacing={4} justifyContent="center">
//           {iconItems.map((item, index) => (
//             <Grid item xs={12} sm={12} md={6} key={index}>
//               <IconItem
//                 image={images[index]}
//                 title={item.title}
//                 description={item.description}
//               />
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   );
// }

// const iconItems = [
//   {
//     title: "Our ProValue Estimate",
//     description:
//       "Get the most accurate estimate, powered by the same technology used by lenders.",
//   },
//   {
//     title: "Comparable Sales",
//     description:
//       "See what other homes are being sold for in and around your neighborhood.",
//   },
//   {
//     title: "Price History",
//     description: "See sale dates and prices from years past.",
//   },
//   {
//     title: "Local Market Snapshots",
//     description: "Get the scoop on sale prices in your ZIP code.",
//   },
// ];

// function IconItem({ image, title, description }) {
//   return (
//     <Box
//       sx={{
//         backgroundColor: "white", // Set the background color of the card to white
//         textAlign: "center",
//         p: 3,
//         boxShadow: 3,
//         borderRadius: 2,
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//       }}
//     >
//       <CardMedia
//         component="img"
//         height="140"
//         image={image}
//         alt={title}
//         sx={{ mb: 2, borderRadius: "5px" }}
//       />
//       <Typography
//         variant="h6"
//         component="h3"
//         sx={{
//           fontFamily: ["Clarkson", "Helvetica", "sans-serif"],
//           fontWeight: 600,
//         }}
//       >
//         {title}
//       </Typography>
//       <Typography
//         variant="body2"
//         sx={{ fontFamily: ["Clarkson", "Helvetica", "sans-serif"] }}
//       >
//         {description}
//       </Typography>
//     </Box>
//   );
// }

// export default InTouch;

// import React from "react";
// import {
//   Container,
//   Box,
//   Typography,
//   Button,
//   TextField,
//   Grid,
//   CardMedia,
//   Paper,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";

// // Replace with actual image URLs
// const images = [
//   "https://source.unsplash.com/random/200x200?sig=1",
//   "https://source.unsplash.com/random/200x200?sig=2",
//   "https://source.unsplash.com/random/200x200?sig=3",
//   "https://source.unsplash.com/random/200x200?sig=4",
// ];

// const ProfessionalTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "gray",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "gray",
//   },
//   "& .MuiOutlinedInput-root": {
//     "&.Mui-focused fieldset": {
//       borderColor: "gray",
//     },
//   },
// });

// const ProfessionalButton = styled(Button)({
//   backgroundColor: "gray",
//   "&:hover": {
//     backgroundColor: "darkgray",
//   },
// });

// const StyledPaper = styled(Paper)({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "center",
//   boxShadow: "none",
//   backgroundColor: "transparent",
//   overflow: "hidden",
// });

// const StyledCardMedia = styled(CardMedia)({
//   flex: "1 1 auto",
//   width: "50%",
// });

// const TextSection = styled(Box)({
//   flex: "1 1 auto",
//   padding: "20px",
//   textAlign: "left",
//   maxWidth: "50%",
// });

// function InTouch() {
//   return (
//     <Box
//       sx={{
//         backgroundColor: "#f5f5f5",
//         color: "text.primary",
//         py: 8,
//         px: 2,
//         borderTop: "5px solid #000",
//         borderBottom: "5px solid #000",
//       }}
//     >
//       <Container maxWidth="lg" sx={{ maxWidth: "75%" }}>
//         {" "}
//         {/* Adjusted maxWidth and width */}
//         <Box textAlign="center" my={4}>
//           <Typography
//             variant="h2"
//             component="h2"
//             gutterBottom
//             sx={{
//               fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//               fontWeight: 700,
//             }}
//           >
//             Discover the Value of Your Business Location
//           </Typography>
//           <Typography
//             variant="h4"
//             component="h4"
//             my={4}
//             sx={{ fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif' }}
//           >
//             Empower your decisions with our comprehensive Business Value Report.
//           </Typography>
//         </Box>
//         <Box display="flex" justifyContent="center" mb={4}>
//           <ProfessionalTextField
//             id="address-input"
//             label="Enter your address"
//             variant="outlined"
//             sx={{ mr: 1, width: "50%" }}
//           />
//           <ProfessionalButton variant="contained" color="primary">
//             Get Started
//           </ProfessionalButton>
//         </Box>
//         <Grid container spacing={2} justifyContent="center">
//           {iconItems.map((item, index) => (
//             <Grid item xs={12} key={index}>
//               <StyledPaper>
//                 <TextSection>
//                   <Typography
//                     variant="h5"
//                     component="div"
//                     sx={{ color: "#0077db", fontWeight: "bold" }}
//                   >
//                     {item.title}
//                   </Typography>
//                   <Typography
//                     variant="h2"
//                     sx={{
//                       fontSize: "36px",
//                       fontWeight: 600,
//                       lineHeight: "48px",
//                       marginY: "20px",
//                     }}
//                   >
//                     {item.subtitle}
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       lineHeight: "24px",
//                       fontWeight: 400,
//                     }}
//                   >
//                     {item.description.join(" ")}
//                   </Typography>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     sx={{ marginTop: "20px" }}
//                   >
//                     Subscribe Now
//                   </Button>
//                 </TextSection>
//                 <StyledCardMedia
//                   component="img"
//                   image={images[index]}
//                   alt={item.title}
//                 />
//               </StyledPaper>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   );
// }

// const iconItems = [
//   {
//     title: "Sales Comps",
//     subtitle: "Access sale prices, financing info, and property details",
//     description: [
//       "View every parcel in the nation and access over 13 million sales comps. Comps include property details, transaction history, ownership info*, and more!",
//     ],
//   },
//   {
//     title: "Market Review",
//     subtitle: "Access sale prices, financing info, and property details",
//     description: [
//       "Stay ahead with insights into market trends and forecasts for informed decision-making.",
//       "Assess market dynamics, including supply and demand, to make data-driven decisions.",
//       "Gain a comprehensive understanding of the factors influencing your market segment.",
//     ],
//   },
//   {
//     title: "Demographics",
//     subtitle: "Access sale prices, financing info, and property details",
//     description: [
//       "Understand your target audience with in-depth demographic analysis.",
//       "Analyze the age, income, and lifestyle patterns of the local population.",
//       "Tailor your marketing and property development strategies to the demographic profiles.",
//     ],
//   },
//   {
//     title: "Sale Comparisons",
//     subtitle: "Access sale prices, financing info, and property details",
//     description: [
//       "Evaluate your property's potential with comprehensive sales data and benchmarks.",
//       "Compare recent sales in your area to gauge market conditions and property values.",
//       "Leverage historical data to predict future trends and maximize investment returns.",
//     ],
//   },
// ];

// function IconItem({ image, title, description }) {
//   return (
//     <IconPaper>
//       <CardMedia
//         component="img"
//         sx={{ width: "20%", height: "10%", borderRadius: "5px" }}
//         image={image}
//         alt={title}
//       />
//       <Box sx={{ width: "100%", textAlign: "center" }}>
//         <Typography
//           variant="h4"
//           component="h3"
//           sx={{
//             fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//             fontWeight: 500,
//             mb: 1,
//           }}
//         >
//           {title}
//         </Typography>
//         {description.map((line, index) => (
//           <Typography
//             key={index}
//             variant="body2"
//             sx={{
//               fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
//               mt: index === 0 ? 0 : 1,
//             }}
//           >
//             {line}
//           </Typography>
//         ))}
//       </Box>
//     </IconPaper>
//   );
// }

// export default InTouch;

import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  CardMedia,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Replace with actual image URLs
const images = [
  "https://source.unsplash.com/random/200x200?sig=1",
  "https://source.unsplash.com/random/200x200?sig=2",
  "https://source.unsplash.com/random/200x200?sig=3",
  "https://source.unsplash.com/random/200x200?sig=4",
];

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: "transparent",
  overflow: "hidden",
});

const StyledCardMedia = styled(CardMedia)({
  flex: "1 1 auto",
  width: "50%",
});

const TextSection = styled(Box)({
  flex: "1 1 auto",
  padding: "20px",
  textAlign: "left",
  maxWidth: "50%",
});

function InTouch() {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        color: "text.primary",
        py: 8,
        px: 2,
        borderTop: "5px solid #000",
        borderBottom: "5px solid #000",
      }}
    >
      <Container maxWidth="xl" sx={{ maxWidth: "100%" }}>
        {/* Your Header and Input Fields here */}

        <Grid container justifyContent="center">
          {iconItems.map((item, index) => (
            <Grid item xs={12} key={index}>
              <StyledPaper
                sx={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
              >
                <TextSection>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: "#0077db", fontWeight: "bold" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "36px",
                      fontWeight: 600,
                      lineHeight: "48px",
                      marginY: "20px",
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                    }}
                  >
                    {item.description.join(" ")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "20px" }}
                  >
                    Subscribe Now
                  </Button>
                </TextSection>
                <StyledCardMedia
                  component="img"
                  image={images[index]}
                  alt={item.title}
                />
              </StyledPaper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

const iconItems = [
  {
    title: "Interactive Maps",
    subtitle: "Easily see population, income, and traffic counts.",
    description: [
      "Interactive maps give you the ability to see the most recent population numbers, median income, traffic counts, and points of interest.",
    ],
  },
  {
    title: "Interactive Maps",
    subtitle: "Easily see population, income, and traffic counts.",
    description: [
      "Interactive maps give you the ability to see the most recent population numbers, median income, traffic counts, and points of interest.",
    ],
  },
  {
    title: "Interactive Maps",
    subtitle: "Easily see population, income, and traffic counts.",
    description: [
      "Interactive maps give you the ability to see the most recent population numbers, median income, traffic counts, and points of interest.",
    ],
  },
  {
    title: "Interactive Maps",
    subtitle: "Easily see population, income, and traffic counts.",
    description: [
      "Interactive maps give you the ability to see the most recent population numbers, median income, traffic counts, and points of interest.",
    ],
  },
  // Add more items here as per your requirement
];

export default InTouch;
