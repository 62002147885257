import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as selectors from "../../../Reducers/selectors";
import "../../../css/listingView.scss";
import { Link, withRouter } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { S3_BASE } from "../../../Constants";

const lightBg = "rgb(31,33,48)";

class ListingItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listings: [],
      startIndex: 0,
      limit: 20,
    };
  }

  getUrl = () => {
    let url = "/listing/" + this.props.listing.listingId;
    return url;
  };

  getMapUrl = () => {
    let address = encodeURI(
      JSON.stringify(this.props.listing.location.formatted)
    );
    let business_type = "none";
    let url = "/" + address + "/" + business_type;
    return url;
  };

  navigateToListing = () => {
    this.props.history.push(this.getUrl());
  };

  render() {
    const { listing } = this.props;
    console.log("Listing: ", listing);
    console.log("Listing.location: ", listing?.location);
    console.log("Listing.location.formatted: ", listing?.location?.formatted);

    if (
      !listing ||
      typeof listing.location === "undefined" ||
      typeof listing.location.formatted === "undefined"
    ) {
      return <div>Loading...</div>; // or any other fallback UI
    }
    return (
      <div
        className="listingItem"
        style={{
          backgroundColor: lightBg,
          padding: "1em",
          width: "100%",
          height: "200px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "175px",
            height: "150px",
            backgroundColor: "rgba(1,1,1,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            onClick={this.navigateToListing}
            className="listingCard"
            src={S3_BASE + listing.photos?.cover_photos[0]}
            style={{ width: "100%", height: "100%" }}
            fluid
          />
        </div>

        <div
          style={{
            height: "100%",
            width: "66%",
            color: "white",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: "0 5em 0 5em",
            flexDirection: "column",
          }}
        >
          <Link
            style={{
              textDecorationLine: "underline",
              color: "white",
              fontSize: "18px",
              fontWeight: "bold",
            }}
            to={this.getUrl}
          >
            <span style={{ fontSize: "20px", textAlign: "left" }}>
              {listing.location.formatted}
            </span>
          </Link>

          <br></br>
          {listing.pricingInfo && listing.pricingInfo.askingPrice && (
            <p style={{ fontWeight: "bold" }}>
              ${listing.pricingInfo.askingPrice}
            </p>
          )}
        </div>
        {/* Additional div elements or content as needed */}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectors.addressSelector,
  selectors.userSelector,
  (address, user) => ({
    address,
    user,
  })
);

export default withRouter(connect(mapStateToProps)(ListingItem));
