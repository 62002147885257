import React from "react";
import ReactPlayer from "react-player/youtube";
import { Box, Typography, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Define a custom theme to use sophisticated typography and larger containers
const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins", // This can be replaced with any font you prefer
      "sans-serif",
    ].join(","),
    h4: {
      fontWeight: 600,
      fontSize: "2.2rem", // Slightly larger for more emphasis
      letterSpacing: "0.1rem",
    },
    body1: {
      fontSize: "1.25rem",
      color: "#333",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingTop: "2rem",
          paddingBottom: "2rem",
          maxWidth: "lg", // Ensuring a larger container for a bigger video
        },
      },
    },
  },
});

function UseMapPage() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" className="mt-10 mb-10">
        {" "}
        {/* Use a larger maxWidth from MUI */}
        <Typography
          variant="h4"
          gutterBottom
          className="text-center mb-6 text-blue-800"
        >
          Learn More About Mapmax Map Page
        </Typography>
        <Box className="flex justify-center items-center mb-4">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=I_3xcE2o4Y4"
            controls={true}
            width="100%"
            height="500px" // Adjusted height for a larger video display
            style={{ maxWidth: "100%", maxHeight: "500px" }} // Adjust to control height appropriately
            className="shadow-2xl rounded-lg" // Enhanced shadow for visual depth
          />
        </Box>
        <Typography variant="body1" className="text-center mt-4">
          Watch our detailed video to get an in-depth understanding of what
          Mapmax can do for you and how to make the most of our map page
          features.
        </Typography>
      </Container>
    </ThemeProvider>
  );
}

export default UseMapPage;
