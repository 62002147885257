import React, { useState } from "react";
import axios from "axios";
import { API } from "../../Constants"; // Adjust the path as necessary
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ApartmentIcon from "@mui/icons-material/Apartment";
import backgroundImage from "../../images/backgrounds/NYCaerial.jpeg"; // Update with the correct path
import MuiAlert from "@mui/material/Alert";

const StyledPaper = styled(Paper)({
  padding: "2em",
  margin: "2em auto",
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  maxWidth: "800px",
  backdropFilter: "blur(10px)",
});

const BackgroundContainer = styled("div")({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: "2em 0",
});

const StyledButton = styled(Button)({
  marginTop: "1em",
  backgroundColor: "#29b6f6",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#039be5",
  },
});

const StyledTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#29b6f6",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#29b6f6",
    },
  },
});

const StyledIcon = styled(ApartmentIcon)({
  fontSize: "2rem",
  marginRight: "10px",
  color: "#29b6f6",
});

const StyledTypography = styled(Typography)({
  color: "#455a64",
  fontWeight: "bold",
  textAlign: "center",
});

// MUI v5 Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(API + `api/contact/submit`, formData);

      setOpenSnackbar(true); // Open snackbar on success
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
      }); // Reset form data
    } catch (error) {
      console.error("Error submitting form: ", error.response);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <BackgroundContainer>
      <StyledPaper elevation={5}>
        <StyledTypography variant="h5" gutterBottom>
          <StyledIcon /> Inquiries for New York City, Long Island, and New
          Jersey
        </StyledTypography>

        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Example for First Name field */}
            <Grid item xs={12} sm={6}>
              <StyledTextField
                name="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                value={formData.firstName}
              />
            </Grid>
            {/* Add other fields following the same pattern */}
            <Grid item xs={12} sm={6}>
              <StyledTextField
                name="lastName"
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                value={formData.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                name="email"
                label="Email Address"
                type="email"
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                value={formData.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                fullWidth
                required
                onChange={handleChange}
                value={formData.phoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="message"
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.message}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton variant="contained" type="submit">
                Send Inquiry
              </StyledButton>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            We'll get back to you shortly.
          </Alert>
        </Snackbar>
      </StyledPaper>
    </BackgroundContainer>
  );
};

export default ContactForm;
