import React from "react";
import {
  Container,
  Typography,
  Box,
  Link,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function PrivacyPolicy() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const responsivePaperStyle = {
    padding: isMobile ? 2 : 3, // less padding on mobile
    marginTop: isMobile ? 2 : 4, // less top margin on mobile
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={responsivePaperStyle}>
        <Typography variant="h4" gutterBottom>
          Mapmax User Privacy Statement
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome to Mapmax, LLC. We are committed to protecting and respecting
          the privacy of our users. This User Privacy Statement
          (&quot;Statement&quot;) explains how Mapmax, LLC collects, uses,
          shares, and protects your personal information when you use our
          services. Please take a moment to read this statement to familiarize
          yourself with our privacy practices. Within the contents of this
          agreement Mapmax, LLC will hereafter be referred to simply as
          “Mapmax”.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Collection of your Personal Information
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. Please keep in mind that if you directly
          disclose personally identifiable information or personally sensitive
          data this information may be collected and used by others. However,
          you may be required to provide certain personal information to us when
          you elect to use certain products or services available on the Mapmax
          platform. These may include, but are not limited to: (a) sending us an
          email message and/or providing user feedback. We will use your
          information for, but not limited to, communicating with you in
          relation to services information and/or products you have requested
          from us and/or to answer questions to help you improve your user
          experience on our platform. We also may gather additional personal or
          non-personal information in the future.
        </Typography>

        <Box sx={{ marginTop: 2 }}>
          {/* Terms content here */}
          <Typography variant="body1" paragraph>
            <strong> Information We Collect</strong>
          </Typography>
          {/* Repeat for other points */}
          <ol>
            <li>
              <Typography variant="body1">
                <strong>Automatically Collected Information:</strong> We
                automatically collect certain information about your device and
                your use of our platform, such as your IP address, browser type,
                device type, and the pages you view on our platform.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Personal Information:</strong>When you use our services,
                you may provide us with personal information, such as your name,
                email address, phone number, and any other information you
                choose to provide.
              </Typography>
            </li>
            {/* Add more list items as needed */}
            <li>
              <Typography variant="body1">
                <strong>Feedback and Communications:</strong>When you
                communicate with us or provide feedback.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          {/* Terms content here */}
          <Typography variant="body1" paragraph>
            <strong> Tracking User Behavior</strong>
          </Typography>

          <Typography variant="body1">
            Mapmax may keep track of the websites and pages our users visit to
            determine what services are the most popular and benefit our users
            the most. This data is used to deliver improved content to users and
            customers whose behavior indicates that they are interested in a
            particular subject.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          {/* Terms content here */}
          <Typography variant="body1" paragraph>
            <strong> How We Use Your Information</strong>
          </Typography>
          <ol>
            <li>
              <Typography variant="body1" paragraph>
                <strong>To Provide and Improve our Services: </strong>We use
                your information to show you detailed geographical location
                analysis and improve our platform to better serve our users.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" paragraph>
                <strong>Communication: </strong>To communicate with you about
                updates, to respond to questions and user feedback, promotional
                offers, and other information.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                <strong>Analytics: </strong>To analyze trends and user traffic
                to improve Mapmax information dispatch, mapping analytics and to
                organize relevance of our services for users.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                <strong>Legal Obligations: </strong>To comply with legal
                obligations and protect our users and services in accordance
                with laws governing the state of New Jersey where our
                headquarters are located.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong> Sharing of Your Information</strong>
          </Typography>

          <ol>
            <li>
              <Typography variant="body1" paragraph>
                We may share your information with third party users and
                partners who assist us in operating our platform and provide
                information that our users may be interested in viewing.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                We may also release your information when we believe it is
                appropriate to comply with the law, enforce our site policies,
                or protect ours or others&#39; rights, property, or safety.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong> Your Choices</strong>
          </Typography>

          <ol>
            <li>
              <Typography variant="body1" paragraph>
                You can always choose not to provide personal information,
                although this may prevent you from accessing certain features.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                If you have an account with us, you can log in to view or change
                your personal information.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                You can unsubscribe from our promotional emails through the link
                provided in the emails.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong> Use of Cookies</strong>
          </Typography>

          <ol>
            <li>
              <Typography variant="body1" paragraph>
                Mapmax may use “cookies” to help you personalize your online
                experience. A cookie is a text file that is placed on your hard
                disk by a web page server. Cookies cannot be used to run
                programs or deliver viruses to your computer. Cookies are
                uniquely assigned to you and can only be read by a web server in
                the domain that issued the cookie to you.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                One of the primary purposes of cookies is to provide a
                convenience feature to save you time. The purpose of a cookie is
                to tell the Web server that you have returned to a specific
                page. This simplifies the process of recording your information,
                location of searches, contact information and so on. When you
                return to the same page, the information you previously provided
                can be retrieved, so you can easily use and update.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                You can accept or decline cookies. Most Web browsers
                automatically accept cookies, but you can usually modify your
                browser setting to decline cookies if you prefer. If you choose
                to decline cookies, you may not be able to fully experience the
                interactive features of Mapmax services or websites you visit.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Security</strong>
          </Typography>

          <ol>
            <li>
              <Typography variant="body1" paragraph>
                We use industry-standard security measures to protect the
                personal information you provide to us.
              </Typography>
            </li>

            <li>
              <Typography variant="body1" paragraph>
                However, no method of transmission or storage is entirely
                secure, and while we strive to protect your personal
                information, we cannot guarantee its absolute security.
              </Typography>
            </li>
          </ol>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Links to Other Websites</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            Our platform may contain links to other websites. We are not
            responsible for the privacy practices or content of these other
            sites.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Children’s Privacy</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            Our platform is not intended for users under the age of 18. We do
            not knowingly collect personal information from children under 18.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Changes to This Statement</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            We may update this Privacy Statement from time to time. We will
            notify you of any changes by posting the updated statement on our
            platform and updating the &quot;Effective Date&quot; above.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">
            <strong>Contact Us:</strong> If you have any questions about this
            Privacy Statement or our privacy practices, please contact us at:
            <Link href="mailto:ithelp.mapmax@gmail.com">
              {" "}
              ithelp.mapmax@gmail.com
            </Link>
            ,<Link href="mailto:mapmax360@gmail.com"> mapmax360@gmail.com</Link>
            .
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          By using our Site, you consent to the practices described in this
          Privacy Statement. We wish you a productive and rewarding user
          experience using our real estate geographical and informational
          services.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            display: "flex",
            fontSize: "1.5rem",
            justifyContent: "center",
            alignItems: "center", // Use this if you also want vertical centering
            // Adjust the height as needed for vertical centering
          }}
        >
          Thank you for using Mapmax!
        </Typography>
      </Paper>
    </Container>
  );
}

export default PrivacyPolicy;
