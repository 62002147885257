// import React from "react";
// import {
//   Container,
//   Typography,
//   Grid,
//   Card,
//   CardContent,
//   CardMedia,
//   Box,
// } from "@mui/material";

// const ResidentialRetonePage = () => {
//   return (
//     <Container sx={{ minHeight: "70vh" }}>
//       {" "}
//       <Box py={3} textAlign="center">
//         <Typography
//           variant="h4"
//           gutterBottom
//           sx={{ mb: 2, fontWeight: "bold" }}
//         >
//           Residents - RETONE
//         </Typography>
//       </Box>{" "}
//       <Typography paragraph>
//         When the global Covid pandemic hit in 2020 it created extensive movement
//         among residential home owners and renters. Mapmax realized a need to
//         provide geographical information about potential new home locations to
//         individuals and families as well. Community is key, so Mapmax designed a
//         proprietary software called RETONE to illustrate neighborhood
//         demographics, services, and transportation. RETONE exhibits close- by
//         needed resources like doctors, dentists, and veterinarians, as well as
//         retail establishments like supermarkets, pharmacies, and restaurants.
//         There are also points of interest available for viewing like schools,
//         places of worship, and recreational facilities such as parks and gyms.
//       </Typography>
//       <Grid container spacing={4}>
//         {/* Additional Information */}
//         <Grid item xs={12}>
//           <Typography paragraph>
// Most importantly, RETONE recognizes a demographic community
// comprised of the diversity and shared aspirations of its people.
// RETONE demographics can help a family with young children find a
// community where there are other young children in the neighborhood
// for their kids to befriend. It can help a young professional person
// discover a neighborhood where other single young professionals live.
// Study the RETONE overlay to better understand the diversity and
// strength of your community and to fully avail yourself of all your
// new home has to offer.
//           </Typography>
//         </Grid>
//       </Grid>

//     </Container>
//   );
// };

// export default ResidentialRetonePage;

import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
} from "@mui/material";

const ResidentialRetonePage = () => {
  return (
    <Container sx={{ minHeight: "70vh" }}>
      <Box py={3} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Residents - RETONE
        </Typography>
      </Box>
      <Typography paragraph>
        When the global Covid pandemic hit in 2020 it created extensive movement
        among residential home owners and renters. Mapmax realized a need to
        provide geographical information about potential new home locations to
        individuals and families as well. Community is key, so Mapmax designed a
        proprietary software called RETONE to illustrate neighborhood
        demographics, services, and transportation. RETONE exhibits close- by
        needed resources like doctors, dentists, and veterinarians, as well as
        retail establishments like supermarkets, pharmacies, and restaurants.
        There are also points of interest available for viewing like schools,
        places of worship, and recreational facilities such as parks and gyms.
      </Typography>
      {/* Additional Information */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography paragraph>
            Most importantly, RETONE recognizes a demographic community
            comprised of the diversity and shared aspirations of its people.
            RETONE demographics can help a family with young children find a
            community where there are other young children in the neighborhood
            for their kids to befriend. It can help a young professional person
            discover a neighborhood where other single young professionals live.
            Study the RETONE overlay to better understand the diversity and
            strength of your community and to fully avail yourself of all your
            new home has to offer.
          </Typography>
        </Grid>
      </Grid>
      {/* Images Row */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={4}>
          <Card sx={{ borderRadius: "0px" }}>
            <CardMedia
              component="img"
              image="/resident1.jpg" // Replace with your image path
              alt="First Image"
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ borderRadius: "0px" }}>
            <CardMedia
              component="img"
              image="/resident2.jpg" // Replace with your image path
              alt="Second Image"
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ borderRadius: "0px" }}>
            <CardMedia
              component="img"
              image="/resident3.jpg" // Replace with your image path
              alt="Third Image"
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResidentialRetonePage;
