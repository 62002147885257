import React from "react";
import { Button } from "@mui/material";
import AutoCompleteBar from "../../AutoCompleteBar"; // Adjust path as necessary
import { connect } from "react-redux";
import { createSelector } from "reselect";
import * as selectors from "../../../Reducers/selectors";

class SelectResidentialLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null,
      error: false,
      errorMessage: "",
      loading: false,
    };
  }

  selectAddress = async (address) => {
    this.setState({ address: address, loading: false });

    // Assuming page1Data is an object with an 'address' property
    const page1Data = { address: address };

    // Set page1Data in the component's state
    this.setState({ page1Data });

    this.props.onAddressSelect(address); // Notify the parent component about the selection
    this.props.goToNextPage(); // Trigger the navigation to the next page
  };

  render() {
    return (
      <div className="selectLocationPage">
        <h1>Select Location</h1>
        <AutoCompleteBar
          addressFunction={this.selectAddress}
          // Pass selectedAddress to AutoCompleteBar
          selectedAddress={this.state.address}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectors.addressSelector,
  selectors.userSelector,
  (address, user) => ({
    address,
    user,
  })
);

export default connect(mapStateToProps)(SelectResidentialLocation);
