import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Box,
} from "@mui/material";

const RetonePage = () => {
  return (
    <Container sx={{ minHeight: "70vh" }}>
      <Box py={3} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          RETONE Analytics
        </Typography>
      </Box>{" "}
      {/* Adjusted min height here */}
      <Typography paragraph>
        When accessing a potential location’s viability look at the RETONE
        demographics. Study the maps and satellite images with the RETONE
        overlay carefully.
      </Typography>
      <Grid container spacing={4}>
        {/* Additional Information */}
        <Grid item xs={12}>
          <Typography paragraph>
            RETONE is represented by the shaded overlay that appears when
            selected on Mapmax’s map page. RETONE represents the community
            surrounding any typed in address. When accessing a potential
            location’s viability look at the demographics, maps routes and
            satellite images within the RETONE overlay carefully. This outlines
            a comprehensive look into the customer base encircling your real
            estate location. It takes into consideration the way potential
            customers travel, shop and “live” within their community. It also
            has a feature that lists existing business with pins within the
            RETONE. These establishments may generate business to your location
            or conversely compete for client revenue. It is critical and
            advantageous to study the RETONE of any proposed new location
            carefully.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {/* Feature Cards */}
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                height="350"
                image="NewJersey.png"
                alt="Interactive Map"
              />
            </CardContent>
          </Card>
        </Grid>
        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                height="350"
                objectFit="contain"
                image="Brooklyn.png"
                alt="Data Analysis"
              />
              <Typography gutterBottom variant="h5"></Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <CardMedia
                component="img"
                height="350"
                image="LongIsland.png"
                alt="Nearby Business"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RetonePage;
