import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Paper,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../../css/App.scss";
import InfoIcon from "@mui/icons-material/Info";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";

import { updateBusinessType } from "../../Actions/business-actions";
import { updateReady } from "../../Actions/ready-actions";
import { updateIsCity } from "../../Actions/isCity-actions";
import { updateDataRange } from "../../Actions/dataRange-actions";
import { updateGeoUnit } from "../../Actions/geoUnit-actions";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import AutoCompleteBar from "../AutoCompleteBar";
import * as selectors from "../../Reducers/selectors";
import { updateAddress } from "../../Actions/address-actions";
import { withRouter } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getLocation, createLocation } from "../../Requests/locations-requests";
import Autocomplete from "@mui/material/Autocomplete";

const ZIP = "zip code tabulation area";
const theme = createTheme({
  typography: {
    fontFamily: ["Clarkson", "Helvetica", "sans-serif"].join(","),
  },
  // ... other theme customizations
});

class LookUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.onFormChange = this.onFormChange.bind(this);
    this.onBusinessFormChange = this.onBusinessFormChange.bind(this);
    this.onUpdateBusinessType = this.onUpdateBusinessType.bind(this);
    this.onUpdateReady = this.onUpdateReady.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onUpdateIsCity = this.onUpdateIsCity.bind(this);
    this.onPriceFormChange = this.onPriceFormChange.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);
    this.getAddress = this.getAddress.bind(this);

    this.state = {
      address: undefined,
      priceLevel: 1,
      business_type: "none",
      distance: "Driving",
      geoUnit: this.props.geo_unit,
      showModal: false,
      recentSearchs: new Map(),
      errorMessage: "",
      error: false,
    };
  }

  async componentDidMount() {
    if (this.props.urlParams) {
      let type = this.props.urlParams.business_type;
      if (type == "lodging") type = "hotels /lodging";

      let business = {
        type: "none", //this.props.urlParams.business_type,
        priceLevel: this.state.priceLevel,
      };
      let promises = [
        this.loadAddress(JSON.parse(this.props.urlParams.address)),
        this.onUpdateBusinessType(business),
        this.setState({
          business_type: this.props.urlParams.business_type.replace(/_/g, " "),
        }),
      ];

      if (this.props.urlParams.distance.toLowerCase() == "driving") {
        promises.push(this.onUpdateIsCity(false));
        promises.push(this.setState({ distance: "Driving" }));
      } else {
        promises.push(this.onUpdateIsCity(true));
        promises.push(this.setState({ distance: "Walking" }));
      }

      let res = await Promise.all(promises);
      await this.onUpdateAddress();
      this.onUpdateReady(true);
    }
  }

  loadAddress = async (address) => {
    await this.setState({ error: false });

    return new Promise(async (resolve, reject) => {
      let zip,
        number,
        street,
        city,
        state = "";
      let place,
        geoCode = null;

      try {
        const results = await geocodeByAddress(address);
        results[0].address_components.forEach((c) => {
          c.types.forEach((type) => {
            if (type === "street_number") number = c.long_name;
            if (type === "route") street = c.short_name;
            if (type === "locality") city = c.long_name;
            if (type === "administrative_area_level_1") state = c.short_name;
            if (type === "postal_code") zip = c.long_name;
          });
        });

        if (number === undefined) number = "";
        if (street === undefined) street = "";
        place = results[0];

        geoCode = await getLatLng(results[0]);
        if (
          typeof geoCode.lat !== "number" ||
          typeof geoCode.lng !== "number"
        ) {
          throw new Error("Invalid latitude or longitude values");
        }
      } catch (error) {
        console.error("Error in loadAddress: ", error);
        this.setState({
          error: true,
          errorMessage: "Failed to retrieve location data: " + error.message,
        });
        reject(error);
        return;
      }

      let addressState = {
        formatted: address,
        street: number + " " + street,
        city: city,
        state: state,
        zip: zip,
        coords: geoCode,
        place: place,
      };

      // handle no zip
      if (addressState.zip == undefined) {
        this.setState({
          error: true,
          errorMessage: "Address is too general, please try another.",
        });
        return;
      }

      // handle no street
      if (addressState.street == " ") {
        let firstPart = address.substring(0, address.indexOf(","));
        let alreadyInAddress = false;

        Object.entries(addressState).forEach(([label, value]) => {
          if (value == firstPart) {
            alreadyInAddress = true;
          }
        });
        if (!alreadyInAddress) addressState.street = firstPart;
      }

      // handle no city
      if (addressState.city == undefined) {
        let secondPart = address.substring(
          address.indexOf(",") + 1,
          address.length
        );
        secondPart = secondPart.substring(0, secondPart.indexOf(","));
        let alreadyInAddress2 = false;

        Object.entries(addressState).forEach(([label, value]) => {
          if (value == secondPart) {
            alreadyInAddress2 = true;
          }
        });
        if (!alreadyInAddress2) addressState.city = secondPart;
      }

      await this.setState({ address: addressState });
      resolve(addressState);
    });
  };

  onUpdateBusinessType(business_type) {
    this.props.onUpdateBusinessType(business_type);
  }

  onUpdateIsCity(isCity) {
    this.props.onUpdateIsCity(isCity);
  }
  onFormChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onPriceFormChange(event) {
    let value = event.target.value.substring(0, 1);
    this.setState({ [event.target.name]: value });
  }

  onBusinessFormChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onUpdateReady(isReady) {
    this.props.onUpdateReady(isReady);
  }

  async onHandleSubmit() {
    console.log("Submitting form with address: ", this.state.address);
    if (this.state.address == undefined) return;

    await this.onUpdateAddress(this.state.address);
    let type = this.state.business_type;
    if (type == "lodging") type = "lodging";
    let business = {
      type: type.replace(/ /g, "_"),
      priceLevel: this.state.priceLevel,
    };
    this.onUpdateBusinessType(business);
    this.onUpdateGeoUnit(this.state.geoUnit);

    if (this.props.address.toString().length > 0) {
      if (this.props.ready) {
        await this.onUpdateReady(false);
        await this.onUpdateReady(true);
      } else {
        await this.onUpdateReady(true);
      }

      // reset data_range to zip
      if (this.props.data_range != ZIP) {
        await this.props.onUpdateDataRange(ZIP);
      }
      // recent searches
      this.checkLocalStorage(this.props.address);

      let address = encodeURI(JSON.stringify(this.props.address.formatted));
      let business_type = encodeURI(this.props.business_type.type);
      let url = "/" + address + "/" + business_type;
      this.props.history.push(url);
    } else {
      this.onUpdateReady(false);
    }
  }

  onUpdateAddress = async () => {
    let address = this.state.address;
    // check for location from db
    let results = await getLocation(address.place.place_id);
    address.isNewEntry = true;
    await this.props.onUpdateAddress(address);
    let countyRes = await this.props.onUpdateAddress(address);
  };

  checkLocalStorage = (location) => {
    // if signed in push to recent searches
    if (this.props.user_id != -1) {
      let searches = JSON.parse(localStorage.getItem("recentSearches"));

      if (searches == null) searches = new Map();
      else {
        // keep last 25
        if (searches.length > 25)
          searches = searches.slice(searches.length - 25, searches.length);
        searches = new Map(searches);
      }

      searches.set(location.place.place_id, {
        location: location,
        business_type: this.props.business_type,
        date: new Date(),
      });

      localStorage.setItem(
        "recentSearches",
        JSON.stringify(Array.from(searches.entries()))
      );
    }
  };

  getAddress = async (address) => {
    let addressState = await this.loadAddress(address);
    this.setState({ address: addressState }, () => {
      // Log to check if this part is reached
      console.log("Address selected: ", this.state.address);
      this.onHandleSubmit();
    });
  };

  setModalShow = (flag) => {
    this.setState({ showModal: flag });
  };

  onUpdateDataRange = (range) => {
    this.props.onUpdateDataRange(range);
  };

  onUpdateGeoUnit = (geo_unit) => {
    this.props.onUpdateGeoUnit(geo_unit);
  };

  onHandleAddressWarning = () => {
    this.setState({
      error: true,
      errorMessage:
        "Please begin typing your address and make sure to select it from the drop down menu.",
    });
  };

  render() {
    const containerStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minHeight: "90vh",
      textAlign: "center",
      gap: "2",
      padding: "20px",
      backgroundImage: "url('/NYC.jpg')",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundColor: "#f5f5f5",
      color: "#333",
      "@media (max-width:600px)": {
        // Media query for mobile devices
        padding: "10px",
        minHeight: "90vh",
        // marginBottom:"10px"
        // textAlign: "justify",
      },
      "@media (min-width:600px) and (max-width:1024px)": {
        // Media query for tablets
        minHeight: "85vh",
      },
    };

    const appBarStyle = {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "white",
    };

    const headerStyle = {
      fontWeight: "bold",
      color: "#ffffff",
      "@media (max-width:600px)": {
        // Mobile devices
        fontSize: "1rem", // Smaller text on mobile
      },
    };
    const searchBoxStyle = {
      width: "100%",
      maxWidth: "700px",
      marginTop: "20px",
      "@media (max-width:600px)": {
        // Mobile devices
        maxWidth: "100%", // Use full width on smaller screens
      },
    };
    const buttonStyle = {
      marginTop: "20px",
      padding: "10px 30px",
      fontSize: "18px",
      textTransform: "none", // Avoid uppercase text transformation for a more formal look
      borderRadius: "8px",
      backgroundColor: "#007BFF", // Consider using a brand color
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#0056b3", // Darken the button on hover for feedback
      },
    };
    const disclaimerStyle = {
      display: "flex",
      backgroundColor: "#FFFFFF",
      opacity: "0.75",
      gap: "20px",
      justifyContent: "center",
      alignItems: "center",
      padding: "15px 10px",
      maxWidth: "900px", // Adjust based on your preference
    };
    const rowStyle = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      maxWidth: 600, // Adjust based on your preference
      mb: 2, // Margin bottom for spacing
    };
    const additionalTextStyle = {
      margin: "20px 0",
      color: "white", // Making sure it's visible on the background
      maxWidth: "100%",
      // Keeping it aligned with the search box width
    };

    return (
      <ThemeProvider theme={theme}>
        <Box sx={containerStyle}>
          <Typography
            variant="h3"
            style={{
              // fontWeight: "bold",
              position: "relative",
              maxWidth: "100%",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Real Estate Consultation For All
          </Typography>
          <Typography
            variant="h6"
            style={{
              // fontWeight: "bold",
              position: "relative",
              maxWidth: "75%",
              color: "white",
            }}
          >
            Mapmax provides real estate analysis for commercial and residential
            properties, supplying and analyzing relevant information, using a
            proprietary algorithm called “RETONE” specifically created to help
            users select their ideal location.
          </Typography>

          <Box sx={searchBoxStyle}>
            <AutoCompleteBar
              urlParams={this.props.urlParams}
              addressFunction={this.getAddress}
            />
          </Box>
          <Typography variant="h6" style={additionalTextStyle}>
            Current analysis available for all addresses in New York City's 5
            boroughs, Long Island, NY and New Jersey, USA.
          </Typography>

          {/* [Modal and other components remain unchanged] */}
        </Box>
      </ThemeProvider>
    );
  }
}

const InfoButton = (props) => {
  const [clicked, setClicked] = useState(false);

  return (
    <InfoIcon onClick={() => [setClicked(!clicked), props.clickFunction()]} />
  );
};

const mapStateToProps = createSelector(
  selectors.userSelector,
  selectors.businessTypeSelector,
  selectors.readySelector,
  selectors.addressSelector,
  selectors.isCitySelector,
  selectors.tradeZoneBoundsSelector,
  selectors.dataRangeSelector,
  selectors.geoUnitSelector,
  (
    user,
    business_type,
    ready,
    address,
    isCity,
    tradeZone_bounds,
    data_range,
    geo_unit
  ) => ({
    user,
    business_type,
    ready,
    address,
    isCity,
    tradeZone_bounds,
    data_range,
    geo_unit,
  })
);

const mapActionsToProps = {
  onUpdateBusinessType: updateBusinessType,
  onUpdateReady: updateReady,
  onUpdateIsCity: updateIsCity,
  onUpdateAddress: updateAddress,
  onUpdateDataRange: updateDataRange,
  onUpdateGeoUnit: updateGeoUnit,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(LookUpForm)
);
