// import React from "react";
// import { Box, Grid, Typography, CardMedia, styled } from "@mui/material";

// // Defining a color scheme and typography that suits a professional real estate page
// const theme = {
//   palette: {
//     primary: {
//       main: "#123152",
//     },
//     secondary: {
//       main: "#f50057",
//     },
//     background: {
//       default: "#f4f5f7",
//     },
//   },
//   typography: {
//     fontFamily: "Arial, sans-serif",
//     h2: {
//       fontSize: "2.2rem",
//       fontWeight: 700,
//       color: "#123152",
//     },
//     h3: {
//       fontSize: "1.5rem",
//       fontWeight: 500,
//       color: "#123152",
//     },
//   },
// };

// // Styled components
// const SectionBox = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(4, 2),
//   background: theme.palette.background.default,
//   borderRadius: theme.shape.borderRadius,
// }));

// const CtaItem = styled(Box)({
//   textDecoration: "none",
//   margin: "16px 0",
//   borderRadius: "8px",
//   overflow: "hidden",
// });

// const ImageMedia = styled(CardMedia)({
//   height: 300,
//   borderRadius: "8px",
// });

// export default function PropertyReviewPage() {
//   return (
//     <Box
//       sx={{
//         mx: "auto",
//         p: 3,
//         bgcolor: theme.palette.background.default,
//       }}
//     >
//       <Typography
//         variant="h3"
//         sx={{
//           mb: 7,
//           color: theme.palette.primary.main,
//           textAlign: "center",
//           fontWeight: "bold",
//         }}
//       >
//         Commercial Review
//       </Typography>

//       {/* Grid container for 2x3 layout */}
//       <Grid container spacing={4}>
//         {sections.map((section, index) => (
//           <Grid item xs={12} md={4} key={index}>
//             <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
//               {section.title}
//             </Typography>
//             {section.subSections
//               ? section.subSections.map((subSection, subIndex) => (
//                   <div key={subIndex}>
//                     <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
//                       {subSection.subTitle}
//                     </Typography>
//                     {subSection.descriptions.map((description, descIndex) => (
//                       <Typography key={descIndex} sx={{ marginBottom: "40px" }}>
//                         {description}
//                       </Typography>
//                     ))}
//                   </div>
//                 ))
//               : // This is for backward compatibility if some sections don't have subSections
//                 section.descriptions?.map((description, descIndex) => (
//                   <Typography key={descIndex} sx={{ marginBottom: "20px" }}>
//                     {description}
//                   </Typography>
//                 ))}
//           </Grid>
//         ))}
//       </Grid>

//       {/* Summary Section */}
//       {/* <Box sx={{ mt: 4 }}>
//         <Typography variant="h4" component="h4" sx={{ mb: 2 }}>
//           Summary
//         </Typography>
//         <Typography>
//           In order for your location to be successful, you will want good
//           visibility, big and bright signage, easy accessibility, and strong
//           demographics that support your business concept. It is a big advantage
//           to make a realistic review of your generators- businesses who may
//           bring customers to your establishment. Equally important is the
//           evaluation of your competitors- businesses who will complete for
//           client revenue. With these tools and market intelligence, you can
//           structure your business for maximum success.
//         </Typography>
//       </Box> */}
//     </Box>
//   );
// }

// // Data for the sections
// const sections = [
//   // {
//   //   title: "Good Visibility",
//   //   descriptions: [
//   //     "Your location should be easy to see and effortless for customers to find.",
//   //   ],

//   // },
//   {
//     title: "Good Visibility",
//     subSections: [
//       // Introducing a subSections array to hold multiple titles and descriptions
//       {
//         subTitle: "",
//         descriptions: [
//           "Your location should be easy to see and effortless for customers to find.",
//         ],
//       },
//       {
//         subTitle: "Bright and Big Signage",
//         descriptions: [
//           "A prominent sign will show customers you are here and ready to do business.",
//         ],
//       },
//       // You can add more sub-titles and descriptions here as needed
//     ],
//   },

//   // {
//   //   title: "Bright and Big Signage",
//   //   descriptions: [
//   //     "A prominent sign will show customers you are here and ready to do business.",
//   //   ],
//   // },
//   {
//     title: "Easy Accessibility",
//     descriptions: [
//       "The location should be easy to get to/from roadways, streets and/or mass transit.",
//       "For suburban drive live/work/shop areas, take into consideration lights by roadways, turns available into your location and curb cuts for easy access.",
//       "For city pedestrian traffic, proximity to mass transit and major street/avenue intersections are an advantage.",
//     ],
//   },

//   {
//     title: "Strong Demographics",
//     descriptions: [
//       "“RETONE” is a proprietary software outlining the community’s primary shopping district. It is designated in gray overlay on the mapping page when selected. RETONE digitally computes travel time and distance to establish an area from which the majority of retail customers are most likely to come. In order for a small business to be successful, RETONE should reveal demographics of a customer base likely to buy your product. For example, if you are selling baby products, you will want a large population of new families with babies in your RETONE.",
//     ],
//   },

//   {
//     title: "Study Generators",
//     descriptions: [
//       "Generators are neighboring establishments and points of interest that are likely to generate customers into your store. For example, if you are opening a coffee shop in NYC, locating by a subway station with thousands of people passing by on their way to work- as indicated by high weekday morning turnstiles counts- will be an advantage for selling coffee.",
//       "Additionally, the demographics of people who live and work in the RETONE have the highest potential to become customers throughout the day and weekend.",
//     ],
//   },

//   {
//     title: "Evaluate Competitors",
//     descriptions: [
//       "These are businesses within your location's RETONE that will compete for your customer’s dollars. In order for your operation to outperform other similar types of businesses in your shopping district, your location should have higher visibility, and be more easily accessible than the neighboring competition. Your product should also be of better quality and sold at competitive price point. It pays to study your competition carefully.",
//     ],
//   },

//   {
//     title: "Summary",
//     descriptions: [
//       "In order for your location to be successful, you will want good visibility, big and bright signage, easy accessibility, and strong demographics that support your business concept. It is a big advantage to make a realistic review of your generators- businesses who may bring customers to your establishment. ",

//       "Equally important is the evaluation of your competitors- businesses who will complete for client revenue. With these tools and market intelligence, you can structure your business for maximum success.",
//     ],
//   },

//   // ... Add other sections here ...
// ];
import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PropertyReviewPage = () => {
  const theme = useTheme(); // Access theme for consistent styling

  // Dummy data for accordion contents - modify the content for "Demographic Insights"
  const accordionData = [
    {
      title: "Good Visibility",
      content:
        "Your location should be easy to see and effortless for customers to find.",
    },

    {
      title: "Bright and Big Signage",
      // Breaking down the content into an array for custom rendering
      content: [
        "A prominent sign will show customers you are here and ready to do business.",
      ],
    },

    {
      title: " Easy Accessibility",
      // Breaking down the content into an array for custom rendering
      content: [
        "The location should be easy to get to/from roadways, streets and/or mass transit.",

        "For suburban drive live/work/shop areas, take into consideration lights by roadways, turns available into your location and curb cuts for easy access.",

        "For city pedestrian traffic, proximity to mass transit and major street/avenue intersections are an advantage.",
      ],
    },

    {
      title: "Strong Demographics",
      // Breaking down the content into an array for custom rendering
      content: [
        "A “RETONE” is a proprietary software outlining the community’s primary shopping district. It is designated in gray overlay on the mapping page when selected. RETONE digitally computes travel time and distance to establish an area from which the majority of retail customers are most likely to come.",
        "In order for a small business to be successful, RETONE should reveal demographics of a customer base likely to buy your product. For example, if you are selling baby products, you will want a large population of new families with babies in your RETONE.",
      ],
    },

    {
      title: "Study Generators",
      // Breaking down the content into an array for custom rendering
      content: [
        "Generators are neighboring establishments and points of interest that are likely to generate customers into your store. For example, if you are opening a coffee shop in NYC, locating by a subway station with thousands of people passing by on their way to work- as indicated by high weekday morning turnstiles counts- will be an advantage for selling coffee.",

        "Additionally, the demographics of people who live and work in the RETONE have the highest potential to become customers throughout the day and weekend.",
      ],
    },

    {
      title: "Evaluate Competitors",
      // Breaking down the content into an array for custom rendering
      content: [
        "These are businesses within your location's RETONE that will compete for your customer’s dollars. In order for your operation to outperform other similar types of businesses in your shopping district, your location should have higher visibility, and be more easily accessible than the neighboring competition. Your product should also be of better quality and sold at competitive price point. It pays to study your competition carefully.",
      ],
    },

    {
      title: "Summary",
      // Breaking down the content into an array for custom rendering
      content: [
        "In order for your location to be successful, you will want good visibility, big and bright signage, easy accessibility, and strong demographics that support your business concept. It is a big advantage to make a realistic review of your generators- businesses who may bring customers to your establishment.",
        "Equally important is the evaluation of your competitors- businesses who will complete for client revenue. With these tools and market intelligence, you can structure your business for maximum success.",
      ],
    },

    // Add the rest of your accordions here...
  ];

  return (
    <Container sx={{ minHeight: "70vh", mt: 4 }}>
      <Box textAlign="center" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Commercial Review
        </Typography>
      </Box>

      {accordionData.map((item, index) => (
        <Accordion key={index} sx={{ mb: 2, boxShadow: 3 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            sx={{
              backgroundColor: theme.palette.action.hover,
              ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(180deg)",
              },
              "&:not(:last-child)": {
                borderBottom: 0,
              },
              "&:before": {
                display: "none",
              },
              fontWeight: "bold",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Checking if content is an array to render it differently */}
            {Array.isArray(item.content) ? (
              <Box>
                {item.content.map((line, lineIndex) => (
                  <Typography
                    key={lineIndex}
                    paragraph={lineIndex < item.content.length - 1}
                  >
                    {line}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography>{item.content}</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default PropertyReviewPage;
