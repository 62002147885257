// import React, { useEffect, useRef, useState } from "react";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import axios from "axios";
// import { connect } from "react-redux";
// import * as selectors from "../../Reducers/selectors"; // Adjust as needed
// import { API } from "../../Constants";
// import {
//   Grid,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";

// const BoroughData = ({ addressFromRedux }) => {
//   const mapRef = useRef(null);
//   const [addressInfo, setAddressInfo] = useState(null);

//   const validateAddress = async (lat, lng) => {
//     try {
//       const response = await axios.get(API + `nyc/api/validate-address`, {
//         params: { latitude: lat, longitude: lng },
//       });
//       setAddressInfo(response.data);
//     } catch (error) {
//       console.error("Error fetching address data:", error);
//       setAddressInfo(null);
//     }
//   };

//   const initMap = (lat, lng) => {
//     if (!mapRef.current._leaflet_id) {
//       const map = L.map(mapRef.current).setView([lat, lng], 17);
//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//         maxZoom: 19,
//         attribution: "© OpenStreetMap contributors",
//       }).addTo(map);

//       if (lat && lng) {
//         L.marker([lat, lng]).addTo(map);
//       }
//     }
//   };

//   useEffect(() => {
//     if (addressFromRedux && addressFromRedux.coords) {
//       const { lat, lng } = addressFromRedux.coords;
//       validateAddress(lat, lng);
//       initMap(lat, lng);
//     }
//   }, [addressFromRedux]);

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12} md={4}>
//         <div id="map" ref={mapRef} style={{ height: "300px" }} />{" "}
//         {/* Map size reduced */}
//       </Grid>
//       <Grid item xs={12} md={8}>
//         {addressInfo && (
//           <TableContainer
//             component={Paper}
//             style={{ backgroundColor: "white" }}
//           >
//             <Table aria-label="address info">
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Field</TableCell>
//                   <TableCell>Value</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {/* Add more rows as needed */}
//                 <TableRow>
//                   <TableCell>Borough</TableCell>
//                   <TableCell>{addressInfo.borough}</TableCell>
//                 </TableRow>
//                 <TableRow>
//                   <TableCell>Address</TableCell>
//                   <TableCell>{addressInfo.address}</TableCell>
//                 </TableRow>
//                 <TableRow>
//                   <TableCell>Lot Area</TableCell>
//                   <TableCell>{addressInfo.lotarea}</TableCell>
//                 </TableRow>
//                 {/* Example additional row */}
//                 <TableRow>
//                   <TableCell>Additional Info</TableCell>
//                   <TableCell>{/* Data Here */}</TableCell>
//                 </TableRow>
//                 {/* Add more rows as needed */}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

// const mapStateToProps = (state) => ({
//   addressFromRedux: selectors.addressSelector(state),
// });

// export default connect(mapStateToProps)(BoroughData);

// import React, { useEffect, useRef, useState } from "react";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import axios from "axios";
// import { connect } from "react-redux";
// import * as selectors from "../../Reducers/selectors";
// import { API } from "../../Constants";
// import { Grid, Typography, Box, Paper, Container } from "@mui/material";
// import { styled } from "@mui/material/styles";

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: "left",
//   color: theme.palette.text.secondary,
//   marginBottom: theme.spacing(2),
// }));

// const BoroughData = ({ addressFromRedux }) => {
//   const mapRef = useRef(null);
//   const [addressInfo, setAddressInfo] = useState(null);

//   const validateAddress = async (lat, lng) => {
//     try {
//       const response = await axios.get(API + `nyc/api/validate-address`, {
//         params: { latitude: lat, longitude: lng },
//       });
//       setAddressInfo(response.data);
//     } catch (error) {
//       console.error("Error fetching address data:", error);
//       setAddressInfo(null);
//     }
//   };

//   const initMap = (lat, lng) => {
//     if (!mapRef.current._leaflet_id) {
//       const map = L.map(mapRef.current).setView([lat, lng], 17);
//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//         maxZoom: 19,
//         attribution: "© OpenStreetMap contributors",
//       }).addTo(map);

//       if (lat && lng) {
//         L.marker([lat, lng]).addTo(map);
//       }
//     }
//   };

//   useEffect(() => {
//     if (addressFromRedux && addressFromRedux.coords) {
//       const { lat, lng } = addressFromRedux.coords;
//       validateAddress(lat, lng);
//       initMap(lat, lng);
//     }
//   }, [addressFromRedux]);

// const formatNoIfNull = (value) => (value ? value : "No");

// const formatArea = (value) => (value ? `${value} sf` : "N/A");

// const formatValue = (value, prefix = "", suffix = "") =>
//   value ? `${prefix}${value}${suffix}` : "N/A";

// const renderInformation = () => (
//   <Container>
//     <Typography variant="h6" gutterBottom>
//       Land Use Information
//     </Typography>
//     <Item>Address: {formatValue(addressInfo?.address)}</Item>
//     <Item>Neighborhood: {formatValue(addressInfo?.cd)}</Item>
//     <Item>Owner: {formatValue(addressInfo?.ownername)}</Item>
//     <Item>Built: {formatValue(addressInfo?.yearbuilt)}</Item>
//     <Item>Landmark: {formatNoIfNull(addressInfo?.landmark)}</Item>
//     <Item>Historic: {formatNoIfNull(addressInfo?.histdist)}</Item>
//     <Item>Building Class: {formatValue(addressInfo?.bldgclass)}</Item>
//     <Item>BBL: {formatValue(addressInfo?.bbl)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Building Information
//     </Typography>
//     <Item># of Buildings: {formatValue(addressInfo?.numbldgs)}</Item>
//     <Item># of Floors: {formatValue(addressInfo?.numfloors)}</Item>
//     <Item>Total Units: {formatValue(addressInfo?.unitstotal)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Area Measurements
//     </Typography>
//     <Item>Lot: {formatArea(addressInfo?.lotarea)}</Item>
//     <Item>Building: {formatArea(addressInfo?.bldgarea)}</Item>
//     <Item>Commercial: {formatArea(addressInfo?.comarea)}</Item>
//     <Item>Office: {formatArea(addressInfo?.officearea)}</Item>
//     <Item>Retail: {formatArea(addressInfo?.retailarea)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Tax Information
//     </Typography>
//     <Item>
//       Assessed Land Value: {formatValue(addressInfo?.assessland, "$")}
//     </Item>
//     <Item>
//       Assessed Total Value: {formatValue(addressInfo?.assesstot, "$")}
//     </Item>
//     <Item>
//       Exempt Total Value: {formatValue(addressInfo?.exempttot, "$")}
//     </Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Frontage
//     </Typography>
//     <Item>Lot Frontage: {formatValue(addressInfo?.lotfront, "", "'")}</Item>
//     <Item>Lot Depth: {formatValue(addressInfo?.lotdepth, "", "'")}</Item>
//     <Item>
//       Building Frontage: {formatValue(addressInfo?.bldgfront, "", "'")}
//     </Item>
//     <Item>
//       Building Depth: {formatValue(addressInfo?.bldgdepth, "", "'")}
//     </Item>
//     <Item>Irregular: {addressInfo?.irrlotcode ? "Yes" : "No"}</Item>
//     <Item>Lot Type: {formatValue(addressInfo?.lottype)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Floor Area Ratios (FAR)
//     </Typography>
//     <Item>Built: {formatValue(addressInfo?.builtfar)}</Item>
//     <Item>Residential Potential: {formatValue(addressInfo?.residfar)}</Item>
//     <Item>Commercial Potential: {formatValue(addressInfo?.commfar)}</Item>
//     <Item>Facility Potential: {formatValue(addressInfo?.facilfar)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Zoning
//     </Typography>
//     <Item>Zoning District 1: {formatValue(addressInfo?.zonedist1)}</Item>
//     <Item>Special District 1: {formatValue(addressInfo?.spdist1)}</Item>

//     <Typography variant="h6" gutterBottom mt={4}>
//       Political Districts & Boundaries
//     </Typography>
//     <Item>Community District: {formatValue(addressInfo?.cd)}</Item>
//     <Item>School District: {formatValue(addressInfo?.schooldist)}</Item>
//     <Item>City Council District: {formatValue(addressInfo?.council)}</Item>
//     <Item>Fire Company: {formatValue(addressInfo?.firecomp)}</Item>
//     <Item>Police Precinct: {formatValue(addressInfo?.policeprct)}</Item>
//     <Item>
//       Health Center District: {formatValue(addressInfo?.healthcenterdistrict)}
//     </Item>
//     <Item>Health Area: {formatValue(addressInfo?.healtharea)}</Item>
//     <Item>
//       Sanitation District Number: {formatValue(addressInfo?.sanitdistrict)}
//     </Item>
//     <Item>Sanitation Subsection: {formatValue(addressInfo?.sanitsub)}</Item>
//   </Container>
// );

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12} md={8}>
//         {addressInfo ? (
//           renderInformation()
//         ) : (
//           <Typography>Loading...</Typography>
//         )}
//       </Grid>
//       <Grid item xs={12} md={4}>
//         <div id="map" ref={mapRef} style={{ height: "300px", width: "100%" }} />
//       </Grid>
//     </Grid>
//   );
// };

// const mapStateToProps = (state) => ({
//   addressFromRedux: selectors.addressSelector(state),
// });

// export default connect(mapStateToProps)(BoroughData);

// import React, { useEffect, useRef, useState } from "react";
// import L from "leaflet";
// import "leaflet/dist/leaflet.css";
// import axios from "axios";
// import { connect } from "react-redux";
// import * as selectors from "../../Reducers/selectors";
// import { API } from "../../Constants";
// import {
//   Grid,
//   Typography,
//   Box,
//   Paper,
//   Container,
//   List,
//   ListItem,
//   ListItemText,
//   Divider,
//   CircularProgress,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";

// const StyledContainer = styled(Container)(({ theme }) => ({
//   backgroundColor: theme.palette.background.default, // Ensures light background
//   padding: theme.spacing(3),
//   borderRadius: theme.shape.borderRadius,
//   boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)", // Soft shadow for depth
// }));

// const InfoBox = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   color: theme.palette.text.primary,
//   margin: theme.spacing(2, 0),
//   padding: theme.spacing(2),
//   borderRadius: theme.shape.borderRadius,
// }));

// const MapContainer = styled("div")(({ theme }) => ({
//   height: "400px",
//   margin: theme.spacing(2, 0),
//   padding: theme.spacing(2),
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: theme.shape.borderRadius,
// }));

// const LoadingContainer = styled("div")(({ theme }) => ({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   minHeight: "100px",
// }));

// const BoroughData = ({ addressFromRedux }) => {
//   const mapRef = useRef(null);
//   const [addressInfo, setAddressInfo] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const validateAddress = async (lat, lng) => {
//     setLoading(true);
//     try {
//       const response = await axios.get(`${API}nyc/api/validate-address`, {
//         params: { latitude: lat, longitude: lng },
//       });
//       setAddressInfo(response.data);
//     } catch (error) {
//       console.error("Error fetching address data:", error);
//       setAddressInfo(null);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const initMap = (lat, lng) => {
//     if (!mapRef.current._leaflet_id) {
//       const map = L.map(mapRef.current, { scrollWheelZoom: false }).setView(
//         [lat, lng],
//         17
//       );
//       L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//         maxZoom: 19,
//         attribution: "© OpenStreetMap contributors",
//       }).addTo(map);

//       if (lat && lng) {
//         L.marker([lat, lng]).addTo(map);
//       }
//     }
//   };

//   useEffect(() => {
//     if (addressFromRedux && addressFromRedux.coords) {
//       const { lat, lng } = addressFromRedux.coords;
//       validateAddress(lat, lng);
//       initMap(lat, lng);
//     }
//   }, [addressFromRedux]);

//   const renderDetails = (title, details) => (
//     <InfoBox>
//       <Typography variant="h6" component="p" sx={{ mb: 2, fontWeight: "bold" }}>
//         {title}
//       </Typography>
//       <List dense>
//         {details.map(([label, value], index) => (
//           <React.Fragment key={label}>
//             <ListItem alignItems="flex-start">
//               <ListItemText
//                 primary={label}
//                 secondary={value !== null ? value : "Loading..."}
//                 primaryTypographyProps={{ fontWeight: "bold" }}
//               />
//             </ListItem>
//             {index < details.length - 1 && (
//               <Divider variant="inset" component="li" />
//             )}
//           </React.Fragment>
//         ))}
//       </List>
//     </InfoBox>
//   );

//   return (
//     <StyledContainer maxWidth="lg">
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={8}>
//           <Box>
//             {renderDetails("Land Use Information", [
//               ["Address", addressInfo ? addressInfo.address : "N/A"],
//               ["Neighborhood", addressInfo ? addressInfo.cd : "N/A"],
//               // Add more details as needed
//             ])}
//             {/* Repeat for other categories */}
//             {loading && (
//               <LoadingContainer>
//                 <CircularProgress />
//               </LoadingContainer>
//             )}
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <MapContainer ref={mapRef} />
//         </Grid>
//       </Grid>
//     </StyledContainer>
//   );
// };

// const mapStateToProps = (state) => ({
//   addressFromRedux: selectors.addressSelector(state),
// });

// export default connect(mapStateToProps)(BoroughData);

import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { connect } from "react-redux";
import * as selectors from "../../Reducers/selectors";
import { API } from "../../Constants";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Container,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
}));

const InfoBox = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const MapContainer = styled("div")(({ theme }) => ({
  height: "400px",
  margin: theme.spacing(2, 0),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const LoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100px",
}));

const BoroughData = ({ addressFromRedux }) => {
  const mapRef = useRef(null);
  const [addressInfo, setAddressInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (addressFromRedux && addressFromRedux.coords) {
        const { lat, lng } = addressFromRedux.coords;
        setLoading(true);
        try {
          const response = await axios.get(`${API}nyc/api/validate-address`, {
            params: { latitude: lat, longitude: lng },
          });
          setAddressInfo(response.data);
        } catch (error) {
          console.error("Error fetching address data:", error);
          setAddressInfo(null);
        } finally {
          setLoading(false);
        }
        initMap(lat, lng);
      }
    };

    fetchData();
  }, [addressFromRedux]);

  const initMap = (lat, lng) => {
    if (!mapRef.current._leaflet_id) {
      const map = L.map(mapRef.current, { scrollWheelZoom: false }).setView(
        [lat, lng],
        17
      );
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution: "© OpenStreetMap contributors",
      }).addTo(map);

      L.marker([lat, lng]).addTo(map);
    }
  };

  const renderDetails = (title, details) => (
    <InfoBox>
      <Typography variant="h6" component="p" sx={{ mb: 2, fontWeight: "bold" }}>
        {title}
      </Typography>
      <List dense>
        {details.map(([label, value], index) => (
          <React.Fragment key={label}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={label}
                secondary={value !== null ? value : "Loading..."}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItem>
            {index < details.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        ))}
      </List>
    </InfoBox>
  );

  // Helper function to format numbers with commas for readability
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Updated to handle numeric values and append units correctly
  const formatValue = (value, prefix = "", suffix = "") => {
    if (!value && value !== 0) return "N/A"; // Check for null or undefined, but allow 0
    // Assume numeric values need formatting with commas
    const formattedValue =
      typeof value === "number" ? numberWithCommas(value) : value;
    return `${prefix}${formattedValue}${suffix}`;
  };

  // Specifically for area measurements, appending "sf" unit
  const formatArea = (value) => {
    if (!value && value !== 0) return "N/A";
    return `${numberWithCommas(value)} sf`;
  };

  const formatNoIfNull = (value) => (value != null ? value : "No");
  const formathyphenIfNull = (value) => (value != null ? value : "-");

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box>
            {renderDetails("Land Use Information", [
              ["Address", formatValue(addressInfo?.address)],
              ["Neighborhood", addressInfo?.cd],
              ["Owner", formatValue(addressInfo?.ownername)],
              ["Built", addressInfo?.yearbuilt],
              ["Landmark", formatNoIfNull(addressInfo?.landmark)],
              ["Historic", formatNoIfNull(addressInfo?.histdist)],
              ["Building Class", formatValue(addressInfo?.bldgclass)],
              ["BBL", formatValue(addressInfo?.bbl)],
            ])}

            {renderDetails("Building Information", [
              ["# of Buildings", formatValue(addressInfo?.numbldgs)],
              ["# of Floors", formatValue(addressInfo?.numfloors)],
              ["Total Units", formatValue(addressInfo?.unitstotal)],
            ])}

            {renderDetails("Area Measurements", [
              ["Lot", formatArea(addressInfo?.lotarea)],
              ["Building", formatArea(addressInfo?.bldgarea)],
              ["Commercial", formatArea(addressInfo?.comarea)],
              ["Office", formatArea(addressInfo?.officearea)],
              ["Retail", formatArea(addressInfo?.retailarea)],
            ])}

            {renderDetails("Tax Information", [
              [
                "Assessed Land Value",
                formatValue(addressInfo?.assessland, "$"),
              ],
              [
                "Assessed Total Value",
                formatValue(addressInfo?.assesstot, "$"),
              ],
              ["Exempt Total Value", formatValue(addressInfo?.exempttot, "$")],
            ])}

            {renderDetails("Frontage", [
              ["Lot Frontage", formatValue(addressInfo?.lotfront, "", "'")],
              ["Lot Depth", formatValue(addressInfo?.lotdepth, "", "'")],
              [
                "Building Frontage",
                formatValue(addressInfo?.bldgfront, "", "'"),
              ],
              ["Building Depth", formatValue(addressInfo?.bldgdepth, "", "'")],
              ["Irregular", addressInfo?.irrlotcode ? "Yes" : "No"],
              ["Lot Type", formatValue(addressInfo?.lottype)],
            ])}

            {renderDetails("Floor Area Ratios (FAR)", [
              ["Built", formatValue(addressInfo?.builtfar)],
              ["Residential Potential", formatValue(addressInfo?.residfar)],
              ["Commercial Potential", formatValue(addressInfo?.commfar)],
              ["Facility Potential", formatValue(addressInfo?.facilfar)],
            ])}

            {renderDetails("Zoning", [
              ["Zoning District 1", addressInfo?.zonedist1],
              ["Special District 1", formathyphenIfNull(addressInfo?.spdist1)],
            ])}

            {renderDetails("Political Districts & Boundaries", [
              ["Community District", addressInfo?.cd],
              ["School District", addressInfo?.schooldist],
              ["City Council District", addressInfo?.council],
              ["Fire Company", addressInfo?.firecomp],
              ["Police Precinct", addressInfo?.policeprct],
              [
                "Health Center District",
                formatValue(addressInfo?.healthcenterdistrict),
              ],
              ["Health Area", addressInfo?.healtharea],
              ["Sanitation District Number", addressInfo?.sanitdistrict],
              ["Sanitation Subsection", formatValue(addressInfo?.sanitsub)],
            ])}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <MapContainer ref={mapRef} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

const mapStateToProps = (state) => ({
  addressFromRedux: selectors.addressSelector(state),
});

export default connect(mapStateToProps)(BoroughData);
