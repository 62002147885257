// index.js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux"; // Import the Redux Provider
import store from "./Redux/store"; // Make sure the path to your store is correct
import App from "./Components/App"; // Your App Component Path
import { AuthProvider } from "./Components/Contexts/AuthContext"; // Path to your AuthProvider

ReactDOM.render(
  <ReduxProvider store={store}>
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
