// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Grid,
//   Card,
//   CardMedia,
//   CardContent,
//   Typography,
//   CardActionArea,
//   TextField,
// } from "@mui/material";
// import Pagination from "@mui/material/Pagination";
// import PaginationItem from "@mui/material/PaginationItem";
// import Stack from "@mui/material/Stack";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// function AllSaleListingsPage() {
//   const [listings, setListings] = useState([]);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [search, setSearch] = useState("");

//   useEffect(() => {
//     const fetchListings = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:8081/all-sale-listings?page=${page}&limit=20&search=${encodeURIComponent(
//             search
//           )}`
//         );
//         setListings(response.data.listings); // Assuming response contains listings and total count
//         setTotalPages(Math.ceil(response.data.totalCount / 20)); // Calculate total pages based on totalCount
//       } catch (error) {
//         console.error("Error fetching listings:", error);
//       }
//     };

//     fetchListings();
//   }, [page, search]);

//   const handleSearchChange = (e) => {
//     setSearch(e.target.value);
//     setPage(1); // Reset to first page with new search
//   };

//   const handlePageChange = (event, value) => {
//     setPage(value);
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <TextField
//           fullWidth
//           label="Search by Address"
//           value={search}
//           onChange={handleSearchChange}
//           variant="outlined"
//           style={{ marginBottom: "20px" }}
//         />
//       </Grid>
//       {listings.map((listing) => (
//         <Grid item xs={12} sm={6} md={4} key={listing._id}>
//           <Card>
//             <CardActionArea onClick={() => handleCardClick(listing._id)}>
//               <CardMedia
//                 component="img"
//                 height="140"
//                 image={listing.images?.[0] || "defaultImage.jpg"} // Replace 'defaultImage.jpg' with your default image
//                 alt="Listing Image"
//               />
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                   {listing.page2Data?.askingPrice || "Price Not Available"}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {listing.page1Data?.address || "Address Not Available"}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                   {listing.description || "No description provided"}{" "}
//                   {/* Add other details as needed */}
//                 </Typography>
//               </CardContent>
//             </CardActionArea>
//           </Card>
//         </Grid>
//       ))}
//       <Grid item xs={12}>
//         <Stack spacing={2}>
//           <Pagination
//             count={totalPages}
//             page={page}
//             onChange={handlePageChange}
//             renderItem={(item) => (
//               <PaginationItem
//                 components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
//                 {...item}
//               />
//             )}
//           />
//         </Stack>
//       </Grid>
//     </Grid>
//   );
// }

// export default AllSaleListingsPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  TextField,
  Stack,
  Pagination,
} from "@mui/material";
import { useHistory } from "react-router-dom";

function AllSaleListingsPage() {
  const [listings, setListings] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const history = useHistory(); // For handling navigation

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.get(
          `http://localhost:8081/all-sale-listings?page=${page}&limit=20&search=${encodeURIComponent(
            search
          )}`
        );
        setListings(response.data.listings || []); // Setting the listings
        setTotalPages(Math.ceil(response.data.totalCount / 20)); // Calculating total pages
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };

    fetchListings();
  }, [page, search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to first page with new search
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Set the new page number
  };

  const handleCardClick = (listingId) => {
    history.push(`/getsalelistingdetails/${listingId}`); // Navigate to individual listing page
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Search by Address"
          value={search}
          onChange={handleSearchChange}
          variant="outlined"
          style={{ marginBottom: "20px" }}
        />
      </Grid>
      {listings.map((listing) => (
        <Grid item xs={12} sm={6} md={4} key={listing._id}>
          <Card>
            <CardActionArea onClick={() => handleCardClick(listing._id)}>
              <CardMedia
                component="img"
                height="140"
                image={listing.images?.[0] || "defaultImage.jpg"} // Replace 'defaultImage.jpg' with your default image
                alt="Listing Image"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {listing.page2Data?.askingPrice || "Price Not Available"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {listing.page1Data?.address || "Address Not Available"}
                </Typography>
                {/* Add other details as needed */}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Stack spacing={2} alignItems="center">
          {/* Updated Pagination with First and Last buttons */}
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default AllSaleListingsPage;
