import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import NavigationBar from "../../Navigation/NavigationBar";
import ListingItem from "../ListingsBrowse/ListingItem";
import imagesec from "../../../images/backgrounds/CentralPark.jpg";
import qrCode from "../../../images/qrcode-mapmax.png";
import agentImg from "../../../images/agent-img.jpg";
import "../../../css/addListing/addviewlisting.scss";

export default function AddViewListing() {
  const [recommendedListings, setRecommendedListings] = useState([]);
  const [popularListings, setPopularListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    // Fetch recommended listings from the backend
    const fetchRecommendedListings = async () => {
      try {
        const response = await fetch("/backend-routes/listings/recommended");
        const data = await response.json();
        setRecommendedListings(data);
      } catch (error) {
        console.error("Error fetching recommended listings:", error);
      }
    };

    // Fetch popular listings from the backend based on a dynamic zipcode
    const fetchPopularListings = async () => {
      try {
        const zipcode = "07029"; // Replace with a dynamic value if needed
        const response = await fetch(
          `/backend-routes/listings/popular/${zipcode}`
        );
        const data = await response.json();
        setPopularListings(data);
      } catch (error) {
        console.error("Error fetching popular listings:", error);
      }
    };

    fetchRecommendedListings();
    fetchPopularListings();
  }, []);

  const handleShowModal = (listing) => {
    setSelectedListing(listing);
  };

  const handleCloseModal = () => {
    setSelectedListing(null);
  };
  return (
    <div className="mainContainer">
      <NavigationBar />
      <section className="heroSection">
        <div className="heroContent">
          <h1>Discover Your Dream Home</h1>
          <p>
            Explore top listings, connect with agents, and find your perfect
            space.
          </p>
          <Button className="exploreButton">Explore Listings</Button>
        </div>
        <img src={imagesec} alt="Central Park" className="heroImage" />
      </section>

      <section className="listingsSection">
        <div className="listingsHeader">
          <h2>Featured Listings</h2>
          <Link to="/listings/browse" className="viewAllLink">
            View All
          </Link>
        </div>
        <div className="listingsGrid">
          {recommendedListings.map((listing, index) => (
            <ListingItem
              key={index}
              listing={listing}
              onMoreDetails={() => handleShowModal(listing)}
            />
          ))}
        </div>
      </section>

      <section className="appDownloadSection">
        <div className="appInfo">
          <h2>Mapmax Mobile App</h2>
          <p>Get real-time updates and explore features on the go.</p>
          <Button className="downloadButton">Download Now</Button>
        </div>
        <img src={qrCode} alt="Mapmax QR Code" className="qrCodeImage" />
      </section>

      <section className="agentContactSection">
        <img src={agentImg} alt="Agent" className="agentImage" />
        <div className="agentInfo">
          <h2>Connect with an Expert</h2>
          <p>Have questions? Our agents are here to help.</p>
          <Button className="contactButton">Contact Agent</Button>
        </div>
      </section>

      {/* Modal for Listing Details */}
      {selectedListing && (
        <div className="modalBackdrop">
          <div className="modalContent">
            <span className="closeButton" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>{selectedListing.title}</h2>
            <div className="listingDetailsModal">
              <p>
                <strong>Price:</strong> ${selectedListing.price}
              </p>
              <p>
                <strong>Size:</strong> {selectedListing.sqft} sqft
              </p>
              <p>
                <strong>Address:</strong> {selectedListing.address}
              </p>
              {/* Additional details can be added here */}
            </div>
            <Button className="contactAgentButton">Contact Agent</Button>
          </div>
        </div>
      )}
    </div>
  );
}
